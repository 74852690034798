import { DetalheItemProcesso } from '@/app/services/types';
import React from 'react';
import { Container, Infos, TitleContainer } from './styles';

interface ProcessGroupDetailsProps {
  items: DetalheItemProcesso[];
}

// Componente usado quando o item de um Negócio é um grupo com vários outros itens
const ProcessGroupItemsDetail: React.FC<ProcessGroupDetailsProps> = ({ items }) => {
  return (
    <Container>
      {items.map((item) => {
        return (
          <Container key={item.numero}>
            <TitleContainer>Item {item.numero}</TitleContainer>
            <p dangerouslySetInnerHTML={{ __html: item.descricao }}></p>
            <Infos>
              <span>Unidade:</span>
              {item.unidade}
            </Infos>
            <Infos>
              <span>Quantidade:</span>
              {item.quantidade}
            </Infos>
          </Container>
        );
      })}
    </Container>
  );
};

export default ProcessGroupItemsDetail;
