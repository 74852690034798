// See docs on: https://mui.com/pt/material-ui/customization/palette/
import { ThemeOptions } from '@mui/material/styles';

const typography = <ThemeOptions['typography']>{
  h6: {
    fontWeight: 500,
    fontSize: '0.75rem',
    color: '#1E1E1E',
  },
  h5: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#1E1E1E',
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 600,
    color: '#1E1E1E',
  },
  h3: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: '#1E1E1E',
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 700,
    color: '#1E1E1E',
  },
  h1: {
    fontSize: '1.7rem',
    fontWeight: 700,
    color: '#1E1E1E',
    paddingBottom: '1rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#1E1E1E',
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: '#1E1E1E',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    color: '#1E1E1E',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.334em',
    color: '#1E1E1E',
  },
  body2: {
    letterSpacing: '0em',
    fontWeight: 400,
    lineHeight: '1.5em',
    color: '#1E1E1E',
  },
  button: {
    textTransform: 'capitalize',
  },
};

export { typography };
