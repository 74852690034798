import React, { useState } from 'react';
import { Button, Collapse } from '@mui/material';
import {
  CardContainer,
  ContainerActionsEditals,
  ContainerMonitoringLinks,
  ContainerTitle,
  ContainerTitleColumn,
  GridFlexColumn,
  GridFlexRow,
} from './styles';
import BadgeCount from '@atoms/BadgeCount';
import TabPosts from '@molecules/TabPosts';
import LinkIcon from '@mui/icons-material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LightbulbSharp } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatIcon from '@mui/icons-material/Chat';
import { Negocio } from '@/app/services/types';

interface MonitoramentoProps {
  item?: Negocio;
  isOpen: boolean;
}

const Monitoramento: React.FC<MonitoramentoProps> = ({ item, isOpen }) => {
  const processId = item?.processoId;

  return (
    <Collapse in={isOpen}>
      <CardContainer id='monitoramento'>
        <GridFlexRow item>
          <ContainerTitle>
            <ContainerTitleColumn>
              <h5>
                Últimas Atividades do pregão Nº {item?.processo.numero} | {item?.processo.uasg} - {item?.processo.orgao}
              </h5>
              <p> Atualizado em {item?.processo.dataAtualizacaoFormatada}</p>
            </ContainerTitleColumn>
            <div>
              <ContainerActionsEditals>
                {item?.processo.urlEdital && (
                  <Button variant='outlined' startIcon={<LinkIcon />} href={item.processo.urlEdital} target='_blank'>
                    <strong>ATA</strong> - Homologado
                  </Button>
                )}
              </ContainerActionsEditals>
            </div>
          </ContainerTitle>
        </GridFlexRow>
        <GridFlexColumn item>
          <ContainerMonitoringLinks>
            <BadgeCount
              label='Avisos'
              count={item?.processo.qtdAvisos}
              Icon={NotificationsIcon}
              link={`http://www.comprasnet.gov.br/livre/Pregao/avisos2.asp?prgcod=${processId}&Tipo=A`}
            />
            <BadgeCount
              label='Esclarecimentos'
              count={item?.processo.qtdEsclarecimentos}
              Icon={LightbulbSharp}
              link={`http://www.comprasnet.gov.br/livre/Pregao/avisos2.asp?prgcod=${processId}&Tipo=E`}
            />
            <BadgeCount
              label='Impugnações'
              count={item?.processo.qtdImpugnacoes}
              Icon={CancelIcon}
              link={`http://www.comprasnet.gov.br/livre/Pregao/avisos2.asp?prgcod=${processId}&Tipo=I`}
            />
            <BadgeCount
              label='Mensagens'
              count={item?.processo.qtdMensagens}
              Icon={ChatIcon}
              link={`http://www.comprasnet.gov.br/livre/Pregao/Mensagens_Sessao_Publica.asp?prgCod=${processId}`}
            />
          </ContainerMonitoringLinks>
        </GridFlexColumn>

        <GridFlexColumn item>{processId && <TabPosts processId={processId} />}</GridFlexColumn>
      </CardContainer>
    </Collapse>
  );
};

export default Monitoramento;
