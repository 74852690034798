export interface DetalheItemProcesso {
  acimaReferencia: boolean;
  descricao: string;
  itens?: DetalheItemProcesso[];
  melhorLance: number;
  numero: string;
  quantidade: number;
  situacao: string;
  temProposta: boolean;
  tipo: string;
  unidade: any; // TODO: pegar o tipo deste campo com a equipe do backend
  valorReferencia: number;
}

export interface Processo {
  abertura: string;
  aberturaFormatada: string;
  codigo: string;
  dataPublicacaoFormatada: string;
  dataAtualizacaoFormatada: string;
  descricao: string;
  encerramento: Date;
  encerramentoFormatado: string;
  id: number;
  jornaisPertencentes: any[];
  modalidade: number;
  nomeModalidade: string;
  nomePortal: string;
  numero: string;
  orgao: string;
  portal: string;
  publicacao: Date;
  superoportunidade: boolean;
  titulo: string;
  uasg: string;
  uf: string;
  objeto: string;
  qtdAvisos: number;
  qtdEsclarecimentos: number;
  qtdImpugnacoes: number;
  qtdMensagens: number;
  urlAppSiga: string;
  urlAvisos: string;
  urlEdital: string;
  urlEsclarecimentos: string;
  urlExterna: string;
  urlImpugnacoes: string;
  urlMensagens: string;
  urlSigaAntigo: string;
}

export interface ItemProcesso {
  id: number;
  dataCricacao: string;
  negocioId: number;
  numeroItem: string;
  valorMaximo: number;
  valorMinimo: number;
  item: DetalheItemProcesso;
}

export interface Negocio {
  clienteId: number;
  dataCriacao: Date;
  empresaId: number;
  id: number;
  nome: string;
  processo: Processo;
  processoId: number;
  tipoNegocio: string;
  itens: ItemProcesso[];
}

export enum TiposNegocio {
  OPORTUNIDADE = 'oportunidade',
  DISPUTA = 'disputa',
  CLASSIFICACAO = 'classificacao',
  CONTRATO = 'contrato',
}

export enum TiposAtualizacoes {
  AVISO = 'avi',
  MENSAGEM = 'men',
  IMPUGNACAO = 'imp',
  ESCLARECIMENTO = 'esc',
}

export interface Atividade {
  cod?: string;
  d?: string;
  dataFormatada?: string;
  h?: string;
  req?: string;
  res?: string;
  tipo?: 'Mensagem' | 'Esclarecimento' | 'Aviso' | 'Impugnação';
}

export interface ItemMonitorado {
  atualizacao: string;
  numero: string;
  orgao: string;
  uasg: string;
  atividades: {
    data: Array<Atividade>;
    last: boolean;
    total: number;
  };
}
