import { client } from '../client/client';

export interface InputOpportunities {
  tipoPeriodo: string;
  orgao: string;
  pesquisa: string;
  excluir: string;
  limitar: string;
  portal: string;
  abertura: string;
  dataInicial: string;
  dataFinal: string;
  modalidade: string;
  dataEncerramento: string;
  tipoFiltroEncerramento: string;
  horaEncerramento: string;
  statusItem: string;
  situacao: string;
  faseCotacao: string;
  ufs: string;
  portais: string;
  modalidades: string;
  superoportunidades: boolean;
  page: number;
}

export interface OpportunityResponse {
  id: number;
  portal: string;
  modalidade: number;
  uasg: any;
  numero: string;
  codigo: string;
  titulo: string;
  descricao: string;
  uf: string;
  orgao: string;
  abertura: string;
  encerramento: undefined;
  publicacao: string;
  jornaisPertencentes: any[];
  superoportunidade: boolean;
  aberturaFormatada: string;
  encerramentoFormatado: string;
  dataPublicacaoFormatada: string;
  nomePortal: string;
  nomeModalidade: string;
}

export const getOpportunities = async (params: InputOpportunities): Promise<OpportunityResponse[]> => {
  return client
    .get('oportunidades', {
      params,
    })
    .then((response: any) => {
      return response.content || [];
    });
};
