import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const ContainerTitle = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const Title = styled.h5(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[400],
}));

export const sxDialog = {
  '& .MuiDialog-paper': {
    width: '100%',
  },
};
