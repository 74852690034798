import React from 'react';
import { Outlet } from 'react-router-dom';
import { Body, Container, Main } from './styles';
import Header from '@organisms/Header';
import Sidebar from '@organisms/Sidebar';
import { useAtomValue } from 'jotai';
import { openSidebarAtom } from '@jotai/ThemeAtom';
import Alerts from '../../organisms/Alerts';

const MainLayout: React.FC = () => {
  const openSideBar = useAtomValue(openSidebarAtom);
  return (
    <Container>
      <Header />
      <Sidebar />
      <Main open={openSideBar}>
        {/* <Breadcrumbs icon />*/}
        <Body>
          <Alerts />
          <Outlet />
        </Body>
      </Main>
    </Container>
  );
};

export default MainLayout;
