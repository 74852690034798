import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '500',
  color: theme.palette.grey[900],
  textTransform: 'capitalize',
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: '0.8rem',
  fontWeight: '500',
  color: theme.palette.grey[100],
  textTransform: 'capitalize',
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));
