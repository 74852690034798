import { client } from '../client/client';

export interface InputCompany {
  nome: string;
  cnpj: string;
}

export interface ResponseCompany {
  agencia: string;
  arquivado: boolean;
  banco: string;
  cep: string;
  cidade: string;
  clienteId: number;
  cnpj: string;
  conta: string;
  email: string;
  endereco: string;
  id: number;
  login: string;
  nome: string;
  nomeContato: string;
  telefone: string;
  uf: string;
  whatsapp: string;
}

export const addCompany = async (data: InputCompany) => {
  try {
    const response = await client.post('empresa', data);
    return response;
  } catch (error: any) {
    if (error.status === 403 && error.data.message === 'limite_excedido') {
      return error.data.message;
    }
  }
};

export interface ResultGetCompanies {
  pagination: string;
  results?: ResponseCompany[];
}

export const getCompanies = async (): Promise<any> => {
  return client.get('empresa');
};
