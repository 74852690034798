import { FormFields } from '@hooks/form';
import { addDays, format } from 'date-fns';

const initialDate = format(new Date(), 'yyyy-MM-dd');
const finalDate = format(addDays(new Date(), 15), 'yyyy-MM-dd');

export const formFields: FormFields = {
  pesquisa: {
    value: '',
    fieldType: 'string',
  },
  tipoPeriodo: {
    value: 'abertura',
    fieldType: 'string',
  },
  orgao: {
    value: '',
    fieldType: 'string',
  },
  excluir: {
    value: '',
    fieldType: 'string',
  },
  limitar: {
    value: '',
    fieldType: 'string',
  },
  portal: {
    value: '',
    fieldType: 'string',
  },
  abertura: {
    value: '',
    fieldType: 'string',
  },
  dataInicial: {
    value: initialDate,
    fieldType: 'string',
  },
  dataFinal: {
    value: finalDate,
    fieldType: 'string',
  },
  modalidade: {
    value: '',
    fieldType: 'string',
  },
  dataEncerramento: {
    value: '',
    fieldType: 'string',
  },
  tipoFiltroEncerramento: {
    value: 'ate',
    fieldType: 'string',
  },
  horaEncerramento: {
    value: '',
    fieldType: 'string',
  },
  statusItem: {
    value: '',
    fieldType: 'string',
  },
  situacao: {
    value: '',
    fieldType: 'string',
  },
  faseCotacao: {
    value: '',
    fieldType: 'string',
  },
  ufs: {
    value: '',
    fieldType: 'string',
  },
  portais: {
    value: '',
    fieldType: 'string',
  },
  modalidades: {
    value: '',
    fieldType: 'string',
  },
  superOportunidades: {
    value: false,
    fieldType: 'boolean',
  },
  page: {
    value: 0,
    fieldType: 'number',
  },
};
