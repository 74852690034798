import React from 'react';
import { ContainerTitleLink, Subtitle, TitleLink } from './styles';

interface Props {
  title: string;
  titleHref: string;
  prev: string;
  size?: 'small' | 'medium';
  card?: boolean;
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
  onClick?: () => void;
}

const LinkWithPreview: React.FC<Props> = ({ title, titleHref, prev, size = 'medium', card, themecolor, onClick }) => {
  return (
    <ContainerTitleLink container>
      <TitleLink href={titleHref} onClick={onClick} size={size} card={card} themecolor={themecolor}>
        {title}
      </TitleLink>
      <Subtitle variant={'body1'} size={size} card={card} dangerouslySetInnerHTML={{ __html: prev }}></Subtitle>
    </ContainerTitleLink>
  );
};

export default LinkWithPreview;
