import styled from '@emotion/styled';
import { theme } from '@/app/theme/theme-config';
import { Box } from '@mui/material';

interface PropContainer {
  fullWidth?: boolean;
}

export const Container = styled(Box)<PropContainer>(({ theme, fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  minHeight: '4.688rem',
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
  width: fullWidth ? '100%' : 'auto',
  small: {
    paddingLeft: '0.313rem',
  },
}));

interface PropContainerIcon {
  bgIcon?: string;
}

export const ContainerIcon = styled.div`
  height: 53px;
  width: 50px;
  background-color: ${(props: PropContainerIcon) => (props.bgIcon ? props.bgIcon : theme.palette.grey[300])};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: '5px 0 0 5px';
  margin-right: 0px;
`;
