import React from 'react';
import { LinearProgress } from '@mui/material';
import { Container } from './styles';

const Loader: React.FC = () => {
  return (
    <Container>
      <LinearProgress color='primary' />
    </Container>
  );
};

export default Loader;
