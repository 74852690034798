import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const Container = styled(Grid)(() => ({
  padding: '1rem 0 0.8rem 0.5rem',

  '@media (max-width: 420px)': {
    padding: '0.2rem',
    justifyContent: 'space-between',
  },
}));
