import config from '@/app/config';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 0.6rem',
  minWidth: '7rem',
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  margin: '0.5rem',
  borderRadius: config.cardBorderRadius,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

export const BadgeContainer = styled(Grid)(() => ({
  display: 'flex',
  margin: 'auto',
  padding: '0 0.5rem',
}));

export const LabelContainer = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
