import comprasnet from '@assets/portais/comprasnet.png';
import pcp from '@assets/portais/pcp.png';
import becsp from '@assets/portais/bec.png';
import lice from '@assets/portais/bb.png';
import caixa from '@assets/portais/caixa.png';
import banrisul from '@assets/portais/banrisul.png';
import comprasrs from '@assets/portais/comprasrs.png';
import comprasba from '@assets/portais/brasao-governo-bahia.png';
import comprasam from '@assets/portais/logo-governo-amazonas_.png';
import comprasgo from '@assets/portais/comprasgo.png';
import comprasrj from '@assets/portais/comprasrj.png';
import comprasre from '@assets/portais/comprasre.png';
import licitanet from '@assets/portais/licitanet.png';
import bll from '@assets/portais/bll.png';
import comprassc from '@assets/portais/compraselic.png';
import procergs from '@assets/portais/procergs.png';
import comprasmg from '@assets/portais/comprasmg.png';
import banpara from '@assets/portais/banpara.png';
import peintegrado from '@assets/portais/peintegrado.png';

interface Portal {
  name: string;
  icon: string;
}

const portalsIcons: any = {
  CN: { name: 'Comprasnet', icon: comprasnet },
  COMPRASNET: { name: 'Comprasnet', icon: comprasnet },
  PCP: { name: 'Portal de Compras Públicas', icon: pcp },
  BECSP: { name: 'BEC - SP', icon: becsp },
  LICE: { name: 'Licitações-e', icon: lice },
  CAIXA: { name: 'Licitações Caixa', icon: caixa },
  BANRISUL: { name: 'Banrisul', icon: banrisul },
  COMPRASRS: { name: 'COMPRAS RS', icon: comprasrs },
  COMPRASBA: { name: 'COMPRAS BAHIA', icon: comprasba },
  COMPRASAM: { name: 'COMPRAS AMAZONAS', icon: comprasam },
  COMPRASGO: { name: 'COMPRASNET GOIÁS', icon: comprasgo },
  COMPRASRJ: { name: 'COMPRAS RJ', icon: comprasrj },
  COMPRASRE: { name: 'COMPRAS RECIFE', icon: comprasre },
  LICITANET: { name: 'Licitanet', icon: licitanet },
  BLL: { name: 'BLL COMPRAS', icon: bll },
  COMPRASSC: { name: 'PORTAL e-LIC - SANTA CATARINA', icon: comprassc },
  PROCERGS: { name: 'PROCERGS', icon: procergs },
  COMPRASMG: { name: 'COMPRAS MINAS GERAIS', icon: comprasmg },
  BANPARA: { name: 'BANPARÁ', icon: banpara },
  PEINTEGRADO: { name: 'PE Integrado', icon: peintegrado },
};

export const getIcons = (portal: any): Portal => {
  return portalsIcons[portal];
};
