import useWindowDimensions from '@/app/hooks/useWindowDimensions';
import { addMyBusiness, InputMyBusiness } from '@/app/services/myBusinessService';
import { getProcess, Process } from '@/app/services/processService';
import { TiposNegocio } from '@/app/services/types';
import { useForm } from '@hooks/form';
import { Add, FileDownloadOutlined, Launch, PushPin } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import AlertMessage from '../../atoms/AlertMessage';
import DeadlineShow from '../../atoms/DeadlineShow';
import InputField from '../../molecules/InputField';
import Modal from '../../molecules/Modal';
import CheckBoxList from '../CheckBoxList';
import SelectFieldCompany from '../SelectFieldCompany';
import { formFieldsOpportunity } from './formFieldsOpportunity';
import { Header } from './Header';
import ModalCadastroEmpresa, { ModalCadastroEmpresaHandle } from './ModalCreateCompany';
import {
  ActionsFooter,
  ButtonCancel,
  ContainerListItems,
  ContainerModal,
  ContainerTitle,
  DeadLineShowContainer,
  Footer,
  InstructionsContainer,
  LineDateOptions,
  LineEmployer,
  ShareIconsContainer,
} from './style';
import { getItensGrupo } from './utils';

interface ModalDetailOpportunityProps {}

interface ItemOportunity {
  id: number;
  checked: boolean;
}

export type ModalDetailOpportunityhandle = {
  showModal: (id: number) => void;
};

export type AlertHandle = {
  showModal: () => void;
};

const ModalDetailOpportunity = (props: ModalDetailOpportunityProps, ref: React.Ref<ModalDetailOpportunityhandle>) => {
  const [data, setData] = useState<Process | null>();
  const [itensCheckList, setItensCheckList] = useState<any[]>([]);
  const [dataError, setDataError] = useState({ nome: '' });
  const [visible, setVisible] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const mobileDimensions = width <= 630;

  const [alertMessage, setAlertMessage] = useState({
    title: 'Sucesso',
    success: true,
    description: 'Oportunidade Cadastrada com sucesso!',
  });

  // Ref do modal de cadastro de empresa
  const alertRef = useRef<AlertHandle>(null);

  // Ref do modal de cadastro de empresa
  const modalCadastroEmpresaRef = useRef<ModalCadastroEmpresaHandle>(null);

  // Inicio formik
  const onHandleSubmit = async (values: any) => {
    console.log('teste');
  };

  const { values, touched, errors, handleOnChange, handleSubmit, setFieldValue } = useForm(
    formFieldsOpportunity,
    onHandleSubmit,
  );
  // Fim formik

  useImperativeHandle(ref, () => ({
    async showModal(id: number) {
      const dataReturn = await getProcess(id);
      setData(dataReturn);

      if (dataReturn) {
        const itens = getItensGrupo(dataReturn.itens);
        setItensCheckList(itens);
        setFieldValue(
          'nome',
          `${dataReturn?.nomePortal} - ${dataReturn?.nomeModalidade} N° ${dataReturn?.numero} - ${dataReturn?.orgao} / ${dataReturn?.uf}`,
        );
        setFieldValue('processoId', dataReturn?.id);
        setFieldValue('tipoNegocio', TiposNegocio.OPORTUNIDADE);
      }
      setVisible(true);
    },
  }));

  const handleCkeck = (id: any, value: boolean) => {
    const indexCheck = itensCheckList.findIndex((i: any) => i.id === id);
    const newChecks = itensCheckList;
    newChecks[indexCheck].checked = value;
    setItensCheckList([...newChecks]);

    const checkedItems = itensCheckList.filter((item: ItemOportunity) => item.checked === true);
    const stringItens = checkedItems.map((item: ItemOportunity) => {
      return { numeroItem: item.id };
    });
    setFieldValue('itens', stringItens);
  };

  const handleAddBusiness = async () => {
    console.log(values.itens);

    const response = await addMyBusiness({
      ...values,
    } as InputMyBusiness);

    if (response !== 'error') {
      setAlertMessage({
        title: 'Sucesso',
        success: true,
        description: 'Oportunidade Cadastrada com sucesso!',
      });
    } else {
      setAlertMessage({
        title: 'Erro',
        success: false,
        description: 'Erro ao cadastrar oportunidade!',
      });
    }

    alertRef?.current?.showModal();
  };

  const footer = () => {
    return (
      <Footer>
        <LineEmployer>
          <InstructionsContainer>
            Pra finalizar, escolha um nome para essa oportunidade, ou use o nome sugerido.
          </InstructionsContainer>
          <InputField
            id='nome'
            form={values}
            style={{ minWidth: '150px', marginBottom: '-2rem' }}
            formErrors={dataError}
            fullWidth={true}
            handleChange={handleOnChange}
          />
        </LineEmployer>
        <LineEmployer>
          <SelectFieldCompany
            id='empresaId'
            form={values}
            handleChange={(id, value) => {
              setFieldValue(id, value.id.toString());
            }}
            formErrors={touched.newspapers ? errors : {}}
            value={values['empresaId'] || ''}
          />
          <ModalCadastroEmpresa ref={modalCadastroEmpresaRef} />
          <Button
            style={{ minWidth: 220, marginLeft: 15 }}
            startIcon={<Add />}
            onClick={(d) => modalCadastroEmpresaRef?.current?.showModal()}
          >
            Cadastrar nova empresa
          </Button>
        </LineEmployer>
        <ActionsFooter>
          <ButtonCancel variant='contained' color='inherit' onClick={() => setVisible(false)}>
            Cancelar
          </ButtonCancel>
          <Button variant='contained' startIcon={<PushPin />} onClick={handleAddBusiness}>
            {mobileDimensions ? 'Adicionar' : 'Adicionar aos meus negócios'}
          </Button>
        </ActionsFooter>
      </Footer>
    );
  };

  return (
    <Modal
      open={visible}
      onClose={() => setVisible(false)}
      header={data ? <Header data={data} /> : ''}
      maxWidth='md'
      footer={footer()}
    >
      <ContainerModal>
        <ContainerTitle>
          <h4>{`${data?.nomeModalidade} N° ${data?.numero} - ${data?.orgao} / ${data?.uf}`}</h4>
          <p>{data?.objeto || ''}</p>
        </ContainerTitle>
        <LineDateOptions>
          <DeadLineShowContainer>
            <DeadlineShow startAt={data?.aberturaFormatada || ''} endAt={data?.encerramentoFormatado || ''} />
          </DeadLineShowContainer>
          <ShareIconsContainer>
            {data?.urlExterna && (
              <Button startIcon={<Launch />} href={data?.urlExterna} target='_blank'>
                Acessar
              </Button>
            )}
            {data?.urlEdital && (
              <Button startIcon={<FileDownloadOutlined />} href={data?.urlEdital} target='_blanco'>
                Anexo
              </Button>
            )}
            {/* TODO: Implementar novas opções de compartilhamento */}
            {/* <Button startIcon={<ShareOutlined />}>Compartilhar</Button> */}
          </ShareIconsContainer>
        </LineDateOptions>
        <Grid style={{ fontWeight: '400', fontSize: 12, width: '100%', padding: '0.8rem 0' }}>
          <b>Para seguir e monitorar as atividades desta oportunidade, adicione-a aos seus negócios:</b>
          <br />
          <br />
          Selecione um ou mais itens para participação:
        </Grid>
        <ContainerListItems>
          <CheckBoxList onCheckedItem={handleCkeck} itens={itensCheckList} />
        </ContainerListItems>
      </ContainerModal>

      <AlertMessage message={alertMessage} ref={alertRef} callBack={() => setVisible(false)} />
    </Modal>
  );
};

export default React.forwardRef(ModalDetailOpportunity);
