import styled from '@emotion/styled';
import { Avatar, ButtonBase } from '@mui/material';

interface AvatarProps {
  styles?: {
    borderRadius?: number;
    backgroundColor?: string;
    backgroundColorHover?: string;
    colorHover?: string;
    color?: string;
    width?: number;
    height?: number;
    fontSize?: number | string;
  };
}
interface ButtonProps {
  styles?: {
    borderRadius?: number;
  };
}

export const Container = styled(ButtonBase)<ButtonProps>(({ styles, theme }) => ({
  overflow: 'hidden',
  borderRadius: styles?.borderRadius ?? theme.shape.borderRadius,
  display: 'none',

  '@media (max-width: 420px)': {
    display: 'block',
  },
}));

export const IconContainer = styled(Avatar)<AvatarProps>(({ styles, theme }) => ({
  borderRadius: styles?.borderRadius ?? theme.shape.borderRadius,
  backgroundColor: styles?.backgroundColor ?? theme.palette.secondary.light,
  color: styles?.color ?? theme.palette.secondary.contrastText,
  height: styles?.height ?? theme.iconButton.height,
  width: styles?.width ?? theme.iconButton.width,
  fontSize: styles?.fontSize ?? 12,
  '&:hover': {
    backgroundColor: styles?.backgroundColorHover ?? theme.palette.action.active,
    color: styles?.colorHover ?? theme.palette.secondary.contrastText,
  },
  transition: 'all 0.2s ease-in-out',
}));
