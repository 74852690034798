import DeadlineShow from '@atoms/DeadlineShow';
import { ShoppingBag } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useState } from 'react';

import { getModalidade } from '@/app/services/modalidadeService';
import { getIcons } from '@/app/services/portalService';
import LinkWithPreview from '@atoms/LinkWithPreview';
import ChipGroup from '@molecules/ChipGroup';
import { ChipItem, Container, ContainerChipGroup, ContainerLinkWithPreview, GirdMonitored } from './styles';

export interface OpportunityItemType {
  abertura: string;
  aberturaFormatada: string;
  codigo: string;
  dataPublicacaoFormatada: string;
  descricao: string;
  encerramento: string;
  encerramentoFormatado: string;
  id: number;
  jornaisPertencentes: [];
  modalidade: number;
  nomeModalidade: string;
  nomePortal: string;
  numero: string;
  orgao: string;
  portal: string;
  publicacao: string;
  superoportunidade: boolean;
  titulo: string;
  uasg: string;
  uf: string;
}

interface OpportunityItemInterface {
  data: OpportunityItemType;
  onClick?: (data: OpportunityItemType) => void;
}

// TODO: Centralizar esta interface no componente ChipItem
export interface ChipItem {
  icon?: string;
  label: string;
  color?: 'primary' | undefined;
}

const OpportunityItem: React.FC<OpportunityItemInterface> = ({ data, onClick }) => {
  const [checked, setChecked] = useState(false);
  const handleCheckBox = () => {
    setChecked(!checked);
  };

  // TODO: Exibir os portais de compra de cada oportunidade listada
  const chipGroup = [
    {
      Icon: ShoppingBag,
      label: data?.nomePortal,
    },
    {
      label: data?.nomeModalidade,
      color: 'primary',
    },
  ];

  const listChip: Array<ChipItem> = [];

  if (data.portal) {
    listChip.push({
      icon: getIcons(data.portal).icon,
      label: getIcons(data.portal).name,
    });
  }

  if (data.modalidade) {
    listChip.push({
      label: getModalidade(data.modalidade),
      color: 'primary',
    });
  }

  return (
    <Container container>
      <Grid item xs={12} md={11}>
        <ContainerLinkWithPreview item>
          <LinkWithPreview
            title={data.titulo}
            titleHref={'#'}
            onClick={() => onClick && onClick(data)}
            prev={data.descricao.substring(0, 200)}
            themecolor={'primary'}
          />
        </ContainerLinkWithPreview>
        <Grid container>
          <DeadlineShow startAt={data.aberturaFormatada} endAt={data.encerramentoFormatado}></DeadlineShow>
        </Grid>
        <ContainerChipGroup>
          <ChipGroup data={chipGroup} />
        </ContainerChipGroup>
      </Grid>
      <Grid item md={1} xs={12}>
        {/* <ContainerPin item m={1}>
          <CheckBoxIcon Icon={PushPin} CheckedIcon={PushPin} onChange={handleCheckBox} />
        </ContainerPin> */}
        <GirdMonitored item>
          {data.superoportunidade && <ChipItem label='Super Oportunidade' color='warning' size='small' />}
        </GirdMonitored>
      </Grid>
    </Container>
  );
};

export default OpportunityItem;
