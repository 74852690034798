import { ShoppingBag } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Container, CardContainer } from './styles';
import BusinessCardGroup from '@organisms/BusinessCardGroup';
import ListBusinessCard, { BusinessCardProps } from '@molecules/ListBusinessCard';

const BusinessCards: React.FC = () => {
  // TODO: Dados estáticos para exemplificar o funcionamento do componente
  const dataListItem: BusinessCardProps = {
    listChip: [
      {
        label: 'COMPRASNET',
      },
      {
        label: 'Dispensa Eletônica',
        color: 'primary',
      },
    ],
    content: {
      title: 'Comprasnet - Pregão eletrônico Nº 1/2022 | 195019 - 11º SUPERINTENDENCIA REGIONAL AMAPA',
      titleHref: 'http://www.google.com',
      prev: 'PREGÃO ELETRÔNICO Nº 1/2022 - 11º SUPERINTENDENCIA REGIONAL AMAPA - Amapá',
    },
    progress: {
      label: 'tarefas',
      total: 100,
      value: 33,
      color: 'secondary',
    },
    options: [
      {
        label: 'Option 1',
        action: () => {
          console.log('opção 1');
        },
      },
      {
        label: 'Option 2',
        action: () => {
          console.log('opção 2');
        },
      },
      {
        label: 'Option 3',
        action: () => {
          console.log('opção 3');
        },
      },
    ],
    startAt: new Date(),
  };

  // TODO: Dados estáticos para exemplificar o funcionamento do componente
  const optionsMenu = [
    {
      label: 'Option 1',
      action: () => {
        console.log('opção 1');
      },
    },
    {
      label: 'Option 2',
      action: () => {
        console.log('opção 2');
      },
    },
    {
      label: 'Option 3',
      action: () => {
        console.log('opção 3');
      },
    },
  ];

  return (
    <Container>
      <Typography variant='h1'>(BusinessCardGroup/ListBusinessCard)</Typography>
      <CardContainer>
        {/* <Grid container spacing={4}>
          <Grid item xs={3}>
            <BusinessCardGroup
              index={1}
              themecolor='primary'
              title='OPORTUNIDADES'
              totalRecords={2}
              optionsMenu={optionsMenu}
            >
              <ListBusinessCard themecolor='primary' data={dataListItem} />
              <ListBusinessCard themecolor='primary' data={dataListItem} />
            </BusinessCardGroup>
          </Grid>
          <Grid item xs={3}>
            <BusinessCardGroup
              index={2}
              themecolor='warning'
              title='DISPUTA'
              totalRecords={3}
              optionsMenu={optionsMenu}
            >
              <ListBusinessCard themecolor='warning' data={dataListItem} />
              <ListBusinessCard themecolor='warning' data={dataListItem} />
            </BusinessCardGroup>
          </Grid>
          <Grid item xs={3}>
            <BusinessCardGroup
              index={3}
              themecolor='success'
              title='CLASSIFICAÇÃO'
              totalRecords={4}
              optionsMenu={optionsMenu}
            >
              <ListBusinessCard themecolor='success' data={dataListItem} />
              <ListBusinessCard themecolor='success' data={dataListItem} />
            </BusinessCardGroup>
          </Grid>
          <Grid item xs={3}>
            <BusinessCardGroup index={4} title='CONTRATO' totalRecords={2} optionsMenu={optionsMenu}>
              <ListBusinessCard data={dataListItem} />
              <ListBusinessCard data={dataListItem} />
            </BusinessCardGroup>
          </Grid>
        </Grid> */}
      </CardContainer>
    </Container>
  );
};

export default BusinessCards;
