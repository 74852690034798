import React, { useEffect, useState } from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';

export interface SelectFieldUFProps<
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo>, 'multiple'> {}

const SelectFieldUF: React.FC<SelectFieldUFProps> = ({ form, id, handleChange, ...rest }) => {
  const listUfs = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  const [ufs, setUfs] = useState<{ ufs: Option[] }>({
    ufs: listUfs.filter((a) => (form[id] ? form[id].split(',').includes(a.value) : false)) || [],
  });

  useEffect(() => {
    setUfs({
      ufs: listUfs.filter((a) => (form[id] ? form[id].split(',').includes(a.value) : false)) || [],
    });
  }, [form[id]]);

  return (
    <SelectField
      id={id}
      {...rest}
      form={ufs}
      handleChange={(_, item, e) => handleChange && handleChange(id, item?.map((a: Option) => a.value).join(','), e)}
      multiple
      placeholder='Selecione ou digite o Estado'
      options={listUfs}
    />
  );
};

export default SelectFieldUF;
