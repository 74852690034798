import styled from '@emotion/styled';
import { Accordion, Box, AccordionSummary } from '@mui/material';
import { blue } from '@mui/material/colors';

export const Container = styled(Box)(() => ({
  paddingBottom: 16,
}));

export const Collapse = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  marginBottom: '10px !important',
}));

interface AccordionSummaryProps {
  iconstart: string;
}

export const Summary = styled(AccordionSummary)<AccordionSummaryProps>(({ iconstart }) => ({
  flexDirection: iconstart === 'start' ? 'row-reverse' : 'row',
  '& .MuiAccordionSummary-content': {
    margin: '0 0 !important',
  },
}));
