import React from 'react';

import { AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { Container, Collapse, Summary, TextItem } from './styles';
import { DetalheItemProcesso, Negocio } from '@/app/services/types';
import ProcessItemDetails from '../../atoms/ProcessItemDetails';
import ProcessGroupItemsDetail from '../../atoms/ProcessGroupItemsDetail';

export interface AccordionCollapseItemProps {
  header: string | React.ReactElement;
  body: string | React.ReactElement;
  disabled?: boolean;
  id: string;
}

type AccordionProps = {
  item?: Negocio;
};

const AccordionItemsBusiness: React.FC<AccordionProps> = ({ item }) => {
  const businessItems = item?.itens;
  const process = item?.processo;

  const getTitleGroupItems = (group: DetalheItemProcesso): string => {
    if (group.itens && group.itens?.length > 0) {
      const qtdItems = group.itens?.length;

      return (
        group.itens[0].descricao.replace(regexStripHtmlTags, '').substring(0, 60) +
        '...' +
        (qtdItems > 1 ? `[e mais ${qtdItems - 1} itens]` : '')
      );
    }

    return '';
  };

  const regexStripHtmlTags = /(<([^>]+)>)/gi;

  const getProcessItemDetails = (detalheItemProcesso: DetalheItemProcesso) => {
    return process ? <ProcessItemDetails process={process} detailItemProcess={detalheItemProcesso} /> : '';
  };

  const getProcessGroupDetails = (detalheGrupoProcesso: DetalheItemProcesso) => {
    return detalheGrupoProcesso.itens ? <ProcessGroupItemsDetail items={detalheGrupoProcesso.itens} /> : '';
  };

  return (
    <Container>
      {businessItems?.map((item, index) => (
        <Collapse id={`its${index}`} key={`its${index}`}>
          <Summary expandIcon={<ExpandMore />} id={item.id.toString()}>
            <TextItem>
              {item.item.tipo === 'Item' ? 'Item' : 'Grupo'} {item.item.numero + ' - '}
              {item.item.tipo === 'Item'
                ? item.item.descricao.replace(regexStripHtmlTags, '').substring(0, 80) + '...'
                : getTitleGroupItems(item.item)}
              <p>&nbsp;{item.item.situacao}</p>
            </TextItem>
          </Summary>
          <AccordionDetails>
            {item.item.tipo === 'Item' ? getProcessItemDetails(item.item) : getProcessGroupDetails(item.item)}
          </AccordionDetails>
        </Collapse>
      ))}
    </Container>
  );
};

export default AccordionItemsBusiness;
