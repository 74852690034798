import { useEffect, useState } from 'react';
import { Atividade, TiposAtualizacoes } from '@/app/services/types';
import { Button, Grid } from '@mui/material';
import { ListPosts, ContainerPost, HeaderPost, BodyPost, MorePostsButton } from './styles';
import { getActivities } from '@/app/services/activitiesService';

interface Props {
  processId: number;
  filtro: TiposAtualizacoes;
  limit?: number;
}

const Posts: React.FC<Props> = ({ processId, filtro, limit = 50 }: Props) => {
  const [offset, setOffset] = useState<number>(0);
  const [messages, setMessages] = useState<Atividade[]>();
  const [isLast, setIsLast] = useState<boolean>(true);

  const getActivitiesMonitoring = async () => {
    if (processId) {
      const { atividades } = await getActivities({ id: processId, filtro, limit, offset });
      setMessages([...atividades.data]);
      setIsLast(atividades.last);
    }
  };

  useEffect(() => {
    if (processId) getActivitiesMonitoring();
  }, [offset]);

  return (
    <ListPosts>
      {messages &&
        messages.map((mensagem) => {
          if (mensagem.tipo === 'Aviso' || mensagem.tipo === 'Mensagem') {
            return (
              <ContainerPost key={mensagem.dataFormatada}>
                <HeaderPost>
                  <Grid>{mensagem.tipo}</Grid>
                  <Grid>{mensagem.dataFormatada}</Grid>
                </HeaderPost>
                <BodyPost>{mensagem.d}</BodyPost>
              </ContainerPost>
            );
          }
          return (
            <ContainerPost key={mensagem.dataFormatada}>
              <HeaderPost>
                <Grid>{mensagem.tipo}</Grid>
                <Grid>{mensagem.dataFormatada}</Grid>
              </HeaderPost>
              <BodyPost>
                <b>Questionamento:</b> {mensagem.req}
              </BodyPost>
              <BodyPost>
                <b>Resposta:</b>
                {mensagem.res}
              </BodyPost>
            </ContainerPost>
          );
        })}

      {!isLast && (
        <MorePostsButton
          onClick={() => {
            setOffset(offset + 1);
          }}
          variant='outlined'
        >
          Carregar mais
        </MorePostsButton>
      )}
    </ListPosts>
  );
};

export default Posts;
