import styled from '@emotion/styled';
import { Box } from '@mui/material';
import config from '@app/config';

interface MainProps {
  open: boolean;
}
export const Container = styled(Box)`
  display: flex;
`;
export const Main = styled('main')<MainProps>(({ theme, open }) => ({
  '@media (max-width: 420px)': {
    padding: '0 !important',
  },
  backgroundColor: theme.palette.background.default,
  width: '100%',
  minHeight: `calc(100vh - ${config.marginContainerTop}px)`,
  flexGrow: 1,
  padding: '20px',
  marginTop: config.marginContainerTop,
  marginRight: '20px',
  borderRadius: theme.shape.borderRadius,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: -(config.drawerWidth - 20),
      width: `calc(100% - ${config.drawerWidth}px)`,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: `calc(100% - ${config.drawerWidth}px)`,
      padding: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
      width: `calc(100% - ${config.drawerWidth}px)`,
      padding: '16px',
      marginRight: '10px',
    },
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: `calc(100% - ${config.drawerWidth}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px',
    },
  }),
}));

export const Body = styled('div')(() => ({
  paddingLeft: '1rem',
  paddingBottom: '1rem',
  paddingRight: '1rem',
  paddingTop: '2rem',

  '@media (max-width: 420px)': {
    padding: '0',
  },
}));
