import config from '@/app/config';
import React from 'react';

import { Container } from './styles';

interface Props {
  children: React.ReactElement | React.ReactNode;
}
const CardContainer: React.FC<Props> = ({ children }) => {
  return (
    <Container container direction='column' padding={config.gridPadding}>
      {children}
    </Container>
  );
};

export default CardContainer;
