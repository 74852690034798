import styled from '@emotion/styled';
import { ListItemIcon, ListItemIconProps } from '@mui/material';

export interface Props extends ListItemIconProps {
  icon: string;
}

export const Container = styled(ListItemIcon)<Props>(({ icon }) => ({
  marginBottom: 'auto',
  marginTop: 'auto',
  minWidth: icon === 't' ? 36 : 18,
}));
