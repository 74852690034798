import { Button, Collapse, Dialog, Grid, ModalProps, ThemeProvider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  HeaderContainer,
  BodyContainer,
  ContainerTitle,
  CardContainer,
  GridFlexColumn,
  GridFlexRow,
  GridFlexRowBody,
  sxDialog,
  themeButtons,
  ContainerTitleRow,
  CountContainer,
  ContainerTitleColumn,
  ContainerDescription,
  TitleBusiness,
  ContainerActionsEditals,
  ContainerTitleBody,
} from './styles';
import DeadlineShow from '@atoms/DeadlineShow';
import ToFile from '@assets/ToFile.svg';
Button;
import Minimize from '@assets/Minimize.svg';
import PenEdit from '@assets/PenEdit.svg';
// TODO: Talvez precisaremos destes icones em breve
// import DocButton from '@assets/DocButton.svg';
// import FileButton from '@assets/FileButton.svg';
// import PdfButton from '@assets/PdfButton.svg';

import TaskList, { Task } from '../TaskList';
import AttachList from '../AttachList';
import { getBusinessById } from '@/app/services/businessService';
import { Negocio } from '@/app/services/types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AccordionItemsBusiness from '@molecules/AccordionItemsBusiness';
import StepsBusiness from '@molecules/StepsBusiness';
import Monitoramento from '../Monitoramento';
import TeamBusiness from '@molecules/TeamBusiness';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

export interface ModalNewspapersProps extends Omit<ModalProps, 'onChange' | 'children'> {
  onChange: (id: string, value: string, event: React.ChangeEvent<any>) => void;
  idItemModal: number;
  callBack: () => void;
}

const ModalBusinessDetail: React.FC<ModalNewspapersProps> = ({ open, onChange, onClose, idItemModal, callBack }) => {
  const [showMonitoring, setShowMonitoring] = useState<boolean>(false);
  const [showItems, setShowItems] = useState<boolean>(true);
  const [show, setShow] = useState(open);
  const [item, setItem] = useState<Negocio>();

  const getItem = async (id: number) => {
    const response = await getBusinessById(id);
    if (response) {
      setItem(response);
      callBack();
    }
  };

  useEffect(() => {
    setShow(open);
    if (idItemModal) getItem(idItemModal);
  }, [open]);

  const handleCloseClickOut = (event: any, reason: any) => {
    setShow(false);
    if (onClose) {
      onClose(event, reason);
    }
  };

  const [tasks, setTasks] = useState<Task[]>([
    { id: 1, label: 'Inbox Template', checked: false },
    { id: 2, label: 'Chat Template', checked: false },
    { id: 3, label: 'Tasks Template', checked: false },
    { id: 4, label: 'Projects Template', checked: false },
  ]);

  const handleChange = (id: number, label: string, value: any) => {
    const indexTask = tasks.findIndex((i) => i.id === Number(id));
    const newTasks = tasks;
    newTasks[indexTask].checked = value;
    setTasks([...newTasks]);
  };

  const getFase = (fase: string) => {
    if (fase === 'oportunidade') return 1;
    if (fase === 'disputa') return 2;
    if (fase === 'classificacao') return 3;
    if (fase === 'contrato') return 4;
    return '';
  };

  const getThemeColor = (fase: string) => {
    if (fase === 'oportunidade') return 'primary';
    if (fase === 'disputa') return 'warning';
    if (fase === 'classificacao') return 'success';
    return undefined;
  };

  return (
    <Dialog sx={sxDialog} maxWidth='lg' open={show}>
      {/* TODO: Quando for habilitar novas divs do lado mudar de lg para xl */}
      <HeaderContainer>
        <ContainerTitle container>
          <ContainerTitleRow item md={9} sm={12} xs={12}>
            <h3>Meus Negócios</h3>
            {item ? <StepsBusiness item={item} callBack={getItem} /> : ''}
          </ContainerTitleRow>
          <ContainerTitleRow md={3} item sm={12} xs={12}>
            <ThemeProvider theme={themeButtons}>
              <Button
                variant='outlined'
                startIcon={<img src={ToFile} />}
                style={{ width: '100%', marginBottom: '0.5rem' }}
              >
                Arquivar
              </Button>
            </ThemeProvider>

            <Button
              style={{ width: '100%' }}
              variant='contained'
              startIcon={<img src={Minimize} />}
              onClick={(e) => handleCloseClickOut(e, null)}
            >
              Fechar
            </Button>
          </ContainerTitleRow>
        </ContainerTitle>
      </HeaderContainer>
      <BodyContainer>
        <Grid container>
          <Grid container item md={12} spacing={2}>
            <Grid item md={12} xs={12}>
              {/* TODO: alterar para md6 quando implementar os recursos escondidos */}
              <CardContainer>
                <GridFlexRowBody item md={12}>
                  <CountContainer themecolor={item?.tipoNegocio ? getThemeColor(item?.tipoNegocio) : undefined}>
                    <h3>{item?.tipoNegocio && getFase(item?.tipoNegocio)}</h3>
                    <i>FASE</i>
                  </CountContainer>
                  <ContainerTitleBody>
                    <ContainerTitleColumn>
                      <h3>Pregão eletrônico</h3>
                      <p>{item?.processo.orgao}</p>
                    </ContainerTitleColumn>
                    <div>
                      <DeadlineShow
                        showIcon={false}
                        alingend
                        startAt={item?.processo.aberturaFormatada ? item?.processo.aberturaFormatada : ''}
                        endAt={item?.processo.encerramentoFormatado ? item?.processo.encerramentoFormatado : ''}
                      ></DeadlineShow>
                    </div>
                  </ContainerTitleBody>
                </GridFlexRowBody>
                <GridFlexColumn item>
                  <TitleBusiness>{item?.nome}</TitleBusiness>
                  <p>{item?.processo.objeto}</p>
                  <ContainerActionsEditals>
                    {item?.processo.urlEdital && (
                      <Button
                        variant='outlined'
                        startIcon={<ArticleOutlinedIcon />}
                        href={item.processo.urlEdital}
                        target='_blank'
                      >
                        Ler edital
                      </Button>
                    )}

                    {item?.processo.urlExterna && (
                      <Button
                        variant='outlined'
                        startIcon={<OpenInNewOutlinedIcon />}
                        href={item.processo.urlExterna}
                        target='_blank'
                      >
                        Acessar relatório
                      </Button>
                    )}

                    <Button
                      onClick={() => {
                        setShowMonitoring(!showMonitoring);
                      }}
                      variant='outlined'
                      startIcon={<AccessTimeIcon />}
                    >
                      Monitoramento
                    </Button>
                  </ContainerActionsEditals>
                </GridFlexColumn>

                <GridFlexColumn item>
                  <ContainerDescription>
                    <div>
                      Itens da Licitação
                      <Button
                        color='primary'
                        onClick={() => {
                          setShowItems(!showItems);
                        }}
                      >
                        {showItems ? <ExpandLess /> : <ExpandMore />}
                      </Button>
                    </div>
                    <ThemeProvider theme={themeButtons}>
                      <Button variant='outlined' startIcon={<img src={PenEdit} />}>
                        Editar Items
                      </Button>
                    </ThemeProvider>
                  </ContainerDescription>
                </GridFlexColumn>

                <Collapse in={showItems} timeout={200}>
                  <Grid item>
                    <AccordionItemsBusiness item={item} />
                  </Grid>
                </Collapse>

                {/* 
                //TODO: Talvez precisaremos disto em breve
                <GridFlexColumn item>
                  <h5>Documentos Úteis</h5>
                  <HeaderDocuments>
                    <img src={DocButton} />
                    <img src={FileButton} />
                    <img src={PdfButton} />
                  </HeaderDocuments>
                </GridFlexColumn> */}
              </CardContainer>
            </Grid>
            <Grid item md={12} xs={12}>
              {/* TODO: alterar para md6 quando implementar os recursos escondidos */}
              <Monitoramento item={item} isOpen={showMonitoring} />
            </Grid>
            {/* TODO: esta parte esta com display none pois n é para entrar nesta fase do projeto */}
            {/* <Grid item md={6} style={{ display: 'none' }}>
              <TaskList title='Tarefas' itens={tasks} handleChange={handleChange} />
            </Grid> */}
          </Grid>
          {/* TODO: esta parte esta com display none pois n é para entrar nesta fase do projeto */}
          {/* <Grid container item md={12} spacing={2} style={{ marginTop: '0.2rem', display: 'none' }}>
            <Grid item md={6}>
              <TeamBusiness />
            </Grid>
            <Grid item md={6}>
              <CardContainer>
                <AttachList
                  onClickPlus={() => {}}
                  onClickItem={(id, value) => console.log(id, value)}
                  itens={[
                    { id: 1, name: 'Termo de Referência', size: 3.25 },
                    { id: 2, name: 'Minuta Contrato', size: 1.22 },
                    { id: 3, name: 'Planilha cálculo estimativa', size: 2 },
                    { id: 4, name: 'Certidão Negativa', size: 3.3 },
                  ]}
                />
              </CardContainer>
            </Grid>
          </Grid> */}
        </Grid>
      </BodyContainer>
    </Dialog>
  );
};

export default ModalBusinessDetail;
