const config = {
  logo: require('@assets/logo.png'),
  defaultPath: '/',
  baseName: '',
  siteName: 'SigaPregão',
  drawerWidth: 260,
  gridSpacing: 3,
  gridPadding: 2,
  gridBorderRadius: 2,
  cardBorderRadius: 10,
  inputBorderRadius: 8,
  marginContainerTop: 65,
  marginContainerTopMobile: 56,
  marginTopLineGrid: 5,
  fontSizeIconSmall: 14,
  widthFull: '100%',
  buttonsBorderRadius: 8,
};

export default config;
