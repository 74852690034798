import React, { useState } from 'react';

import { FiberManualRecord, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ListContainer } from './styles';
import NavItem, { NavItemProps } from '@molecules/NavItem';
import { Collapse, ListItemText, Typography } from '@mui/material';
import NavContainer from '@atoms/NavContainer';
import NavIconContainer from '@atoms/NavIconContainer';

interface Props {
  menu: NavItemProps;
  level: number;
}
const NavCollapse: React.FC<Props> = ({ menu, level }) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const menus = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Collapse Menu Error
          </Typography>
        );
    }
  });

  const Icon = menu.icon;
  const menuIcon = menu.icon ? (
    <Icon size='1.3rem' style={{ marginTop: 'auto', marginBottom: 'auto', fontSize: '1.3rem' }} />
  ) : (
    <FiberManualRecord fontSize={level > 0 ? 'inherit' : 'medium'} />
  );

  return (
    <>
      <NavContainer selected={selected === menu.id} onClick={() => handleClick()} level={level}>
        <NavIconContainer icon={menu?.icon ? 't' : 'f'}>{menuIcon}</NavIconContainer>
        <ListItemText
          primary={
            <Typography variant={selected === menu.id ? 'h5' : 'body1'} color='inherit'>
              {menu.title}
            </Typography>
          }
          secondary={
            menu.caption && (
              <Typography variant='caption' display='block'>
                {menu.caption}
              </Typography>
            )
          }
        />
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </NavContainer>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <ListContainer disablePadding>{menus}</ListContainer>
      </Collapse>
    </>
  );
};

export default NavCollapse;
