import ModalBusinessDetail from '@/app/components/organisms/ModalBusinessDetail';
import useDebounce from '@/app/hooks/debounce';
import { getBusiness } from '@/app/services/businessService';
import { Negocio, TiposNegocio } from '@/app/services/types';
import ButtonTutorial from '@assets/ButtonTutorial.png';
import ListBusinessCard from '@molecules/ListBusinessCard';
import { Add } from '@mui/icons-material';
import { Button, Grid, Select, Typography } from '@mui/material';
import BusinessCardGroup from '@organisms/BusinessCardGroup';
import { useEffect, useState } from 'react';
import { CardContainer, Container, FilterContainer, FilterItem, SearchField } from './styles';

const BusinessCards: React.FC = () => {
  // TODO: Dados estáticos para exemplificar o funcionamento do componente
  const optionsMenu = [
    {
      label: 'Option 1',
      action: () => {
        console.log('opção 1');
      },
    },
    {
      label: 'Option 2',
      action: () => {
        console.log('opção 2');
      },
    },
    {
      label: 'Option 3',
      action: () => {
        console.log('opção 3');
      },
    },
  ];

  const [searchField, setSearchField] = useState<string>('');

  const filterBusiness = (business: Negocio[]) => {
    if (searchField === '') return business;
    const filteredBusiness = business.filter((item) => {
      return (
        item.nome.toLowerCase().includes(searchField.toLowerCase()) ||
        item.processo.numero.includes(searchField) ||
        item.processo.descricao.toLowerCase().includes(searchField.toLowerCase()) ||
        item.processo.titulo.toLowerCase().includes(searchField.toLowerCase())
      );
    });

    return filteredBusiness;
  };

  const [listaOportunidades, setListaOportunidades] = useState<Negocio[]>([]);
  const [listaDisputas, setListaDisputas] = useState<Negocio[]>([]);
  const [listaClassificacao, setListaClassificacao] = useState<Negocio[]>([]);
  const [listaContrato, setListaContrato] = useState<Negocio[]>([]);

  const [filteredListOportunidades, setFilteredListOportunidades] = useState<Negocio[]>();
  const [filteredListDisputas, setFilteredListDisputas] = useState<Negocio[]>();
  const [filteredListClassificacao, setFilteredListClassificacao] = useState<Negocio[]>();
  const [filteredListContrato, setFilteredListContrato] = useState<Negocio[]>();

  useEffect(() => {
    setFilteredListOportunidades(filterBusiness(listaOportunidades));
    setFilteredListDisputas(filterBusiness(listaDisputas));
    setFilteredListClassificacao(filterBusiness(listaClassificacao));
    setFilteredListContrato(filterBusiness(listaContrato));
  }, [searchField]);

  const [totalOportunidades, setTotalOportunidades] = useState<number>(0);
  const [totalDisputas, setTotalDisputas] = useState<number>(0);
  const [totalClassificacao, setTotalClassificacao] = useState<number>(0);
  const [totalContratos, setTotalContratos] = useState<number>(0);

  const [openModalDetail, setOpenModalDetail] = useState<boolean>(false);

  const searchBusiness = async () => {
    const opportunities = await getBusiness({ tipos: TiposNegocio.OPORTUNIDADE });
    setTotalOportunidades(opportunities.length);
    setListaOportunidades(opportunities);
    setFilteredListOportunidades(opportunities);

    const disputes = await getBusiness({ tipos: TiposNegocio.DISPUTA });
    setTotalDisputas(disputes.length);
    setListaDisputas(disputes);
    setFilteredListDisputas(disputes);

    const classification = await getBusiness({ tipos: TiposNegocio.CLASSIFICACAO });
    setTotalClassificacao(classification.length);
    setListaClassificacao(classification);
    setFilteredListClassificacao(classification);

    const contract = await getBusiness({ tipos: TiposNegocio.CONTRATO });
    setTotalContratos(contract.length);
    setListaContrato(contract);
    setFilteredListContrato(contract);
  };

  useDebounce(JSON.stringify(''), async () => searchBusiness(), 100);

  const [idItemModal, setIdItemModal] = useState<number>(0);

  const handleDetail = (item: Negocio) => {
    setIdItemModal(item.id);
    setOpenModalDetail(true);
  };

  return (
    <Container>
      <FilterContainer container spacing={2}>
        <FilterItem item md={2} xs={12}>
          <Typography variant='h1'>Meus Negócios</Typography>
        </FilterItem>
        <FilterItem item md={5} xs={12}>
          {/* TODO: implementar filtro */}
          <SearchField
            type='text'
            placeholder='Pesquisar'
            onChange={(e) => {
              setSearchField(e.target.value);
            }}
          />
        </FilterItem>
        <FilterItem item md={3} xs={12} sm={12}>
          <Button startIcon={<Add />} variant='contained' style={{ width: '100%' }}>
            Encontrar Oportunidades
          </Button>
        </FilterItem>
        <FilterItem item md={2} xs={12}>
          <img src={ButtonTutorial} height='40px' />
        </FilterItem>
      </FilterContainer>
      <FilterContainer container spacing={2}>
        {/* <FilterItem item lg={4} md={12}>
          <ComboSwitch />
        </FilterItem>
        <FilterItem item lg={3} md={12}>
          <SelectPortais />
        </FilterItem>
        <FilterItem item lg={4} md={12}></FilterItem> */}
      </FilterContainer>
      <CardContainer>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6}>
            <BusinessCardGroup
              index={1}
              themecolor='primary'
              title='OPORTUNIDADES'
              totalRecords={totalOportunidades}
              optionsMenu={optionsMenu}
            >
              {filteredListOportunidades?.map((data, index) => {
                return (
                  <ListBusinessCard
                    key={data.id}
                    themecolor='primary'
                    data={data}
                    handleDetail={() => {
                      handleDetail(data);
                    }}
                    index={index}
                  />
                );
              })}
            </BusinessCardGroup>
          </Grid>
          <Grid item lg={3} md={4} sm={6}>
            <BusinessCardGroup
              index={2}
              themecolor='warning'
              title='DISPUTA'
              totalRecords={totalDisputas}
              optionsMenu={optionsMenu}
            >
              {filteredListDisputas?.map((data, index) => {
                return (
                  <ListBusinessCard
                    key={data.id}
                    themecolor='warning'
                    data={data}
                    handleDetail={() => {
                      handleDetail(data);
                    }}
                    index={index}
                  />
                );
              })}
            </BusinessCardGroup>
          </Grid>
          <Grid item lg={3} md={4} sm={6}>
            <BusinessCardGroup
              index={3}
              themecolor='success'
              title='CLASSIFICAÇÃO'
              totalRecords={totalClassificacao}
              optionsMenu={optionsMenu}
            >
              {filteredListClassificacao?.map((data, index) => {
                return (
                  <ListBusinessCard
                    key={data.id}
                    themecolor='success'
                    data={data}
                    handleDetail={() => {
                      handleDetail(data);
                    }}
                    index={index}
                  />
                );
              })}
            </BusinessCardGroup>
          </Grid>
          <Grid item lg={3} md={4} sm={6}>
            <BusinessCardGroup index={4} title='CONTRATO' totalRecords={totalContratos} optionsMenu={optionsMenu}>
              {filteredListContrato?.map((data, index) => {
                return (
                  <ListBusinessCard
                    key={data.id}
                    data={data}
                    handleDetail={() => {
                      handleDetail(data);
                    }}
                    index={index}
                  />
                );
              })}
            </BusinessCardGroup>
          </Grid>
        </Grid>
      </CardContainer>

      <ModalBusinessDetail
        open={openModalDetail}
        onClose={() => setOpenModalDetail(false)}
        onChange={(e) => console.log('data', e)}
        idItemModal={idItemModal}
        callBack={searchBusiness}
      />
    </Container>
  );
};

export default BusinessCards;
