import React from 'react';

export default function useDebounce(value: string, execute: (value: string) => void, delay = 500) {
  React.useEffect(() => {
    const handler: NodeJS.Timeout = setTimeout(() => {
      execute(value);
    }, delay);

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
}
