import styled from '@emotion/styled';

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.header?.logo?.maxWidth ?? 120,
  paddingTop: theme.header?.logo?.paddingTop ?? 0,
  paddingBottom: theme.header?.logo?.paddingBottom ?? 0,
  paddingRight: theme.header?.logo?.paddingRight ?? 0,
  paddingLeft: theme.header?.logo?.paddingLeft ?? 0,
}));
