import styled from '@emotion/styled';

export const HeaderContainer = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flex: 1,
  alignItems: 'center',
}));

export const ContainerImg = styled.div(() => ({}));

export const Logo = styled.img(() => ({}));

export const ContainerHeader = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LabelDispensa = styled.span(() => ({
  fontWeight: '400',
  fontSize: 12,
  '@media (max-width: 420px)': {
    textAlign: 'center',
  },
}));

export const LabelNumDispensa = styled.span(() => ({
  fontWeight: '700',
  fontSize: 12,
}));

export const ContainerChip = styled.div(() => ({}));
