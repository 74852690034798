import styled from '@emotion/styled';
import { DialogContentText, DialogTitle } from '@mui/material';

export const DialogTitleContainer = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'end',
  height: '1rem',
}));

export const DialogTextContainer = styled(DialogContentText)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '1rem',
}));

export const IconAlert = styled('img')(() => ({
  width: '93px',
  height: '93px',
  marginRight: '2rem',
}));

export const MessageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const DialogActionsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '1.5rem',
}));
