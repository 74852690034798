import styled from '@emotion/styled';
import { Chip, Grid } from '@mui/material';

interface PropsChipItem {
  color?: 'primary' | 'warning' | undefined;
}

export const Container = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginTop: '1rem',
  padding: 4,
  borderRadius: 8,
  border: '1px  solid',
  borderColor: theme.palette.grey[100],

  '@media (max-width: 420px)': {
    padding: 2,
  },
}));

export const ChipItem = styled(Chip)<PropsChipItem>(({ theme, color }) => ({
  height: '1.2rem',
  color: color === 'warning' ? theme.palette[color].main : theme.palette.background.paper,
  backgroundColor: color ? theme.palette[color].light : theme.palette.grey[500],
  fontSize: '9px',
  fontWeight: color === 'primary' ? theme.typography.fontWeightMedium : theme.typography.fontWeightBold,
  margin: '0 8px',
}));

export const GirdMonitored = styled(Grid)(() => ({
  height: '50%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
}));

export const ContainerLinkWithPreview = styled(Grid)(() => ({
  padding: '1rem',
  '@media (max-width: 420px)': {
    padding: '0.4rem',
  },
}));

export const ContainerPin = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const ContainerChipGroup = styled(Grid)(() => ({
  '@media (max-width: 420px)': {
    textAlign: 'center',
    padding: 0,
  },
}));
