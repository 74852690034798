import styled from '@emotion/styled';
import { Button, DialogTitle, Grid, TextField } from '@mui/material';
import InputMask from 'react-input-mask';

export const Container = styled(Grid)(() => ({
  padding: '1rem',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const FormContainer = styled('form')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const Title = styled(DialogTitle)(() => ({
  fontSize: '1rem',
}));

export const MultiswitchContainer = styled(Grid)(() => ({}));

export const FieldNameContainer = styled(Grid)(() => ({
  width: '100%',
  marginTop: '1rem',
  '>label': {
    paddingLeft: '2px',
  },
}));

export const FieldDocumentContainer = styled(Grid)(() => ({
  width: '100%',
  '>label': {
    paddingLeft: '2px',
  },
}));

export const ButtonCancel = styled(Button)(() => ({
  border: '1px solid #757575',
  background: '#FFFFFF',
  color: '#434343',
}));

export const InputDocument = styled(InputMask)(() => ({
  width: '100%',
  height: '3.5rem',
  border: '1px solid #bdbdbd',
  borderRadius: '4px',
  padding: '1.031rem 0.875rem',
  fontSize: '0.875rem',

  '&:hover': {
    outline: 'none',
    border: '1px solid #000',
  },

  '&:focus': {
    outline: 'none',
    border: '2px solid #3498ff',
  },

  placeholder: {
    color: '#bdbdbd',
  },
}));

export const InputName = styled(TextField)(() => ({
  width: '100%',
  marginBottom: '1rem',
}));
