import React, { useEffect, useRef, useState } from 'react';

import { Container } from './styles';
import { Notifications } from '@mui/icons-material';
import { Badge } from '@mui/material';
import { getNotifications } from '@services/notificationsService';
import { useAtom } from 'jotai';
import { setNotificationsAtom, notificationsAtom, notificationsCountAtom } from '@jotai/NotificationAtom';
import Popper from '@molecules/Popper';
import MainCard from '@molecules/MainCard';
import NotificationCard from '@molecules/NotificationCard';

const NotificationHeader: React.FC = () => {
  const [, setNotifications] = useAtom(setNotificationsAtom);
  const [notifications] = useAtom(notificationsAtom);
  const [notificationsCount] = useAtom(notificationsCountAtom);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    getNotifications().then((data) => {
      setNotifications(data);
    });
  }, []);

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <Container>
      <Badge
        badgeContent={notificationsCount}
        color='error'
        onClick={() => {
          setOpen(!open);
        }}
        ref={anchorRef}
      >
        <Notifications />
      </Badge>
      <Popper anchorRef={anchorRef} open={open} bottom={20} onClickAway={() => setOpen(false)}>
        <MainCard styles={{ border: true, shadow: true }} content={false}>
          <NotificationCard />
        </MainCard>
      </Popper>
    </Container>
  );
};

export default NotificationHeader;
