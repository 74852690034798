import styled from '@emotion/styled';
import { Card, Grid } from '@mui/material';

export const CardContainer = styled(Card)(() => ({
  padding: '1rem',
  height: '100%',
  '@media (max-width: 420px)': {
    padding: '0.4rem',
  },
}));

export const GridFlexRow = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
}));

export const GridFlexColumn = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ContainerTitle = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 1080px)': {
    flexDirection: 'column',
  },
}));

export const ContainerTitleColumn = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem',
  maxWidth: '80%',
  '>p': {
    color: '#656565',
    fontWeight: 'bold',
  },
  '@media (max-width: 420px)': {
    padding: '0',
    '>h5': {
      fontSize: '0.7rem',
    },
  },
}));

export const ContainerActionsEditals = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  '>a': {
    margin: '0.5rem 0.5rem 0 0',
  },
}));

export const TitleBusiness = styled('p')(() => ({
  fontSize: '0.8rem',
  fontWeight: 'bold',
  color: '#656565',
  paddingTop: '2rem',
}));

export const ContainerMonitoringLinks = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '>a': {
    margin: '0.5rem 0.5rem 0 0',
  },
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 680px)': {
    flexDirection: 'column',
  },
}));
