// See docs on: https://mui.com/pt/material-ui/customization/palette/
import { CustomTheme } from '@mui/material/styles';

const header = <CustomTheme['header']>{
  logoContainer: 228,
  toolbar: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  logo: {
    maxWidth: 180,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
};

export { header };
