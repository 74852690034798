import { atom } from 'jotai';
import config from '@app/config';

const logoAtom = atom(config.logo);
const pageTitleAtom = atom(config.siteName);
const changePageTitleAtom = atom(
  (get) => get(pageTitleAtom),
  (get, set, value: string) => {
    set(pageTitleAtom, `${config.siteName} - ${value}`);
  },
);

const openSidebarAtom = atom<boolean>(false);

export { openSidebarAtom, logoAtom, pageTitleAtom, changePageTitleAtom };
