import axios, { AxiosRequestConfig } from 'axios';
import Environment from '../environments/environment';
import { Interceptors } from './Interceptors';

export const client = axios.create({
  baseURL: Environment.getUrl(),
});

client.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const data = sessionStorage.getItem('data');

    if (data) {
      const { token } = JSON.parse(data);
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

client.interceptors.response.use(
  (response) => Interceptors.responseInterceptorSuccess(response),
  (err) => {
    return Interceptors.responseInterceptorError(err);
  },
);
