import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

interface ContainerProps {
  alingend?: string;
}

export const Container = styled(Grid)<ContainerProps>(({ alingend }) => ({
  display: 'grid',
  alignContent: 'center',
  textAlign: alingend === 'right' ? alingend : 'left',
  '@media (max-width: 420px)': {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export const OpeningTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.dark,
  fontWeight: 'bold',

  '@media (max-width: 420px)': {
    fontSize: '0.7rem',
  },
}));

export const ClosureTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: 'bold',

  '@media (max-width: 420px)': {
    fontSize: '0.7rem',
  },
}));
