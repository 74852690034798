import { Container } from './styles';
import TopMenuItem from './TopMenuItem';
import menuItems from '@jotai/MenuAtom/menuList';

const TopMenu = () => {
  const navItems = menuItems.items[0].children.map((item, index) => {
    return <TopMenuItem key={index} item={item} />;
  });

  // TODO: Adaptar para menus em grupo
  // const navItems = menuItems.items.map((item) => {
  // switch (item.type) {
  //   case 'group':
  //     return <TopMenuItem submenu key={item.id} item={item} />;
  //   default:
  //     return <TopMenuItem key={item.id} item={item} />;
  // }
  // });

  return <Container>{navItems}</Container>;
};

export default TopMenu;
