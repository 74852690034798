import styled from '@emotion/styled';
import { Box } from '@mui/material';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';

interface ContainerProps {
  fullWidth?: boolean;
  error?: boolean;
}
export const Container = styled(Box)<ContainerProps>(({ theme, fullWidth, error }) => ({
  display: 'inline-flex',
  marginTop: 6,
  flexDirection: 'column',
  position: 'relative',
  '.MuiFormHelperText-root': {
    color: `${error ? theme.palette.error.main : theme.palette.text.primary} !important`,
  },
  '&:focus-within label': {
    color: `${error ? theme.palette.error.main : theme.palette.primary.main} !important`,
  },
}));

interface LabelProps {
  error?: boolean;
}
export const Label = styled.label<LabelProps>(({ theme, error }) => ({
  color: `${error ? theme.palette.error.main : theme.palette.common.black} !important`,
  fontSize: '0.875rem',
  fontWeight: '400',
  fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
  letterSpacing: '0.00938em',
  padding: '0px 5px 0px 5px',
  position: 'absolute',
  display: 'block',
  transformOrigin: 'top left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(133% - 24px)',
  borderRadius: 15,
  left: '0',
  top: '0',
  zIndex: '6',
  transform: 'translate(14px, -5px) scale(0.75)',
  backgroundColor: theme.palette.common.white,
}));

interface CalendarProps {
  fullWidth?: boolean;
  error?: boolean;
}
// theme.palette.text.primary
export const Calendar = styled(DateRangePicker)<CalendarProps>(({ theme, fullWidth, error }) => ({
  display: 'block',
  marginBottom: 0,
  boxShadow: `0 0 0 ${error ? `1px ${theme.palette.error.main}` : '0px'} !important`,
  borderColor: `${error ? theme.palette.error.main : theme.palette.common.black} !important`,
  borderRadius: 5,
  WebkitBoxShadow: `${error ? theme.palette.error.main : theme.palette.common.white} !important`,
  '.rs-picker-toggle-active': {
    borderColor: `${error ? theme.palette.error.main : theme.palette.info.main} !important`,
    WebkitBoxShadow: `${error ? theme.palette.error.main : 'var(--rs-state-focus-shadow)'} !important`,
    boxShadow: `0 0 0 1.3px ${error ? theme.palette.error.main : 'rgb(52 152 255 / 25%)'} !important`,
  },
  '.rs-picker-toggle:hover': {
    boxShadow: `0 0 0 ${error ? `0px ${theme.palette.error.main}` : '0px'} !important`,
    borderColor: `${error ? theme.palette.error.main : theme.palette.common.black} !important`,
    borderRadius: 5,
  },
  '.rs-picker-toggle': {
    paddingRight: '66px  !important',
    '.rs-picker-toggle-wrapper': {
      width: '100% !important',
    },
    '&.rs-btn-lg': {
      paddingTop: '13px !important',
      paddingBottom: '13px !important',
    },
    '.rs-picker-toggle-clean': {
      top: '13px !important',
      '& .rs-btn-close': {
        paddingTop: '18px !important',
      },
    },
    'rs-picker-toggle-placeholder': {},
    '.rs-picker-toggle-caret': {
      top: '11px !important',
      right: '10px !important',
    },
    '.rs-picker-toggle-value': {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  '.rs-icon': {
    top: '18px',
    width: '21px !important',
    height: '21px !important',
  },

  '::placeholder': {
    color: 'red !important',
  },

  ':-ms-input-placeholder': {
    color: 'red !important',
  },

  '::-ms-input-placeholder': {
    color: 'red !important',
  },
}));
