import React from 'react';

import { Props, Container } from './styles';

const NavContainer: React.FC<Props> = ({ level, selected, onClick, children, ...rest }) => {
  return (
    <Container {...rest} level={level} selected={selected} onClick={(e) => onClick && onClick(e)}>
      {children}
    </Container>
  );
};

export default NavContainer;
