import React from 'react';
import { avatarChipSize, CustomChip } from './styles';
import { Avatar } from '@mui/material';

export interface ChipItemProps {
  icon?: string;
  label: string;
  color?: any;
}

const ChipItem: React.FC<ChipItemProps> = ({ icon, label, color }) => {
  return icon ? (
    <CustomChip
      key={label}
      avatar={<Avatar style={avatarChipSize} alt='teste' src={icon} />}
      label={label}
      color={color}
      size='small'
    />
  ) : (
    <CustomChip key={label} label={label} color={color} size='small' />
  );
};

export default ChipItem;
