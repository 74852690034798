import { client } from '../client/client';
import { Negocio } from './types';

interface BusinessParams {
  tipos: string;
}

export const getBusiness = async (params: BusinessParams): Promise<Negocio[]> => {
  return client
    .get('negocio', {
      params,
    })
    .then((response: any) => {
      return response;
    });
};

export const getBusinessById = async (id: number): Promise<Negocio | null> => {
  return client.get(`negocio/${id}`).then((response: any) => {
    return response;
  });
};

export const updateBusinessType = async (id: number, tipoNegocio: string): Promise<any> => {
  try {
    return client.put(`negocio/alterar-tipo?id=${id}&tipoNegocio=${tipoNegocio}`);
  } catch (err) {
    console.log(err);
  }
};
