import { atom } from 'jotai';

interface NotificationProp {
  id: number;
  description: string;
}
const notificationsAtom = atom<NotificationProp[]>([]);
const notificationsCountAtom = atom(0);
const setNotificationsAtom = atom(null, (get, set, value: NotificationProp[]) => {
  set(notificationsCountAtom, value.length);
  set(notificationsAtom, value);
});

export { notificationsAtom, notificationsCountAtom, setNotificationsAtom };
