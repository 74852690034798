import React from 'react';

import CardContainer from '@atoms/CardContainer';
import CardRow from '@atoms/CardRow';
import { Chip, Stack, Typography } from '@mui/material';
import CardItem from '@atoms/CardItem';
import CardHeaderContainer from '@atoms/CardHeaderContainer';
import { Link } from 'react-router-dom';

const NotificationCard: React.FC = () => {
  return (
    <CardContainer>
      <CardRow>
        <CardHeaderContainer>
          <CardItem>
            <Stack direction='row' spacing={1}>
              <Typography variant='subtitle1'>All Notification</Typography>
              <Chip size='small' label='01' color='secondary' />
            </Stack>
          </CardItem>
          <CardItem>
            <Typography component={Link} to='#' variant='subtitle2' color='primary'>
              Mark as all read
            </Typography>
          </CardItem>
        </CardHeaderContainer>
      </CardRow>
    </CardContainer>
  );
};

export default NotificationCard;
