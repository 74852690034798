import React from 'react';
import { Grid } from '@mui/material';
import {
  SummaryIndicator,
  Summary,
  SummaryNumber,
  SummaryItalic,
  TitleContainer,
  Title,
  SummaryTitle,
  NumberItems,
} from './styles';
import ContextMenu from '@molecules/ContextMenu';

interface Props {
  index: number;
  title: string;
  totalRecords: number;
  optionsMenu: { label: string; action: () => void }[];
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
  children: React.ReactElement | React.ReactNode;
}

const BusinessCardGroup: React.FC<Props> = ({ index, title, totalRecords, optionsMenu, themecolor, children }) => {
  return (
    <Grid>
      <Summary container item md={12} themecolor={themecolor}>
        <SummaryIndicator item lg={3}>
          <SummaryNumber>{index}</SummaryNumber>
          <SummaryItalic>FASE</SummaryItalic>
        </SummaryIndicator>
        <SummaryTitle item lg={9}>
          <TitleContainer container item md={12}>
            <Title>{title}</Title>
            <NumberItems>{totalRecords}</NumberItems>
            {/* <ContextMenu options={optionsMenu} /> */}
          </TitleContainer>
        </SummaryTitle>
      </Summary>
      <Grid>{children}</Grid>
    </Grid>
  );
};

export default BusinessCardGroup;
