import styled from '@emotion/styled';
import { AppBar, Box, Toolbar } from '@mui/material';

export const Container = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.background.paper,
}));

export const Bar = styled(Toolbar)(({ theme }) => ({
  paddingTop: theme.header.toolbar.paddingTop,
  paddingBottom: theme.header.toolbar.paddingBottom,
  justifyContent: 'space-between',
}));

export const Spacer = styled(Box)(() => ({
  flexGrow: 1,
}));

export const LogoSection = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.down('md') ? theme.header?.logoContainer : 'auto',
  display: 'flex',

  '@media (max-width: 420px)': {
    width: '100%',
  },
}));

export const LogoContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  verticalAlign: 'middle',
  display: theme.breakpoints.up('md') ? 'inline-flex' : theme.breakpoints.down('md') ? 'none' : 'inline-flex',
}));

export const NotificationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '@media (max-width: 420px)': {
    display: 'none',
  },
}));
