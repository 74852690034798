import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import AlertMessage from '@/app/components/atoms/AlertMessage';

import MultiSwitch from '@/app/components/molecules/MultiSwitch';
import Tip from '@/app/components/molecules/Tip';
import { addCompany } from '@/app/services/companyService';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  ButtonCancel,
  Container,
  FieldDocumentContainer,
  FieldNameContainer,
  FormContainer,
  InputDocument,
  InputName,
  MultiswitchContainer,
  Title,
} from './styles';
import { textFieldsPerType } from './textFieldsPerType';

export interface IIdentifier {
  label: string;
  value: string;
  documentType: 'cpf' | 'cnpj';
}

interface ModalCadastroEmpresaProps {}

export type ModalCadastroEmpresaHandle = {
  showModal: () => void;
};

export type AlertHandle = {
  showModal: () => void;
};

const ModalCadastroEmpresa = (props: ModalCadastroEmpresaProps, ref: React.Ref<ModalCadastroEmpresaHandle>) => {
  const [open, setOpen] = useState(false);
  const [identifier, setIdentifier] = useState<IIdentifier>({
    label: 'Pessoa Física',
    value: '1',
    documentType: 'cpf',
  });
  const [alertMessage, setAlertMessage] = useState({
    title: 'Sucesso',
    success: true,
    description: 'Empresa Cadastrada com sucesso!',
  });

  const [name, setName] = useState('');
  const [document, setDocument] = useState('');

  const handleNewCompany = async (e: any) => {
    e.preventDefault();
    const data = {
      nome: name,
      cnpj: document,
    };

    if (data) {
      const resposta = await addCompany(data);

      if (resposta === 'limite_excedido') {
        setAlertMessage({
          title: 'Atenção',
          success: false,
          description: `Você alcançou o seu limite máximo de Empresas.
          Que tal realizar agora um Upgrade do seu plano SIGA PREGÃO e desbloquear a quantidade de Empresas que você pode utilizar? Fale agora com nossa equipe de atendimento.`,
        });
      } else {
        setAlertMessage({
          title: 'Sucesso',
          success: true,
          description: 'Empresa Cadastrada com sucesso!',
        });
      }

      setName('');
      setDocument('');
      alertRef?.current?.showModal();
    }
  };

  const optionsIdentifier: Array<IIdentifier> = [
    { label: 'Pessoa Física', value: '1', documentType: 'cpf' },
    { label: 'Pessoa Jurídica', value: '2', documentType: 'cnpj' },
  ];

  useImperativeHandle(ref, () => ({
    showModal: () => handleClickOpen(),
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Ref do modal de cadastro de empresa
  const alertRef = useRef<AlertHandle>(null);

  // const aref = useRef(null);
  const inputRef = useRef(null);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Title>Adicionar empresa</Title>
        <FormContainer onSubmit={handleNewCompany}>
          <DialogContent>
            <Tip
              type='info'
              showIcon={false}
              label=''
              description='O SIGA Pregão utiliza o nome da sua empresa e o número do CNPJ para identificar nas mensagens do chat de pregões eletrônicos do Comprasnet quando sua empresa for citada. É importante fornecer o nome exato da sua empresa para que ela possa ser identificada quando for citada pelo pregoeiro (nos caso em que a empresa for citada apenas pelo nome, sem conter o número do CNPJ na mensagem).'
            />

            <Container container item md={12}>
              <MultiswitchContainer item>
                <MultiSwitch
                  defaultValue={'1'}
                  options={optionsIdentifier}
                  onChange={(e) => {
                    const optionSelected = optionsIdentifier.filter((item: IIdentifier) => item.value === e);
                    setDocument('');
                    setIdentifier(optionSelected[0]);
                  }}
                />
              </MultiswitchContainer>
              <FieldNameContainer item>
                <label>{textFieldsPerType[identifier.documentType].name.label}</label>

                <InputName
                  id='nome'
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                  placeholder={textFieldsPerType[identifier.documentType].name.placeHolder}
                  aria-label='Nome da empresa'
                  inputRef={inputRef}
                  required
                />
              </FieldNameContainer>

              <FieldDocumentContainer item>
                <label>{textFieldsPerType[identifier.documentType].document.label}</label>

                <InputDocument
                  id='cnpj'
                  value={document}
                  onChange={({ target }) => setDocument(target.value)}
                  placeholder={textFieldsPerType[identifier.documentType].document.placeHolder}
                  aria-label='Número do documento'
                  mask={textFieldsPerType[identifier.documentType].document.mask}
                  required
                />
              </FieldDocumentContainer>
            </Container>
          </DialogContent>
          <DialogActions>
            <ButtonCancel variant='contained' color='inherit' onClick={handleClose}>
              Cancelar
            </ButtonCancel>
            <Button type='submit' variant='contained' color='primary'>
              Cadastrar
            </Button>
          </DialogActions>
        </FormContainer>
      </Dialog>

      <AlertMessage message={alertMessage} ref={alertRef} />
    </div>
  );
};

export default forwardRef(ModalCadastroEmpresa);
