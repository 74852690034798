// assets
import { Dashboard } from '@mui/icons-material';

// constant
const icons = { Dashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'dashboard',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Oportunidades',
      type: 'item',
      url: '/opportunity',
      icon: icons.Dashboard,
      breadcrumbs: true,
    },
    {
      id: 'default',
      title: 'Jornal',
      type: 'item',
      url: '/newspapers',
      icon: icons.Dashboard,
      breadcrumbs: true,
    },
    {
      id: 'default',
      title: 'Meus Negócios',
      type: 'item',
      url: '/business',
      icon: icons.Dashboard,
      breadcrumbs: true,
    },
  ],
};

export default dashboard;
