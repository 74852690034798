import ChipItem from '@/app/components/atoms/ChipItem';
import { getIcons } from '@/app/services/portalService';
import { Process } from '@/app/services/processService';
import {
  ContainerChip,
  ContainerHeader,
  ContainerImg,
  HeaderContainer,
  LabelDispensa,
  LabelNumDispensa,
  Logo,
} from './styles';

interface HeaderProps {
  data: Process | null;
}

export const Header = ({ data }: HeaderProps) => {
  return (
    <HeaderContainer>
      <ContainerImg>
        <Logo src={getIcons(data?.portal).icon} alt={getIcons(data?.portal).name} />
      </ContainerImg>
      <ContainerHeader>
        <LabelDispensa>{`${data?.nomeModalidade}`}</LabelDispensa>
        <LabelNumDispensa>Nº {data?.numero}</LabelNumDispensa>
      </ContainerHeader>
      <ContainerChip>
        <ChipItem label='Monitorada' color='warning' />
      </ContainerChip>
    </HeaderContainer>
  );
};
