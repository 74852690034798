import React from 'react';
import ChipItem from '@atoms/ChipItem';
import { Container } from './styles';

interface ChipItem {
  icon?: string;
  label: string;
  color?: any;
}

interface Props {
  data: ChipItem[];
}

const ChipGroup: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      {data.map((item) => {
        return <ChipItem key={item.label} icon={item.icon} label={item.label} color={item.color} />;
      })}
    </Container>
  );
};

export default ChipGroup;
