import React from 'react';

import { Container } from './styles';

interface Props {
  children: React.ReactElement | React.ReactNode;
}
const CardRow: React.FC<Props> = ({ children }) => {
  return <Container item>{children}</Container>;
};

export default CardRow;
