import CalendarField from '@/app/components/molecules/CalendarField';
import InputField from '@/app/components/molecules/InputField';
import OpportunityItem, { OpportunityItemType } from '@/app/components/molecules/OpportunityItem';
import CheckBoxMultPortal from '@/app/components/organisms/CheckBoxMultPortal';
import ModalDetailOpportunity, {
  ModalDetailOpportunityhandle,
} from '@/app/components/organisms/ModalDetailOpportunity';
import ModalElectronicExemptions from '@/app/components/organisms/ModalElectronicExemptions';
import ModalNewspapers from '@/app/components/organisms/ModalNewspapers';
import MultiSwitchModality from '@/app/components/organisms/MultiSwitchModality';
import MultiSwitchPeriodType from '@/app/components/organisms/MultiSwitchPeriodType';
import SelectFieldOrgao from '@/app/components/organisms/SelectFieldOrgao';
import SelectFieldUF from '@/app/components/organisms/SelectFieldUF';
import useDebounce from '@/app/hooks/debounce';
import { useAlertService } from '@/app/services/alertService';
import { createNewspapers, Jornal } from '@/app/services/newspapersService';
import { getOpportunities, InputOpportunities } from '@/app/services/opportunitiesService';
import ArrowUpIcon from '@assets/ArrowUpIcon.svg';
import { useForm } from '@hooks/form';
import { List, Search } from '@mui/icons-material';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { formFields } from './formFields';
import {
  ArrowIcon,
  ButtonAddSearch,
  ButtonDispensasEletronicas,
  Container,
  ContainerAddSearch,
  ContainerButtonSaveFilters,
  ContainerFilters,
  ContainerFiltersFooter,
  ContainerItems,
  ContainerPesquisaAvancada,
  ContainerRowFilters,
  ContainerRowSwitches,
  ContainerSearch,
  ContentAction,
  ContentFilters,
  ContentSpacing,
  Description,
  GridComboCustom,
  HelpInput,
  SelectNewspapers,
  TitleHeader,
} from './styles';

const Newspapers: React.FC = () => {
  const [advancedSearchIdVisible, setAdvancedSearchIdVisible] = useState(false);
  const [openModalNewspapers, setOpenModalNewspapers] = useState<boolean>(false);
  const [openModalElectronic, setOpenModalElectronic] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataList, setDataList] = useState<OpportunityItemType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [newspapers, setNewspapers] = useState<{ data: Jornal | undefined }>({
    data: undefined,
  });
  const { addAlert } = useAlertService();

  const modalDetail = useRef<ModalDetailOpportunityhandle>(null);

  const [defaultInitialDate, setDefaultInitialDate] = useState<Date>(new Date(formFields.dataInicial.value));
  const [defaultFinalDate, setDefaultFinalDate] = useState<Date>(new Date(formFields.dataFinal.value));

  const onHandleSubmit = async (values: any) => {
    console.log('teste');
    setLoading(true);
    try {
      const data = await createNewspapers(values);
      if (!data.id) {
        return addAlert({
          type: 'error',
          message: 'Erro ao tentar comunicar com servidor, tente novamente.',
        });
      }
      return addAlert({
        type: 'success',
        message: 'Salvo com sucesso!',
      });
    } catch (error: any) {
      if (error && error.status) {
        setOpenModalNewspapers(true);
        if (error.status === 401) {
          return addAlert({
            type: 'error',
            message: error.data.message,
          });
        } else if (error.status >= 500) {
          return addAlert({
            type: 'error',
            message: 'Erro ao tentar comunicar com servidor, tente novamente.',
          });
        }
      }
    }
    setLoading(false);
  };

  const { values, touched, errors, handleOnChange, handleSubmit, setFieldValue } = useForm(formFields, onHandleSubmit);

  const searchOpportunity = async (clear = false) => {
    let data = clear ? [] : dataList || [];
    const newPage = clear ? 0 : page;
    const opportunities = await getOpportunities({
      ...(values as InputOpportunities),
      page: newPage,
    });
    setPage(newPage + 1);
    data = [...data, ...(opportunities as unknown as OpportunityItemType[])];
    setDataList(data);
  };

  useDebounce(JSON.stringify(values), async () => searchOpportunity(true), 600);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const listBox = event.currentTarget;
    const x = listBox.scrollTop + listBox.clientHeight;
    if (listBox.scrollHeight - (x + 1) <= 1) {
      const top = listBox.scrollTop;
      await searchOpportunity();
      setTimeout(() => listBox.scrollTo({ top }), 40);
    }
  };

  useEffect(() => {
    if (newspapers) {
      setFieldValue('palavras', newspapers.data?.palavras.join(';'));
      setFieldValue('modalidades', newspapers.data?.modalidades.join(','));
      setFieldValue('excluir', newspapers.data?.excluir.join(';'));
      setFieldValue('limitar', newspapers.data?.limitar.join(';'));
      setFieldValue('portais', newspapers.data?.portais.join(','));
      setFieldValue('ufs', newspapers.data?.ufs.join(','));
    }
  }, [newspapers]);

  const handlePeriodType = () => {
    const currentDate = new Date();
    const add15DaysInCurrentDate = addDays(new Date(), 14);
    const sub15DaysInCurrentDate = subDays(new Date(), 15);

    if (values.tipoPeriodo === 'abertura') {
      setDefaultInitialDate(currentDate);
      setDefaultFinalDate(add15DaysInCurrentDate);

      setFieldValue('dataInicial', format(currentDate, 'yyyy-MM-dd'));
      setFieldValue('dataFinal', format(add15DaysInCurrentDate, 'yyyy-MM-dd'));
    } else {
      setDefaultInitialDate(sub15DaysInCurrentDate);
      setDefaultFinalDate(currentDate);

      setFieldValue('dataInicial', format(sub15DaysInCurrentDate, 'yyyy-MM-dd'));
      setFieldValue('dataFinal', format(currentDate, 'yyyy-MM-dd'));
    }
  };

  useEffect(() => {
    handlePeriodType();
  }, [values.tipoPeriodo]);

  const handleAdvancedSearch = () => {
    setAdvancedSearchIdVisible(!advancedSearchIdVisible);
  };

  return (
    <Container onScroll={handleScroll}>
      <Paper elevation={3} style={{ backgroundColor: '#fafafa' }}>
        <ContainerFilters>
          <ContainerRowFilters container>
            <TitleHeader item xs={12} md={12} xl={3}>
              <Typography variant='h1'>Seu Jornal</Typography>
              <Description>Acompanhe seus filtros personalizados e não perca nenhuma oportunidade.</Description>
            </TitleHeader>
            <ContainerSearch container item xs={12} md={12} xl={9}>
              <SelectNewspapers
                id='data'
                formErrors={touched.nomeJornal ? errors : {}}
                form={newspapers}
                handleChange={(id, item) => {
                  setNewspapers({
                    data: item,
                  });
                }}
                Icon={List}
                bgIcon={'#FFFFFF'}
              />
              <ContainerAddSearch item xs={12} md={12} xl={12}>
                <HelpInput>Selecione uma lista personalizada ou crie uma nova</HelpInput>
                <ButtonAddSearch>
                  {/* TODO: Tirar dúvida de como seria essa adição de filtro */}
                  {/* <Add /> */}
                  {/* <LabelAddSearch>Adicionar filtro</LabelAddSearch> */}
                </ButtonAddSearch>
              </ContainerAddSearch>
            </ContainerSearch>

            <ContainerSearch container item xs={12} md={12} xl={8}>
              <InputField
                id='palavras'
                form={values}
                formErrors={touched.search ? errors : {}}
                handleChange={handleOnChange}
                placeholder='O que você deseja persquisar'
                positionIcon='start'
                Icon={Search}
                bgIcon='#FFFFFF'
              />
              <HelpInput>Insira palavras-chave, separadas por vírgula. Exemplo: papel, resma, envelope</HelpInput>
            </ContainerSearch>
          </ContainerRowFilters>

          <ContainerRowSwitches container>
            <ContentFilters>
              <MultiSwitchModality onChange={(e) => setFieldValue('modalidades', e.toString())} />
            </ContentFilters>
            <ContentFilters>
              <MultiSwitchPeriodType onChange={(e) => setFieldValue('tipoPeriodo', e.toString())} />
            </ContentFilters>
            <ContentFilters>
              <CalendarField
                id='calendar'
                form={values}
                formErrors={touched.calendar ? errors : {}}
                handleChange={(id: string, value: any) => {
                  if (value) {
                    setFieldValue('dataInicial', format(value[0], 'yyyy-MM-dd'));
                    setFieldValue('dataFinal', format(value[1], 'yyyy-MM-dd'));
                  }
                }}
                value={[defaultInitialDate, defaultFinalDate]}
              />
            </ContentFilters>
          </ContainerRowSwitches>

          <ContainerPesquisaAvancada display={advancedSearchIdVisible ? 'block' : 'none'}>
            <Typography variant='h4'>Pesquisa Avançada</Typography>
            <ContainerRowFilters container item spacing={2}>
              <Grid item lg={6} md={12}>
                <label>Deseja excluir alguma palavra da busca?</label>
                <InputField
                  id='excluir'
                  form={values}
                  formErrors={touched.excluir ? errors : {}}
                  placeholder='Digite a palavra para excluir da busca'
                  handleChange={handleOnChange}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <label>Deseja limitar alguma palavra da busca?</label>
                <InputField
                  id='limitar'
                  form={values}
                  formErrors={touched.limitSearch ? errors : {}}
                  placeholder='Digite a palavra para limitar da busca'
                  handleChange={handleOnChange}
                />
              </Grid>
            </ContainerRowFilters>
            <ContainerRowFilters container item spacing={2}>
              <Grid item lg={6} md={12}>
                <GridComboCustom item>
                  <CheckBoxMultPortal
                    onChange={(itens) => setFieldValue('portais', itens.map((a) => a.id).join(','))}
                    itensChecked={values['portais']?.split(',')}
                  />
                </GridComboCustom>
              </Grid>
              <Grid item lg={6} md={12}>
                <GridComboCustom item>
                  <Typography variant='h6'>Escolha os estados que deseja encontrar oportunidades</Typography>
                  <SelectFieldUF
                    id='ufs'
                    form={values}
                    handleChange={(id, value) => setFieldValue(id, value)}
                    formErrors={touched.newspapers ? errors : {}}
                  />
                </GridComboCustom>
              </Grid>
            </ContainerRowFilters>
            <ContainerRowFilters container item spacing={2}>
              <Grid item lg={6} md={12}>
                <SelectFieldOrgao
                  id='orgao'
                  form={values}
                  handleChange={(id, item) => setFieldValue(id, item ? item.value : '')}
                  formErrors={touched.orgao ? errors : {}}
                  portal={values['portais'] || ''}
                />
              </Grid>
              <Grid item lg={6} md={12}>
                <ButtonDispensasEletronicas onClick={() => setOpenModalElectronic(true)}>
                  Dispensas eletrônicas
                </ButtonDispensasEletronicas>
              </Grid>
            </ContainerRowFilters>
            <ContainerRowFilters container item spacing={2}>
              <ContainerButtonSaveFilters item lg={12} md={12}>
                <Button variant='contained' onClick={() => setOpenModalNewspapers(true)}>
                  Salvar Filtro
                </Button>
              </ContainerButtonSaveFilters>
            </ContainerRowFilters>
          </ContainerPesquisaAvancada>
        </ContainerFilters>

        <ContainerFiltersFooter>
          <ContentAction onClick={handleAdvancedSearch}>
            <p>{advancedSearchIdVisible ? 'Fechar' : 'Abrir'} Pesquisa </p>
            <ArrowIcon src={ArrowUpIcon} opened={advancedSearchIdVisible} />
          </ContentAction>
          <ContentSpacing></ContentSpacing>
        </ContainerFiltersFooter>

        <ModalDetailOpportunity ref={modalDetail} />

        <ModalNewspapers
          open={openModalNewspapers}
          onClose={() => setOpenModalNewspapers(false)}
          onChange={(_, value) => {
            setFieldValue('nomeJornal', value);
            onHandleSubmit({ ...values, nomeJornal: value });
          }}
        />
        <ModalElectronicExemptions
          open={openModalElectronic}
          form={values}
          formError={touched}
          onChange={(value, e) => {
            setOpenModalElectronic(false);
            Object.keys(value).map((a) => setFieldValue(a, value[a]));
          }}
        />
      </Paper>
      <ContainerItems onScroll={handleScroll}>
        {dataList?.map((data, index) => (
          <OpportunityItem key={index} data={data} onClick={(d) => modalDetail?.current?.showModal(data.id)} />
        ))}
      </ContainerItems>
    </Container>
  );
};

export default Newspapers;
