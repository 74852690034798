// See docs on: https://mui.com/pt/material-ui/customization/palette/
import { CustomProps } from '@/@types/theme';

const customPalette = <CustomProps['customPalette']>{
  text: {
    primary: '#000',
  },
};

export { customPalette };
