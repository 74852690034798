import React, { useEffect } from 'react';
import MultiSwitch, { MultiSwitchProps } from '../../molecules/MultiSwitch';

export interface MultiSwitchModalityProps extends Omit<MultiSwitchProps, 'options' | 'defaultValue'> {}

const MultiSwitchModality: React.FC<MultiSwitchModalityProps> = ({ onChange, business }) => {
  useEffect(() => {}, []);

  return (
    <MultiSwitch
      business={business}
      defaultValue={'1,2'}
      options={[
        { label: 'Todos', value: '1,2' },
        { label: 'Pregão Eletrônico', value: '1' },
        { label: 'Dispensa Eletrônica', value: '2' },
      ]}
      onChange={onChange}
    />
  );
};

export default MultiSwitchModality;
