import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputField from '../../molecules/InputField';
import Modal, { ModalProps } from '../../molecules/Modal';

export interface ModalNewspapersProps extends Omit<ModalProps, 'onChange' | 'children'> {
  onChange: (id: string, value: string, event: React.ChangeEvent<any>) => void;
}

const ModalNewspapers: React.FC<ModalNewspapersProps> = ({ open, onChange, onClose }) => {
  const [values, setValues] = useState({
    name: '',
  });

  const [errors, setErrors] = useState({
    name: '',
  });

  const [show, setShow] = useState(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleSave = (e: any) => {
    if (!values.name || (values.name && values.name.length <= 3)) {
      setErrors({
        name: 'Informe um nome válido',
      });
      return;
    }

    onChange('name', values.name, e);
    setShow(false);
    onClose && onClose(e, 'backdropClick');
  };

  const footer = () => {
    return (
      <div>
        <Button variant='contained' onClick={handleSave}>
          Salvar
        </Button>
      </div>
    );
  };

  return (
    <Modal open={show} onClose={onClose} header='Salvar Jornal' maxWidth='sm' footer={footer()}>
      <div>
        <InputField
          id='name'
          form={values}
          formErrors={errors}
          placeholder='Informe o nome para Jornal'
          handleChange={(_, value) => setValues({ name: value })}
        />
      </div>
    </Modal>
  );
};

export default ModalNewspapers;
