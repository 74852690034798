import { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

export interface FormValidation {
  [key: string]: Array<any>;
}
export interface FormFields {
  [key: string]: {
    value: any;
    required?: boolean;
    customRequiredText?: string;
    fieldType?: 'string' | 'number' | 'boolean' | 'any';
    fieldValidations?: FormValidation;
  };
}

export interface FormValues {
  [key: string]: any;
}

export const useForm = (formFields: FormFields = {}, onSubmit: (values: any, event: any) => void) => {
  const getInitialValues = () => {
    const initialValues: FormValues = {};
    Object.keys(formFields).forEach((key: string) => {
      initialValues[key] = formFields[key].value;
    });
    return initialValues;
  };

  const getValidationSchema = () => {
    const validationSchema: any = {};
    Object.keys(formFields).forEach((key: string) => {
      const field = formFields[key];

      switch (field.fieldType) {
        case 'string':
          validationSchema[key] = yup.string();
          break;
        case 'number':
          validationSchema[key] = yup.number();
          break;
        default:
          validationSchema[key] = yup.string();
          break;
      }
      if (field.required) {
        validationSchema[key] = validationSchema[key].required(field.customRequiredText || 'Campo obrigatório');
      }

      if (field.fieldValidations) {
        Object.keys(field.fieldValidations).forEach((validationKey: string) => {
          if (field?.fieldValidations !== undefined && field.fieldValidations[validationKey] !== undefined) {
            validationSchema[key] = validationSchema[key][validationKey](...field.fieldValidations[validationKey]);
          }
        });
      }
    });
    return validationSchema;
  };

  const { touched, errors, values, handleChange, setFieldValue, handleSubmit, resetForm, setSubmitting } = useFormik({
    initialValues: getInitialValues(),
    validationSchema: yup.object(getValidationSchema()),
    onSubmit: onSubmit,
  });

  const handleOnChange = (id: string, value: any, event: any) => {
    handleChange(event);
  };

  return { values, touched, errors, handleOnChange, handleSubmit, resetForm, setSubmitting, setFieldValue };
};
