import { client } from '../client/client';

export interface Orgao {
  id: number;
  text: string;
}

export interface ResultOrgao {
  pagination: string;
  results?: Orgao[];
}

export interface InputFilterOrgao extends Record<string, string> {
  term: string;
  portal: string;
  page: string;
}

export const getOrgaos = (params: InputFilterOrgao): Promise<ResultOrgao> => {
  if (params.term) {
    return client.get(`/oportunidades/search-orgao?${new URLSearchParams(params).toString()}`);
  } else {
    return new Promise((resolve) => {
      return resolve({
        pagination: 'false',
        results: [],
      });
    });
  }
};
