import styled from '@emotion/styled';
import { Chip } from '@mui/material';

interface PropsChipItem {
  color?: 'primary' | 'warning' | undefined;
}

export const CustomChip = styled(Chip)<PropsChipItem>(({ theme, color }) => ({
  height: '1rem',
  color: color === 'warning' ? theme.palette[color].main : theme.palette.background.paper,
  backgroundColor: color ? theme.palette[color].light : theme.palette.grey[500],
  fontSize: '9px',
  fontWeight: color === 'primary' ? theme.typography.fontWeightMedium : theme.typography.fontWeightBold,
  marginRight: 4,
}));

export const avatarChipSize = {
  height: '12px',
  width: '12px',
};
