import styled from '@emotion/styled';
import { List } from '@mui/material';

export const ListContainer = styled(List)(({ theme }) => ({
  position: 'relative',
  '&:after': {
    content: '',
    position: 'absolute',
    left: 32,
    top: 0,
    height: '100%',
    width: 1,
    opacity: 1,
    background: theme.palette.primary.light,
    border: '1px red solid',
  },
}));
