import React from 'react';

import {
  Image
} from './styles'; 

interface Props{
  alt?: string,
  src: string
}

const Logo: React.FC<Props> = ({src, alt}) => {
    return (
        <Image alt={alt ?? 'Logo'} src={src}  />
    );
};

export default Logo;
