import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface PropsType {
  type: 'info' | 'warning' | 'alert';
}

export const Container = styled(Box)<PropsType>(({ theme, type }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: (theme.palette as any)[type].light,
  color: (theme.palette as any)[type].dark,
  minHeight: '58px',
  height: 'max-content',
  padding: '1rem 1rem',
  borderRadius: '8px',
  '> div': {
    span: {
      fontWeight: 'bold',
    },
  },
}));

export const ContainerIcon = styled('div')<PropsType>(({ theme, type }) => ({
  display: 'flex',
  width: '55px',
  height: '55px',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: (theme.palette as any)[type].main,
  color: theme.palette.background.paper,
  textAlign: 'center',
  borderRadius: '18%',
  padding: '0.8rem',
  margin: '-1.8rem 2rem 0 2rem',
  transform: 'rotate(-15.89deg)',
  svg: {
    transform: 'rotate(45deg)',
  },
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const LabelContainer = styled('div')(() => ({}));

export const LabelSpan = styled('span')(() => ({}));
