import React from 'react';

import { Container, IconContainer } from './styles';

interface Props {
  variant?: 'circular' | 'rounded' | 'square' | undefined;
  icon?: any;
  onClick: () => void;
  innerRef?: React.Ref<any>;
  styles?: {
    borderRadius?: number;
    backgroundColor?: string;
    backgroundColorHover?: string;
    colorHover?: string;
    color?: string;
    width?: number;
    height?: number;
    fontSize?: number | string;
  };
}

const IconButton: React.FC<Props> = ({ variant, icon, onClick = () => {}, styles, innerRef }) => {
  return (
    <Container styles={styles} ref={innerRef}>
      <IconContainer onClick={onClick} styles={styles} variant={variant ?? 'rounded'}>
        {icon}
      </IconContainer>
    </Container>
  );
};

export default IconButton;
