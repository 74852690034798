import React from 'react';
import { PushPin, SvgIconComponent } from '@mui/icons-material';
import { ContainerIcon, Container, LabelContainer, LabelSpan } from './styles';

interface Props {
  label?: string;
  description: string;
  Icon?: SvgIconComponent;
  type: 'info' | 'warning' | 'alert';
  showIcon?: boolean;
}

const Tip: React.FC<Props> = ({ label = 'DICA: ', description, Icon = PushPin, type = 'info', showIcon = true }) => {
  return (
    <Container type={type}>
      <LabelContainer>
        <LabelSpan>{label}</LabelSpan> {description}
      </LabelContainer>
      {showIcon && (
        <ContainerIcon type={type}>
          <Icon />
        </ContainerIcon>
      )}
    </Container>
  );
};

export default Tip;
