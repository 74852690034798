import styled from '@emotion/styled';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const LinksContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

export const Links = styled('a')(({ theme }) => ({
  display: 'flex',
  borderColor: theme.palette.grey[900],
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.grey[400],
  border: '1px solid',
  borderRadius: 8,
  padding: '0 0.5rem',
  fontSize: '1rem',
  margin: '1rem 0.2rem',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.grey[800],
  },
}));
