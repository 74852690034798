import config from '@/app/config';
import styled from '@emotion/styled';
import { Button, Grid, Box, Typography } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  borderRadius: config.gridBorderRadius,
  backgroundColor: theme.palette.background.paper,
}));

export const DescriptionContainer = styled(Grid)(() => ({
  padding: '1.5rem 0.875rem 1.375rem 1.0625rem',
}));

export const ActionsContainer = styled(Grid)(() => ({
  paddingTop: '1rem',
  paddingBottom: '0.75rem',
  textAlign: 'center',
}));

export const OpportunityContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const SendDisputeButton = styled(Box)(({ theme }) => ({
  width: '8.5rem',
  height: '5.4375rem',
  backgroundColor: theme.palette.grey[100],
  textAlign: 'center',
  padding: '1rem',
  margin: 'auto',
  cursor: 'pointer',
}));

export const SendDisputeDescription = styled('p')(() => ({
  fontSize: '0.5625rem',
  marginTop: '0.5rem',
}));

export const CountdownButton = styled(Button)(({ theme }) => ({
  color: theme.palette.error.main,
  border: 'none',
  backgroundColor: theme.palette.error.light,
  fontSize: '0.8125rem',
  marginTop: '0.8125rem',
}));

export const PeriodContainer = styled(Grid)(() => ({
  textAlign: 'right',
  paddingRight: '1.25rem',
}));

export const PeriodDescription = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: '400',
}));

export const OpportunityButton = styled(Button)(({ theme }) => ({
  width: '8.125rem',
  height: '1.25rem',
  fontSize: '0.75rem',
  fontWeight: theme.typography.subtitle1.fontWeight,
}));
