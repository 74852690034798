import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const Container = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',

  '@media (max-width: 420px)': {
    display: 'none',
  },

  '>button': {
    borderRadius: 0,
    margin: '0 4px',
    borderBottom: '2px #FFF solid',
    '&:hover': {
      borderColor: '#539319',
      backgroundColor: '#fff',
      color: 'red',
    },
  },
}));
