import React, { useEffect, useState } from 'react';
import CheckBoxMult, { CheckBoxMultProps } from '../CheckBoxMult';

export interface CheckBoxMultPortalProps extends Omit<CheckBoxMultProps, 'itens'> {
  itensChecked?: string[];
}

const CheckBoxMultPortal: React.FC<CheckBoxMultPortalProps> = ({ itensChecked, ...rest }) => {
  const [itens, setItens] = useState([
    { id: 'CN', label: 'ComprasNet', checked: false },
    { id: 'PCP', label: 'Portal de Compras Públicas', checked: false },
    { id: 'BECSP', label: 'BEC - SP', checked: false },
    { id: 'LICE', label: 'Licitações-e', checked: false },
    { id: 'CAIXA', label: 'Licitaçoes Caixa', checked: false },
    { id: 'BANRISUL', label: 'Banrisul', checked: false },
    { id: 'COMPRASRS', label: 'COMPRAS RS', checked: false },
    { id: 'COMPRASBA', label: 'COMPRAS BAHIA', checked: false },
    { id: 'COMPRASAM', label: 'COMPRAS AMAZONAS', checked: false },
    { id: 'COMPRASGO', label: 'COMPRASNET GOIÁS', checked: false },
    { id: 'COMPRASRJ', label: 'COMPRAS RJ', checked: false },
    { id: 'COMPRASRE', label: 'COMPRAS RECIFE', checked: false },
    { id: 'LICITANET', label: 'Licitanet', checked: false },
    { id: 'BLL', label: 'BLL COMPRAS', checked: false },
    { id: 'COMPRASSC', label: 'PORTAL e-LIC - SANTA CATARINA', checked: false },
    { id: 'PROCERGS', label: 'PROCERGS', checked: false },
    { id: 'COMPRASMG', label: 'COMPRAS MINAS GERAIS', checked: false },
    { id: 'BANPARA', label: 'BANPARÁ', checked: false },
    { id: 'PEINTEGRADO', label: 'PE Integrado', checked: false },
  ]);

  useEffect(() => {
    if (itensChecked) {
      setItens(
        itens.map((a) => ({
          ...a,
          checked: itensChecked.includes(a.id),
        })),
      );
    }
  }, [itensChecked]);

  return <CheckBoxMult {...rest} title='Escolha os portais de compras' itens={itens} />;
};

export default CheckBoxMultPortal;
