import React, { useEffect, useRef, useState } from 'react';

import { Container, Label, MenuCard, UserIcon } from './styles';
import { Logout, Settings } from '@mui/icons-material';

import Popper from '@molecules/Popper';
import MainCard from '@molecules/MainCard';
import {
  Avatar,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AuthProvider, useAuth } from '@/app/contexts/auth';

const UserHeader: React.FC = () => {
  const { user } = useAuth();
  const { signOut } = useAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const prevOpen = useRef(open);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const stringAvatar = (name: string) => {
    const splitedName = name.split(' ');

    return {
      sx: {
        textTransform: 'uppercase',
        backgroundColor: theme.palette.primary.light,
      },
      children: `${splitedName[0][0]}${splitedName.length > 1 ? splitedName[1][0] : splitedName[0][1]}`,
    };
  };

  const handleButtonClick = (routeAlias: string, params: any = {}) => {};
  const handleLogout = () => {
    signOut();
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <Container>
      <Label>{user?.nome}</Label>
      <UserIcon
        icon={<Avatar {...stringAvatar(user?.nome || 'Usuário Teste')} />}
        ref={anchorRef}
        variant='filled'
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        color='info'
      />

      <Popper anchorRef={anchorRef} open={open} bottom={20} onClickAway={() => setOpen(false)}>
        <MainCard styles={{ border: true, shadow: true }} content={false}>
          <MenuCard>
            <Stack>
              <Stack direction='row' spacing={0.5} alignItems='center'>
                <Typography variant='h4'>Olá, </Typography>
                <Typography component='span' variant='h4' sx={{ fontWeight: 400 }}>
                  {user?.nome}
                </Typography>
              </Stack>
              <Typography variant='subtitle2'>Usuário</Typography>
            </Stack>
            <Divider />
            <List component='nav'>
              <ListItemButton onClick={() => handleButtonClick('panel.profile.edit')}>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText>Pefil</ListItemText>
              </ListItemButton>

              <ListItemButton onClick={() => handleLogout()}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText>Sair</ListItemText>
              </ListItemButton>
            </List>
          </MenuCard>
        </MainCard>
      </Popper>
    </Container>
  );
};

export default UserHeader;
