import CardContainer from '@atoms/CardContainer';
import OpportunityCard, { OpportunityItemProps } from '@molecules/OpportunityCard';
import { ShoppingBag } from '@mui/icons-material';
import { Card, Grid, Typography } from '@mui/material';
import { Container } from './styles';

const OpportunityCardPage: React.FC = () => {
  // TODO: Dados de exemplo
  const dataListItem: OpportunityItemProps = {
    listChip: [
      {
        Icon: ShoppingBag,
        label: 'COMPRASNET',
      },
      {
        label: 'Dispensa Eletônica',
        color: 'primary',
      },
    ],
    content: {
      title: 'Comprasnet - Pregão eletrônico Nº 1/2022 |195019 - 11º SUPERINTENDENCIA REGIONAL ',
      titleHref: 'http://www.google.com',
      prev: 'PREGÃO ELETRÔNICO Nº 1/2022 - 11º SUPERINTENDENCIA REGIONAL AMAPA - Amapá',
    },
    progress: {
      label: 'tarefas',
      total: 100,
      value: 33,
      color: 'secondary',
    },
    options: [
      {
        label: 'Option 1',
        action: () => {
          console.log('opção 1');
        },
      },
      {
        label: 'Option 2',
        action: () => {
          console.log('opção 2');
        },
      },
      {
        label: 'Option 3',
        action: () => {
          console.log('opção 3');
        },
      },
    ],
    startAt: new Date(),
    endAt: new Date(),
    remainingDays: 3,
  };

  return (
    <Container>
      <Typography variant='h1'>Meus negócios</Typography>
      <Card>
        <CardContainer>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <OpportunityCard data={dataListItem} />
            </Grid>

            <Grid item xs={4}>
              <OpportunityCard data={dataListItem} />
            </Grid>

            <Grid item xs={4}>
              <OpportunityCard data={dataListItem} />
            </Grid>
          </Grid>
        </CardContainer>
      </Card>
    </Container>
  );
};

export default OpportunityCardPage;
