import { CustomTheme } from '@mui/material/styles';

const components = <CustomTheme['components']>{
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        display: 'flex',
        padding: 0,
        margin: 0,
        height: 40,
        borderRadius: 5,
        border: '1px solid',
        borderColor: '#bdbdbd',
        maxWidth: '500px',
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        borderRadius: 5,
        color: '#000',

        '&:hover': {
          backgroundColor: '#fff',
        },

        '&.Mui-selected': {
          backgroundColor: '#5494F5',
          color: '#fff',
        },

        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },

        '.MuiBottomNavigationAction-label': {
          display: 'flex',
          fontSize: '0.8rem',
          width: 'auto',
        },
      },
    },
  },
};

export { components };
