import styled from '@emotion/styled';

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

export const Column = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const Label = styled.span(() => ({}));

interface LineProps {
  minWidth?: number;
  marginRight?: number;
}

export const Line = styled.div<LineProps>(({ minWidth }) => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth,
  marginRight: '0.875rem',
}));

export const ContainerDataEncerramento = styled.div(() => ({
  marginRight: 10,
}));

export const ContainerTipoFiltroEncerramento = styled(ContainerDataEncerramento)(() => ({
  minWidth: 110,
}));

export const ContainerHoraEncerramento = styled(ContainerTipoFiltroEncerramento)(() => ({}));
