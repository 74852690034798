import { client } from '../client/client';

export interface Jornal {
  id: number;
  clienteId: number;
  nomeJornal: string;
  orgao: string;
  palavras: string[];
  excluir: string[];
  limitar: string[];
  modalidades: string[];
  portais: string[];
  ufs: string[];
  situacaoDispensa: string;
  statusItemDispensa: string;
}

export interface ResultJornal {
  message: string;
  data?: Jornal[];
}

export interface InputJornal {
  nomeJornal: string;
  palavras: string;
  excluir: string;
  limitar: string;
  ufs: string;
  portais: string;
  modalidades: string;
  orgao: string;
}

export const getNewspapers = (): Promise<Jornal[]> => {
  try {
    return client.get('/jornal');
  } catch (err) {
    return new Promise((resolve) => resolve([] as Jornal[]));
  }
};

export const createNewspapers = (value: InputJornal): Promise<Jornal> => {
  return client.post('/jornal', value);
};
