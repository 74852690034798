import { Item, ItemProcess } from '@/app/services/processService';

const bodyItens = (item: Item) => {
  return (
    <div style={{ marginBottom: 15 }}>
      <span>{item.descricao}</span>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ fontWeight: 'bold' }}>Unidade:</span>
        <span>{item.unidade}</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ fontWeight: 'bold' }}>Quantidade:</span>
        <span>{item.quantidade}</span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <span style={{ fontWeight: 'bold' }}>Valor de referência: </span>
        <span>{` R$ ${item.valorReferencia}`}</span>
      </div>
    </div>
  );
};

export const getItensGrupo = (item: ItemProcess) => {
  const grupos = item.grupos.map((a) => ({
    id: a.numero,
    checked: false,
    highlightThrowUp: false,
    title: `${a.tipo} ${a.numero} -  ${a.itens[0].descricao}  ${
      a.itens.length > 1 ? `[ e mais ${a.itens.length - 1} itens]` : ''
    }`,
    body: <>{a.itens.map((a) => bodyItens(a))}</>,
  }));

  const items = item.itens.map((a) => ({
    id: a.numero,
    checked: false,
    highlightThrowUp: false,
    title: `${a.tipo} ${a.numero} -  ${a.descricao} `,
    body: <>{bodyItens(a)}</>,
  }));

  return [...grupos, ...items];
};
