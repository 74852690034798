import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

interface Props {
  size?: 'small' | 'medium' | undefined;
  card?: boolean;
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
}

interface SubtitleProps {
  size?: 'small' | 'medium' | undefined;
  card?: boolean;
}

export const ContainerTitleLink = styled(Grid)(() => ({
  flexDirection: 'column',
  marginLeft: '0.5rem',
  '@media (max-width: 420px)': {
    margin: 0,
  },
}));

export const TitleLink = styled('a')<Props>(({ size, card = false, theme, themecolor }) => ({
  textTransform: 'uppercase',
  textDecoration: 'none',
  fontSize: size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.h4.fontSize,
  fontWeight: card ? 'bold' : 'normal',
  color: themecolor ? (theme.palette as any)[themecolor].dark : theme.palette.grey[900],
  marginBottom: '0.5rem',

  '&:hover': {
    opacity: '1',
    textDecoration: 'none',
  },

  '@media (max-width: 420px)': {
    fontSize: '0.8rem',
    textAlign: 'justify',
    fontWeight: 'bold',
    marginBottom: '0.8rem',
  },
}));

export const Subtitle = styled(Typography)<SubtitleProps>(({ size, card, theme }) => ({
  fontSize: size === 'small' ? theme.typography.subtitle2.fontSize : theme.typography.h4.fontSize,
  marginTop: card ? '10px' : '0',

  '@media (max-width: 420px)': {
    fontSize: '0.7rem',
    textAlign: 'justify',
  },
}));
