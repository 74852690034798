import React from 'react';

import { Container, ContainerIcon } from './styles';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import useWindowDimensions from '@/app/hooks/useWindowDimensions';

type InputFieldProps = TextFieldProps & {
  id: string;
  form?: any;
  formErrors?: any;
  handleChange?: (id: string, value: any, event?: React.ChangeEvent<any>) => void;
  positionIcon?: 'start' | 'end' | undefined;
  Icon?: SvgIconComponent;
  bgIcon?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  fullWidth,
  variant,
  id,
  form,
  formErrors,
  helperText,
  label,
  positionIcon,
  bgIcon,
  handleChange,
  Icon,
  ...rest
}) => {
  const containsIcon = positionIcon ? true : false;
  const { width } = useWindowDimensions();

  const getHelperText = () => {
    if (formErrors[id] !== undefined && formErrors[id] !== '') {
      return formErrors[id];
    }
    if (helperText) {
      if (width > 630) return helperText;
    }
    return ' ';
  };
  const onChange = (e: any) => {
    if (handleChange) {
      handleChange(id, e.target.value, e);
    }
  };

  const getInputProps = () => {
    return (
      positionIcon &&
      Icon && {
        InputProps: {
          style: { padding: '0px' },
          [`${positionIcon?.toString()}Adornment`]: (
            <InputAdornment position='start' style={positionIcon == 'end' ? { marginRight: '8px' } : {}}>
              <ContainerIcon bgIcon={bgIcon} positionIcon={positionIcon}>
                <Icon fontSize='large' />
              </ContainerIcon>
            </InputAdornment>
          ),
        },
      }
    );
  };

  return (
    <Container containsIcon={containsIcon} fullWidth={fullWidth ?? true}>
      <TextField
        name={id}
        fullWidth={fullWidth ?? true}
        variant={variant ?? 'outlined'}
        {...rest}
        value={form[id] ?? ''}
        onChange={onChange}
        label={label}
        helperText={getHelperText()}
        error={!!formErrors[id]}
        {...getInputProps()}
      />
    </Container>
  );
};

export default InputField;
