import React from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';
import { getCompanies } from '@/app/services/companyService';

interface CompanyResponse {
  id: string;
  nome: string;
}

export interface SelectFieldCompanyProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo> {
  infoText?: string;
}

const SelectFieldCompany: React.FC<SelectFieldCompanyProps> = ({ ...rest }) => {
  const loadCompany = async () => {
    try {
      const companies = await getCompanies();
      const companiesFormatted = companies.map((company: CompanyResponse) => {
        return {
          id: company.id,
          text: `${company.id} - ${company.nome}`,
        };
      });

      return companiesFormatted || [];
    } catch (err) {
      return [];
    }
  };

  return (
    <SelectField
      {...rest}
      placeholder='Selecione ou digite o nome da empresa'
      fetch={loadCompany}
      fieldParamsSearch='term'
      fieldLabel='text'
      nameFieldValue='id'
    />
  );
};

export default SelectFieldCompany;
