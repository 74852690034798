import React, { useEffect, useState } from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';

export interface SelectFieldElectronicExemptionsSituationProps<
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo>, 'multiple'> {}

const SelectFieldElectronicExemptionsSituation: React.FC<SelectFieldElectronicExemptionsSituationProps> = ({
  form,
  id,
  handleChange,
  ...rest
}) => {
  const listSituations = [
    { value: '', label: 'Todas' },
    { value: 'Em Andamento', label: 'Em Andamento' },
    { value: 'Encerrada', label: 'Encerrada' },
    { value: 'Homologada', label: 'Homologada' },
    { value: 'Cancelada', label: 'Cancelada' },
  ];

  return (
    <SelectField
      id={id}
      {...rest}
      form={form}
      handleChange={(_, item, e) => handleChange && handleChange(id, item, e)}
      placeholder='Selecione ou digite a Situação'
      options={listSituations}
    />
  );
};

export default SelectFieldElectronicExemptionsSituation;
