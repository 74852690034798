import React, { forwardRef } from 'react';

import { useMediaQuery, useTheme, ListItemText, Typography, Chip, Avatar, ChipProps } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { openSidebarAtom } from '@jotai/ThemeAtom';
import { useUpdateAtom } from 'jotai/utils';
import NavContainer from '@atoms/NavContainer';
import NavIconContainer from '@atoms/NavIconContainer';

export interface NavItemProps {
  id: string;
  title: string;
  caption?: string;
  type: string;
  url?: string;
  icon?: any;
  breadcrumbs?: boolean;
  external?: boolean;
  target?: boolean;
  disabled?: boolean;
  children?: NavItemProps[];
  chip?: ChipProps;
}

interface Props {
  item: NavItemProps;
  level: number;
}

const NavItem: React.FC<Props> = ({ item, level }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('lg'));
  const openSidebar = useUpdateAtom(openSidebarAtom);
  let itemTarget = '_self';
  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon
      sx={{
        fontSize: '1.3rem',
      }}
    />
  ) : (
    <FiberManualRecord fontSize={level > 0 ? 'inherit' : 'medium'} />
  );

  if (item.target) {
    itemTarget = '_blank';
  }
  const LinkComponent = forwardRef((props, ref: any) => (
    <Link ref={ref} to={item.url ?? ''} target={itemTarget} {...props} />
  ));
  LinkComponent.displayName = 'LinkComponent';

  let listItemProps: any = {
    component: LinkComponent,
  };
  if (item?.external === true) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const handleClick = () => {
    if (matchesSm) {
      openSidebar(false);
    }
  };

  // TODO: Implementar alteração de rota atual, inclusive quando entrar pela url
  // useEffect(() => {
  //   const currentIndex = document.location.pathname.toString().split('/');
  // }, [document.location]);

  return (
    <NavContainer {...listItemProps} disabled={item.disabled} level={level} onClick={() => handleClick()}>
      <NavIconContainer icon={item?.icon ? 't' : 'f'}>{itemIcon}</NavIconContainer>
      <ListItemText
        primary={
          <Typography color='inherit' variant='body1'>
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant='caption' display='block'>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </NavContainer>
  );
};

export default NavItem;
