import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const Container = styled(Grid)(({ theme }) => ({
  padding: '0 0.5rem',

  '>button': {
    fontWeight: '500',
    fontSize: '1rem',
    color: theme.palette.grey[800],
    borderRadius: 0,

    '&:hover': {
      borderBottom: `2px ${theme.palette.success.dark} solid`,
      marginBottom: '-2px',
      backgroundColor: theme.palette.background.paper,
    },

    '> a': {
      textDecoration: 'none',
    },
  },
}));
