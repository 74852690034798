import React from 'react';
import { ThemeProvider } from '@mui/system';
import { Button } from '@mui/material';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { themeButtons } from '../../organisms/ModalBusinessDetail/styles';
import { Negocio } from '@/app/services/types';
import { updateBusinessType } from '@/app/services/businessService';

interface StepsBusinessProps {
  item: Negocio;
  callBack: (id: number) => void;
}

// Componente que possibilita mover um negócio entre seus tipos
const StepsBusiness: React.FC<StepsBusinessProps> = ({ item, callBack }) => {
  const updateBusiness = (type: string) => {
    updateBusinessType(item.id, type);
    callBack(item.id);
    callBack(item.id); // TODO: só funciona se chamar 2 vezes, investigar o porque
  };

  const getBotaoOportunidade = () => {
    return (
      <Button
        variant='contained'
        color='secondary'
        startIcon={<KeyboardTabIcon />}
        onClick={() => {
          updateBusiness('disputa');
        }}
      >
        Enviar para disputa
      </Button>
    );
  };

  const getBotaoDisputa = () => {
    return (
      <>
        <Button
          variant='outlined'
          startIcon={<KeyboardTabIcon style={{ color: '#757575', transform: 'rotate(180deg)' }} />}
          onClick={() => {
            updateBusiness('oportunidade');
          }}
        >
          Enviar para oportunidades
        </Button>

        <Button
          variant='contained'
          color='secondary'
          startIcon={<KeyboardTabIcon />}
          onClick={() => {
            updateBusiness('classificacao');
          }}
        >
          Enviar para classificação
        </Button>
      </>
    );
  };

  const getBotaoClassificacao = () => {
    return (
      <>
        <Button
          variant='outlined'
          startIcon={<KeyboardTabIcon style={{ color: '#757575', transform: 'rotate(180deg)' }} />}
          onClick={() => {
            updateBusiness('disputa');
          }}
        >
          Enviar para disputa
        </Button>

        <Button
          variant='contained'
          color='secondary'
          startIcon={<KeyboardTabIcon />}
          onClick={() => {
            updateBusiness('contrato');
          }}
        >
          Enviar para contrato
        </Button>
      </>
    );
  };

  const getBotaoContrato = () => {
    return (
      <Button
        variant='outlined'
        startIcon={<KeyboardTabIcon style={{ color: '#757575', transform: 'rotate(180deg)' }} />}
        onClick={() => {
          updateBusiness('classificacao');
        }}
      >
        Enviar para classificação
      </Button>
    );
  };

  return (
    <ThemeProvider theme={themeButtons}>
      {item.tipoNegocio === 'oportunidade' ? getBotaoOportunidade() : ''}
      {item.tipoNegocio === 'disputa' ? getBotaoDisputa() : ''}
      {item.tipoNegocio === 'classificacao' ? getBotaoClassificacao() : ''}
      {item.tipoNegocio === 'contrato' ? getBotaoContrato() : ''}
    </ThemeProvider>
  );
};

export default StepsBusiness;
