import { getNewspapers } from '@/app/services/newspapersService';
import { getOrgaos, InputFilterOrgao } from '@/app/services/orgaoService';
import React, { useEffect, useState } from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';

export interface SelectFieldOrgaoProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo> {}

const SelectFieldNewspapers: React.FC<SelectFieldOrgaoProps> = ({ ...rest }) => {
  const loadNewspapers = async () => {
    try {
      const newspapers = await getNewspapers();
      return newspapers || [];
    } catch (err) {
      return [];
    }
  };

  return (
    <SelectField
      {...rest}
      placeholder='Selecione um jornal'
      fetch={loadNewspapers}
      fieldLabel='nomeJornal'
      nameFieldValue='id'
    />
  );
};

export default SelectFieldNewspapers;
