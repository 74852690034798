import { Checkbox, Typography, IconButton } from '@mui/material';
import { CheckCircleRounded, CircleOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

import {
  Body,
  ButtonSelectAll,
  ButtonUnSelectAll,
  CheckboxLabel,
  Container,
  Footer,
  Header,
  HeaderButton,
  LabelSelectCount,
} from './styles';

export interface ItemCheckBoxMult {
  id: string | number;
  label: string;
  checked: boolean;
}

export interface CheckBoxMultProps {
  title?: string;
  itens: ItemCheckBoxMult[];
  onChange: (itensChecked: ItemCheckBoxMult[]) => void;
}

export const CheckBoxMult: React.FC<CheckBoxMultProps> = ({ title, itens, onChange }) => {
  const [listCheck, setListCheck] = useState<ItemCheckBoxMult[]>(itens);

  useEffect(() => {
    setListCheck(itens);
  }, [itens]);

  const handleOnCheck = (id: string | number, value: boolean) => {
    const index = listCheck.findIndex((e) => e.id == id);
    const newList = listCheck;
    newList[index].checked = value;
    setListCheck([...newList]);
    onChange(newList.filter((e) => e.checked));
  };

  const handleAllCheck = () => {
    setListCheck(listCheck.map((e) => ({ ...e, checked: true })));
  };

  const handleUnAllCheck = () => {
    setListCheck(listCheck.map((e) => ({ ...e, checked: false })));
  };

  return (
    <Container>
      <Header>
        <Typography variant='h6'>{title}</Typography>
        <HeaderButton>
          <IconButton onClick={handleAllCheck}>
            <ButtonSelectAll>Selecionar todos</ButtonSelectAll>
          </IconButton>
          <IconButton onClick={handleUnAllCheck}>
            <ButtonUnSelectAll>Desmarcar todos</ButtonUnSelectAll>
          </IconButton>
        </HeaderButton>
      </Header>
      <Body>
        {listCheck.map((item) => (
          <CheckboxLabel
            key={item.id}
            label={item.label}
            checked={item.checked}
            control={
              <Checkbox
                checked={item.checked}
                value={item.checked}
                onChange={(e) => handleOnCheck(item.id, e.target.checked)}
                icon={<CircleOutlined />}
                checkedIcon={<CheckCircleRounded />}
              />
            }
          />
        ))}
      </Body>
      <Footer>
        <LabelSelectCount>
          Selecionados {listCheck.filter((e) => e.checked).length}/{listCheck.length}
        </LabelSelectCount>
      </Footer>
    </Container>
  );
};

export default CheckBoxMult;
