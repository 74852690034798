import { client } from '../client/client';

export interface Item {
  acimaReferencia: boolean;
  descricao: string;
  melhorLance: number;
  numero: string;
  quantidade: number;
  situacao: string;
  temProposta: boolean;
  tipo: string;
  unidade: string;
  valorReferencia: number;
}

export interface Grupo {
  itens: Item[];
  melhorLance: number;
  numero: string;
  situacao: any;
  tipo: string;
  valorReferencia: number;
}

export interface ItemProcess {
  grupos: Grupo[];
  itens: Item[];
}

export interface Process {
  abertura: Date;
  aberturaFormatada: string;
  arquivada: boolean;
  arquivosCaixa: any[];
  codigo: number;
  dadosUasg: any;
  dataAtualizacaoFormatada: string;
  encerramento: any;
  encerramentoFormatado: string;
  id: number;
  itens: ItemProcess;
  modalidade: number;
  nomeModalidade: string;
  nomePortal: string;
  numero: string;
  objeto: string;
  orgao: string;
  portal: string;
  publicacao: string;
  qtdAvisos: number;
  qtdEsclarecimentos: number;
  qtdImpugnacoes: number;
  qtdMensagens: number;
  situacao: string;
  status: string;
  suspenso: string;
  uasg: string;
  uf: string;
  urlAppSiga: string;
  urlAvisos: any;
  urlEdital: any;
  urlEsclarecimentos: any;
  urlExterna: string;
  urlImpugnacoes: string;
  urlMensagens: string;
  urlSigaAntigo: string;
}

export const getProcess = (id: number): Promise<Process | null> => {
  try {
    return client.get(`/processo/pub/processo?id=${id}`);
  } catch (err) {
    return new Promise((resolve) => resolve(null));
  }
};
