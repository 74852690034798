import styled from '@emotion/styled';
import config from '@/app/config';
import { Grid, Typography } from '@mui/material';

interface PropsType {
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
}

export const Container = styled(Grid)(() => ({}));

export const SummaryIndicator = styled(Grid)(({ theme }) => ({
  color: theme.palette.background.default,
  padding: '0 15px 10px 15px',
  textAlign: 'center',

  '@media (max-width: 1290px)': {
    display: 'none',
  },
}));

export const Summary = styled(Grid)<PropsType>(({ theme, themecolor }) => ({
  width: config.widthFull,
  display: 'flex',
  backgroundColor: themecolor ? (theme.palette as any)[themecolor].dark : theme.palette.grey[900],
  borderTopLeftRadius: config.cardBorderRadius, // TODO: TEMPORARIO
  borderTopRightRadius: config.cardBorderRadius,
  borderBottomRightRadius: config.cardBorderRadius,
  borderBottomLeftRadius: config.cardBorderRadius,
  justifyContent: 'center', // TODO: TEMPORARIO
  alignItems: 'center', // TODO: TEMPORARIO
}));

export const SummaryNumber = styled('h4')(() => ({
  '@media (max-width: 1290px)': {
    fontSize: '0.9rem',
  },
}));

export const SummaryItalic = styled('i')(() => ({}));

export const TitleContainer = styled(Grid)(() => ({
  width: config.widthFull,
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  // marginLeft: 16, //TODO: TEMPORARIO
  marginTop: '10px', // TODO: TEMPORARIO

  '@media (max-width: 1290px)': {
    justifyContent: 'center',
  },
}));

export const Title = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: '#fff',
  fontSize: '1rem',

  '@media (max-width: 1290px)': {
    fontSize: '0.9rem',
  },
}));

export const SummaryTitle = styled(Grid)(({ theme }) => ({
  width: config.widthFull,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 10,
  // backgroundColor: theme.palette.background.default, TODO: TEMPORARIO
  borderTopRightRadius: config.cardBorderRadius,
  borderBottomRightRadius: config.cardBorderRadius,
  borderBottomLeftRadius: config.cardBorderRadius,
}));

export const NumberItems = styled('p')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  margin: '-1px 12px 0 12px',
  padding: '6px 12px',
  borderRadius: 48,
  fontWeight: 'light',
}));
