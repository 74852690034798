import React, { useEffect, useState } from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';

export interface SelectFieldElectronicExemptionsStatusItemsProps<
  Multiple extends boolean | undefined = true,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo>, 'multiple'> {}

const SelectFieldElectronicExemptionsStatusItems: React.FC<SelectFieldElectronicExemptionsStatusItemsProps> = ({
  form,
  id,
  handleChange,
  ...rest
}) => {
  const listStatus = [
    { value: '', label: 'Todas' },
    { value: 'desertos', label: 'Com itens desertos' },
    { value: 'acima_referencia', label: 'Itens com lance acima da referência' },
  ];

  return (
    <SelectField
      id={id}
      {...rest}
      form={form}
      handleChange={(_, item, e) => handleChange && handleChange(id, item, e)}
      placeholder='Selecione ou digite o Status'
      options={listStatus}
    />
  );
};

export default SelectFieldElectronicExemptionsStatusItems;
