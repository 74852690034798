import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const Container = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: '500',
  color: theme.palette.grey[900],
  padding: 6,
  textTransform: 'capitalize',
  marginTop: 10,
}));

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: '0.6875rem',
  fontWeight: '500',
  color: theme.palette.grey[500],
  textTransform: 'capitalize',
}));
