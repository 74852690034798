export const textFieldsPerType = {
  cpf: {
    name: {
      label: 'Nome',
      placeHolder: 'Nome do titular responsável',
      mask: '',
    },
    document: {
      label: 'CPF',
      placeHolder: 'CPF do titular responsável',
      mask: '999.999.999-99',
    },
  },
  cnpj: {
    name: {
      label: 'Nome',
      placeHolder: 'Nome da Empresa (utilizado para identificar citações no chat)',
      mask: '',
    },
    document: {
      label: 'CNPJ',
      placeHolder: 'Nº de CNPJ da empresa',
      mask: '99.999.999/9999-99',
    },
  },
};
