import React, { useState } from 'react';
import BgLogin from '@assets/bg_login.png';
import LgLogin from '@assets/lg_login.png';
import LgSigaPregao from '@assets/lg_siga_pregao.png';

import {
  CardLogin,
  Container,
  ContainerLogin,
  Logo,
  SigaPregao,
  Title,
  ButtonLogin,
  LabelRegister,
  InputEmail,
  InputPassword,
  CircularLoading,
} from './styles';
import { Link } from 'react-router-dom';

import { login } from '@/app/services/loginService';
import { Alert, Collapse, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAuth } from '@/app/contexts/auth';
import { FormFields, useForm } from '@hooks/form';

const Login: React.FC = () => {
  const { signIn } = useAuth();

  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const formFields: FormFields = {
    email: {
      value: '',
      required: true,
      customRequiredText: 'O campo e-mail é obrigatório',
      fieldType: 'string',
      fieldValidations: {
        email: ['O campo e-mail deve ser um e-mail válido'],
      },
    },
    senha: {
      value: '',
      required: true,
      fieldType: 'string',
      fieldValidations: {
        min: [6, 'A senha deve ter no mínimo 6 caracteres'],
      },
    },
  };

  const onHandleSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    setSubmitting(false);
    setLoading(true);
    setOpen(false);
    try {
      const data = await login(values);
      if (data.data) {
        signIn(data.data);
        resetForm();
      } else {
        setOpen(true);
        setMessage('Erro ao tentar comunicar com servidor, tente novamente.');
      }
    } catch (error: any) {
      if (error && error.status) {
        setOpen(true);
        if (error.status === 401) {
          setMessage(error.data.message);
        } else if (error.response.status >= 500) {
          setMessage('Erro ao tentar comunicar com servidor, tente novamente.');
        }
      }
    }
    setLoading(false);
    setSubmitting(true);
  };

  const { values, touched, errors, handleOnChange, handleSubmit } = useForm(formFields, onHandleSubmit);

  return (
    <Container bgSrc={BgLogin}>
      <Logo src={LgLogin} />
      <ContainerLogin>
        <CardLogin
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <SigaPregao src={LgSigaPregao} height={90} />
          <Title>Bem vindo!</Title>
          <Collapse in={open} style={{ marginTop: 15 }}>
            <Alert
              severity='error'
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Close fontSize='inherit' />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
          <InputEmail
            id='email'
            label='Email'
            form={values}
            handleChange={handleOnChange}
            formErrors={touched.email ? errors : {}}
          />
          <InputPassword
            id='senha'
            label='Senha'
            type='password'
            form={values}
            handleChange={handleOnChange}
            formErrors={touched.senha ? errors : {}}
          />
          <ButtonLogin type='submit' variant='contained' disabled={loading}>
            {loading && <CircularLoading color='inherit' size={24} />}
            Entrar
          </ButtonLogin>
          <LabelRegister>
            <span>Precisa de ajuda para acessar?</span>
            <span>Entre em contato com</span>
            <a href={'mailto:suporte@sigapregao.com.br'}>suporte@sigapregao.com.br</a>
          </LabelRegister>
        </CardLogin>
      </ContainerLogin>
    </Container>
  );
};

export default Login;
