import styled from '@emotion/styled';
import { Avatar, Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  marginRight: '2rem',
  '& .MuiBadge-root': {
    cursor: 'pointer',
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.grey[500],
    },
  },
}));
