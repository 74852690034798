import { client } from '../client/client';

export interface InputLogin {
  email: string;
  senha: string;
}

export interface ResultLogin {
  message: string;
  data?: {
    nome: string;
    email: string;
    token: string;
  };
}

export const login = (data: InputLogin): Promise<ResultLogin> => {
  return client.post('/flash/pub/login', data);
};
