import React from 'react';
import { Grid } from '@mui/material';
import ContextMenu from '../ContextMenu';
import LinkWithPreview from '@atoms/LinkWithPreview';
import ChipGroup from '@molecules/ChipGroup';
import { SvgIconComponent } from '@mui/icons-material';
import { ReactComponent as SendDisputeIcon } from '@assets/SendDisputeIcon.svg';
import { ReactComponent as ClockIcon } from '@assets/ClockIcon.svg';
import {
  Container,
  OpportunityButton,
  DescriptionContainer,
  ActionsContainer,
  OpportunityContainer,
  SendDisputeButton,
  SendDisputeDescription,
  CountdownButton,
  PeriodContainer,
  PeriodDescription,
} from './styles';
import { format } from 'date-fns';

export interface ListChip {
  Icon?: SvgIconComponent;
  label: string;
  color?: 'primary' | undefined;
}

export interface OpportunityItemProps {
  listChip: ListChip[];
  content: {
    title: string;
    titleHref: string;
    prev: string;
  };
  progress: {
    label: string;
    total: number;
    value: number;
    color: 'secondary' | 'success' | 'primary' | 'error' | 'info' | 'warning';
  };
  options: {
    label: string;
    action: () => void;
  }[];
  startAt: Date;
  endAt?: Date;
  remainingDays?: number;
}

export interface OpportunityCardProps {
  data: OpportunityItemProps;
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({ data }) => {
  return (
    <Container container>
      <DescriptionContainer xs={7}>
        <LinkWithPreview
          title={data.content.title}
          titleHref={data.content.titleHref}
          prev={data.content.prev}
          size={'small'}
        />
      </DescriptionContainer>
      <ActionsContainer xs={5}>
        <OpportunityContainer>
          <OpportunityButton variant='contained' disableElevation>
            Oportunidade
          </OpportunityButton>
          <ContextMenu options={data.options} />
        </OpportunityContainer>
        <Grid>
          <SendDisputeButton>
            <SendDisputeIcon />
            <SendDisputeDescription>ENVIAR PARA DISPUTA</SendDisputeDescription>
          </SendDisputeButton>
        </Grid>
        <Grid>
          <CountdownButton variant='outlined' startIcon={<ClockIcon />}>
            {data.remainingDays && data.remainingDays > 1
              ? `${data.remainingDays} dias restantes`
              : `${data.remainingDays} dia restante`}
          </CountdownButton>
        </Grid>
      </ActionsContainer>
      <Grid xs={6}>{/* <ChipGroup data={data.listChip} /> */}</Grid>
      <PeriodContainer xs={6}>
        <PeriodDescription>
          Abertura em {format(data.startAt, 'dd/MM/yyyy')} às {format(data.startAt, 'HH:mm')}
        </PeriodDescription>
        <PeriodDescription>
          {data.endAt && 'Encerramento em ' + format(data.endAt, 'dd/MM/yyyy') + 'às' + format(data.endAt, 'HH:mm')}
        </PeriodDescription>
      </PeriodContainer>
    </Container>
  );
};

export default OpportunityCard;
