import React from 'react';

import LinkWithPreview from '@atoms/LinkWithPreview';
import ChipGroup from '@molecules/ChipGroup';
import { getIcons } from '@/app/services/portalService';

import {
  Container,
  Header,
  TypographyhOpeningDate,
  ChipsContainer,
  DateContainer,
  GridProgress,
  Footer,
} from './styles';

import { Negocio } from '@/app/services/types';
import { getModalidade } from '@/app/services/modalidadeService';

// TODO: Centralizar esta interface no componente ChipItem
export interface ChipItem {
  icon?: string;
  label: string;
  color?: 'primary' | undefined;
}

export interface BusinessCardProps {
  listChip: ChipItem[];
  content: {
    title: string;
    titleHref: string;
    prev: string;
  };
  progress: {
    label: string;
    total: number;
    value: number;
    color: 'secondary' | 'success' | 'primary' | 'error' | 'info' | 'warning';
  };
  options: {
    label: string;
    action: () => void;
  }[];
  startAt: Date;
}

export interface ListBusinessCardProps {
  data: Negocio;
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
  handleDetail?: () => void;
  index?: number;
}

const ListBusinessCard: React.FC<ListBusinessCardProps> = ({ data, themecolor, handleDetail, index }) => {
  const listChip: Array<ChipItem> = [];

  if (data.processo.portal) {
    listChip.push({
      icon: getIcons(data.processo.portal).icon,
      label: getIcons(data.processo.portal).name,
    });
  }

  if (data.processo.modalidade) {
    listChip.push({
      label: getModalidade(data.processo.modalidade),
      color: 'primary',
    });
  }

  return (
    <Container container onClick={handleDetail} first={index === 0 ? 'true' : 'false'}>
      <Header>
        <LinkWithPreview
          title={data.nome}
          titleHref={'#'}
          prev={data.processo.titulo}
          size={'small'}
          themecolor={themecolor}
        />
      </Header>
      <ChipsContainer>
        <ChipGroup data={listChip} />
      </ChipsContainer>

      <DateContainer>
        <TypographyhOpeningDate variant={'body2'}>Abertura em {data.processo.aberturaFormatada}</TypographyhOpeningDate>
      </DateContainer>

      <GridProgress>
        {/* <ProgressField
          label={data.progress.label}
          total={data.progress.total}
          value={data.progress.value}
          color={data.progress.color}
          title={false}
        /> */}
      </GridProgress>

      <Footer themecolor={themecolor}></Footer>
    </Container>
  );
};

export default ListBusinessCard;
