import React from 'react';

import { Container, LogoBox, LogoContainer, MobileContainer, ScrollContainer } from './styles';
import { useAtomValue } from 'jotai';
import { openSidebarAtom } from '@jotai/ThemeAtom';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import { useUpdateAtom } from 'jotai/utils';
import LogoHeader from '@molecules/LogoHeader';
import { BrowserView, MobileView } from 'react-device-detect';
import MenuList from '@organisms/MenuList';
import config from '@app/config';

const SideBar: React.FC = () => {
  const theme = useTheme();
  const matchupmd = useMediaQuery(theme.breakpoints.up('md'));
  const heightCalc = matchupmd ? config.marginContainerTop : config.marginContainerTopMobile;

  const setOpenSidebar = useUpdateAtom(openSidebarAtom);
  const openSidebar = useAtomValue(openSidebarAtom);
  return (
    <Container component='nav' matchupmd={matchupmd}>
      <Drawer
        variant={matchupmd ? 'persistent' : 'temporary'}
        open={openSidebar}
        onClose={() => {
          setOpenSidebar(false);
        }}
        ModalProps={{ keepMounted: true }}
      >
        <LogoBox>
          <LogoContainer>
            <LogoHeader />
          </LogoContainer>
        </LogoBox>
        <BrowserView>
          <ScrollContainer component='div' height={heightCalc}>
            <MenuList />
          </ScrollContainer>
        </BrowserView>
        <MobileView>
          <MobileContainer>
            <MenuList />
          </MobileContainer>
        </MobileView>
      </Drawer>
    </Container>
  );
};

export default SideBar;
