import React, { useState, MouseEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Container, PaperProps } from './styles';

export interface ContextMenuOption {
  label: string;
  action: () => void;
}

interface Props {
  options: ContextMenuOption[];
}

const ContextMenu: React.FC<Props> = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: ContextMenuOption) => {
    option.action();
    handleClose();
  };

  return (
    <Container>
      <IconButton
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={PaperProps}
      >
        {options.map((option: ContextMenuOption) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              handleSelect(option);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

export default ContextMenu;
