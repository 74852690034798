import styled from '@emotion/styled';
import { Box } from '@mui/material';
import config from '@/app/config';
import { theme } from '@/app/theme/theme-config';

interface PropContainerIcon {
  bgIcon?: string;
  positionIcon?: string;
  containsIcon?: boolean;
  fullWidth?: boolean;
}

export const Container = styled(Box)<PropContainerIcon>(({ theme, containsIcon, fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
  input: {
    borderTopLeftRadius: containsIcon ? 0 : config.inputBorderRadius,
    borderBottomLeftRadius: containsIcon ? 0 : config.inputBorderRadius,
    backgroundColor: theme.palette.background.paper,
    borderTopRightRadius: config.inputBorderRadius,
    borderBottomRightRadius: config.inputBorderRadius,
    textIndent: 10,
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
  p: {
    color: theme.palette.primary.contrastText,
    '@media (max-width: 420px)': {
      display: 'none',
    },
  },
}));

export const ContainerIcon = styled('div')<PropContainerIcon>(({ bgIcon, positionIcon }) => ({
  height: '53px',
  width: '50px',
  backgroundColor: bgIcon ? bgIcon : theme.palette.grey[300],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: positionIcon === 'start' ? '5px 0 0 5px' : '0 5px 5px 0',
  marginRight: -8,
}));
