// See docs on: https://mui.com/pt/material-ui/customization/palette/
import { ThemeOptions } from '@mui/material/styles';
const palette = <ThemeOptions['palette']>{
  primary: {
    main: '#5494F5',
    dark: '#428BCA',
  },
  background: {
    paper: '#FFF',
    default: '#FAFAFA',
  },
  info: {
    light: '#C5DCFA',
    main: '#5494F5',
    dark: '#0A3977',
    900: '#1756A9',
  },
  success: {
    main: '#63db99',
    dark: '#28C66F',
    400: '#305911',
    500: '#28C66F33',
  },
  warning: {
    light: '#FBE5C9',
    main: '#FFC245',
    dark: '#FFAB00',
    900: '#C3791C',
    A700: '#fbe5c97f',
  },
  alert: {
    light: '#E7A0A1',
    main: '#EF5350',
    dark: '#C62828',
  },
  error: {
    light: '#E7A0A1',
    main: '#d32f2f',
  },
  grey: {
    100: '#E6EEFA',
    200: '#EFEFEF',
    300: '#E1E8F1E6',
    400: '#808080',
    600: '#656565',
    900: '#1E1E1E',
    800: '#414141',
    A200: '#c8d4e4be',
    A400: '#dae4f37b',
  },
  secondary: {
    main: '#6259CE',
    A200: 'rgba(98, 89, 206, 0.2)',
  },
};

export { palette };
