import styled from '@emotion/styled';
import { Accordion, Box, AccordionSummary } from '@mui/material';

export const Container = styled(Box)(() => ({
  paddingBottom: 16,
}));

export const Collapse = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  marginBottom: '10px !important',
}));

export const Summary = styled(AccordionSummary)(() => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    margin: '0 0 !important',
  },
}));

export const TextItem = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // fontSize: '0.7vw',
  marginLeft: '0.5rem',
  '>p': {
    color: '#5494F5',
  },
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 850px)': {
    fontSize: '0.65rem',
  },
}));
