import { client } from '../client/client';

type TypeMyBusiness = 'oportunidade' | 'disputa' | 'classificacao' | 'contrato';

export interface ParamsMyBusiness {
  tipos: TypeMyBusiness;
}

export interface ItemBusiness {
  numeroItem: string;
}

export interface InputMyBusiness {
  processoId: number;
  nome: string;
  empresaId: string;
  tipoNegocio: TypeMyBusiness;
  itens: ItemBusiness[];
}

export interface OpportunityResponse {
  id: number;
  portal: string;
  modalidade: number;
  uasg: any;
  numero: string;
  codigo: string;
  titulo: string;
  descricao: string;
  uf: string;
  orgao: string;
  abertura: string;
  encerramento: undefined;
  publicacao: string;
  jornaisPertencentes: any[];
  superoportunidade: boolean;
  aberturaFormatada: string;
  encerramentoFormatado: string;
  dataPublicacaoFormatada: string;
  nomePortal: string;
  nomeModalidade: string;
}

export const getMyBusiness = async (params: ParamsMyBusiness): Promise<OpportunityResponse[]> => {
  return client
    .get('negocio', {
      params,
    })
    .then((response: any) => {
      return response.content || [];
    });
};

export const addMyBusiness = async (data: InputMyBusiness) => {
  try {
    const response = await client.post('negocio', data);
    return response;
  } catch (error: any) {
    if (error.status !== 200) {
      return 'error';
    }
  }
};

export const removeMyBusiness = async (id: number) => {
  return client.delete(`negocio/remover/${id}`);
};
