import styled from '@emotion/styled';
import { Box, Button, Grid } from '@mui/material';
import bigCircles from '@assets/BG.png';
import config from '@app/config';

export const Container = styled('div')(() => ({}));

export const TitlePage = styled(Grid)(() => ({
  '@media(max-width: 900px)': {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export const TitleHeader = styled(Grid)(() => ({
  paddingLeft: '1rem',
}));

export const ContainerFilters = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${bigCircles})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  padding: '1rem',
  margin: '20px 20px 0 20px',
  '@media (max-width: 420px)': {
    margin: 0,
    padding: 0,
    backgroundColor: theme.palette.success[400],
  },
}));

export const ContainerSearch = styled(Grid)(() => ({
  padding: '12px',
  paddingBottom: '0px',
  paddingTop: '32px',
  '@media (max-width: 420px)': {
    padding: '0.8rem 0.4rem 0 0.4rem',
  },
}));

export const ContainerFiltersFooter = styled(Grid)(() => ({
  margin: '0 20px',
  display: 'flex',
}));

export const ContentAction = styled('div')(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.background.default,
  borderBottomRightRadius: 16,
  borderBottomLeftRadius: 16,
  display: 'flex',
  width: 220,
  boxShadow: theme.shadows[19],
  zIndex: 1,
  height: 50,
  cursor: 'pointer',

  '@media (max-width: 420px)': {
    padding: '0.5rem',
    height: 30,
    justifyContent: 'center',
  },
}));

export const ContentSpacing = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  width: '100%',
  borderBottomRightRadius: 16,
  boxShadow: theme.shadows[19],
  height: 25,
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const ContainerRowFilters = styled(Grid)(() => ({
  marginTop: config.marginTopLineGrid,
  '@media (max-width: 420px)': {
    justifyContent: 'space-between',
  },
}));

export const ContainerPesquisaAvancada = styled(Grid)(({ theme }) => ({
  marginTop: config.marginTopLineGrid,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 10,
  padding: 31,
  '& .MuiGrid-item': {
    paddingTop: 10,
  },
}));

export const ContainerBody = styled(Grid)(() => ({
  marginTop: config.marginTopLineGrid,
  padding: 26,

  '@media (max-width: 420px)': {
    padding: '0.8rem 0.4rem 0 0.4rem',
  },
}));

export const ContainerVejaAqui = styled.div(() => ({
  marginTop: 10,
  '> img': {
    height: '100%',
    width: '100%',
  },
}));

export const TextItem = styled.span(({ theme }) => ({
  fontSize: '0.8vw',
  color: theme.palette.grey[800],
}));

export const GridComboCustom = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: config.cardBorderRadius,
  padding: 10,
  height: '100%',
}));

export const ContainerTip = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
}));

interface ArowIconProps {
  opened: boolean;
}

export const ArrowIcon = styled('img')<ArowIconProps>(({ opened }) => ({
  height: 20,
  width: 16,
  margin: '0 1rem',
  transform: opened ? '' : 'rotate(180deg)',
  transition: '0.6s ease',
}));

export const ContentFilters = styled(Box)(() => ({
  margin: '1rem',

  '@media (max-width: 420px)': {
    margin: '0.4rem',
    width: '100%',

    '> div': {
      width: 'inherit',
    },
  },
}));

export const MessageEmpty = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.dark,
  minHeight: '58px',
  height: 'max-content',
  padding: '1rem 1rem',
  borderRadius: '8px',
  '> strong': {
    margin: '0.1rem 0 1.5rem 0',
    span: {
      fontWeight: 'bold',
    },
  },
}));

export const AdvancedSearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.warning.dark,
  color: theme.palette.grey[900],

  ':hover': {
    backgroundColor: theme.palette.warning.main,
  },
}));

export const ButtonDispensasEletronicas = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '1rem',
  color: theme.palette.grey[500],
  border: '1px solid',
  borderColor: '#c5c6c7', // TODO: reornanizar as escalas de cinza em palette
  borderRadius: '5px',
  cursor: 'pointer',
}));

export const ContainerSelectDispensaEletronica = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  minHeight: '4.688rem',
}));
