import styled from '@emotion/styled';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '1rem',
}));

export const TitleContainer = styled('div')(() => ({
  display: 'flex',
  fontWeight: 'bold',
}));

export const Infos = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  '>span': {
    fontWeight: 'bold',
    marginRight: '0.2rem',
  },
}));
