import React, { useEffect, useState, ReactElement } from 'react';
import { Search } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import AccordionCollapse, { AccordionCollapseItemProps } from '@/app/components/molecules/Accordion';
import InputField from '@/app/components/molecules/InputField';

import {
  Container,
  ContainerItem,
  ContainerTitleItem,
  HighlightThrowUpItem,
  Title,
  TitleItem,
  ContainerTitle,
  SearchBarContainer,
} from './styles';

interface CheckBoxListForm {
  search: string;
}

export interface ItemCheckBoxList {
  id: any;
  title: string;
  checked: boolean;
  highlightThrowUp: boolean;
  body: string | ReactElement;
}

export interface CheckBoxListProps {
  itens: ItemCheckBoxList[];
  onCheckedItem: (id: any, value: boolean) => void;
}

const CheckBoxList: React.FC<CheckBoxListProps> = ({ itens, onCheckedItem }) => {
  const initialForm: CheckBoxListForm = {
    search: '',
  };

  const [form, setform] = useState<CheckBoxListForm>(initialForm);
  const [formErrors, setFormErrors] = useState<CheckBoxListForm>(initialForm);
  const [listAccordion, setListAccordion] = useState<AccordionCollapseItemProps[]>([]);
  const regexStripHtmlTags = /(<([^>]+)>)/gi;

  useEffect(() => {
    setListAccordion(
      itens
        .filter((i) => i.title.toLowerCase().includes(form.search.toLowerCase()))
        .map((item) => ({
          id: item.id,
          header: (
            <ContainerItem>
              <Checkbox
                checked={item.checked}
                onChange={(e) => onCheckedItem(item.id, e.target.checked)}
                onClick={(e) => e.stopPropagation()}
                inputProps={{ 'aria-label': 'controlled' }}
                color='secondary'
              />
              <ContainerTitleItem>
                <TitleItem>{`${item.title.replace(regexStripHtmlTags, '').substring(0, 80)}...`}</TitleItem>
              </ContainerTitleItem>
              {item.highlightThrowUp && <HighlightThrowUpItem>Lance acima da referência</HighlightThrowUpItem>}
            </ContainerItem>
          ),
          body: item.body,
        })),
    );
  }, [itens, form]);

  const setFormDataValue = (id: string, value: any) => {
    setform({
      ...form,
      [id]: value,
    });
  };

  return (
    <Container>
      {listAccordion.length > 0 && (
        <Grid container item xs={12}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ContainerTitle>
              <Checkbox onChange={(e) => listAccordion.map((i) => onCheckedItem(i.id, e.target.checked))} />
              <Title>Selecionar todos</Title>
            </ContainerTitle>
          </Grid>
          <SearchBarContainer item lg={9} md={9} sm={12} xs={12}>
            <InputField
              id='search'
              form={form}
              formErrors={formErrors}
              handleChange={(id: string, value: any) => setFormDataValue(id, value)}
              placeholder='Exemplo: Capacete, ar-condicionado...'
              positionIcon='start'
              Icon={Search}
              bgIcon='#FFFFFF'
            />
          </SearchBarContainer>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <AccordionCollapse collapseOne={true} itemCollapsed='item2' itens={listAccordion} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default CheckBoxList;
