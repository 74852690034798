import { createTheme } from '@mui/material';
import { palette } from '@theme/palette';
import { shape } from '@theme/shape';
import { header } from '@theme/header';
import { iconButton } from '@theme/buttons';
import { shadows } from '@theme/shadows';
import { typography } from '@theme/typography';
import { customPalette } from '@theme/customPalette';
import { components } from '@theme/components';

// See docs on: https://mui.com/pt/material-ui/customization/theming/
const theme = createTheme({
  palette: palette,
  customPalette: customPalette,
  shape: shape,
  header: header,
  iconButton: iconButton,
  shadows: shadows,
  typography: typography,
  components: components,
});

export { theme };
