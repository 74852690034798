import { getOrgaos, InputFilterOrgao } from '@/app/services/orgaoService';
import React, { useEffect, useState } from 'react';
import SelectField, { SelectFieldProps, Option } from '../../molecules/SelectField';

export interface SelectFieldOrgaoProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo> {
  portal: string;
  infoText?: string;
}

const SelectFieldOrgao: React.FC<SelectFieldOrgaoProps> = ({ portal, infoText, ...rest }) => {
  const [numPortals, setNumPortals] = useState<number>(0);
  const [paramsOrgao, setParamsOrgao] = useState<InputFilterOrgao>({
    page: '0',
    portal,
    term: '',
  });

  const countPortais = (listPortals: string) => {
    const qtde = listPortals ? listPortals.split(',') : [];
    return qtde.length;
  };

  useEffect(() => {
    setParamsOrgao({ ...paramsOrgao, portal });
    setNumPortals(countPortais(portal));
  }, [portal]);

  const loadOrgao = async (params: InputFilterOrgao) => {
    try {
      const orgaos = await getOrgaos(params);
      return orgaos.results || [];
    } catch (err) {
      return [];
    }
  };

  return (
    <SelectField
      {...rest}
      placeholder='Filtrar por Órgão'
      fetch={loadOrgao}
      fieldParamsSearch='term'
      paramsDefault={paramsOrgao}
      fieldLabel='text'
      nameFieldValue='id'
      infoText={numPortals !== 1 ? infoText : ''}
    />
  );
};

export default SelectFieldOrgao;
