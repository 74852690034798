import { DetalheItemProcesso, Processo } from '@/app/services/types';
import React from 'react';
import { Container, Links, LinksContainer } from './styles';

interface ProcessItemDetailsProps {
  process: Processo;
  detailItemProcess: DetalheItemProcesso;
}

// Componente usado quando os itens de um Negócio são de fato itens e não um grupo de itens
const ProcessItemDetails: React.FC<ProcessItemDetailsProps> = ({ process, detailItemProcess }) => {
  return (
    <Container>
      <p dangerouslySetInnerHTML={{ __html: detailItemProcess.descricao }}></p>
      <LinksContainer style={{ display: 'none' }}>
        {/* Itens escondidos até implementar tela de histórico e fabricantes */}
        <Links href='#'>Histórico</Links>
        <Links href='#'>Concorrentes</Links>
        <Links href='#'>Fabricantes</Links>
      </LinksContainer>
    </Container>
  );
};

export default ProcessItemDetails;
