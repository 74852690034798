import React from 'react';

import { Caption, Container } from './styles';

interface Props {
  title: string;
  caption?: string;
  color?: string;
}
const SearchHeader: React.FC<Props> = ({ title, caption, color }) => {
  return (
    <Container>
      {color ? <h1 style={{ color: color }}>{title}</h1> : <h1>{title}</h1>}
      {caption && <Caption>{caption}</Caption>}
    </Container>
  );
};

export default SearchHeader;
