import styled from '@emotion/styled';
import { Box } from '@mui/material';
import config from '@app/config';
import ScrollBar, { ScrollBarProps } from 'react-perfect-scrollbar';

interface ContainerProps {
  matchupmd: boolean;
}
interface ScrollProps extends ScrollBarProps {
  height: number;
}

export const Container = styled(Box)<ContainerProps>(({ matchupmd, theme }) => ({
  width: matchupmd ? config.drawerWidth : 'auto',
  flexShrink: theme.breakpoints.up('md') ? 0 : '',
  '& .MuiDrawer-paper': {
    width: config.drawerWidth,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: 'none',
    top: theme.breakpoints.up('md') ? config.marginContainerTop + 18 : '',
  },
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  display: theme.breakpoints.up('md') ? 'none' : 'block',
}));

export const LogoContainer = styled(Box)(() => ({
  display: 'flex',
  padding: 16,
  margin: '0 auto',
}));

export const ScrollContainer = styled(ScrollBar)<ScrollProps>(({ height }) => ({
  paddingRight: '1rem',
  paddingLeft: '1rem',
  height: `calc(100vh - ${height}px)`,
}));

export const MobileContainer = styled(Box)(() => ({
  paddingLeft: '2rem',
  paddingRight: '2rem',
}));
