import React from 'react';
import { Calendar, Container, Label } from './styles';
import { DateRangePickerProps, CustomProvider } from 'rsuite';
import { FormControl, FormHelperText } from '@mui/material';
import { Locale } from 'rsuite/locales';
import ptBr from 'rsuite/locales/pt_BR';
import useWindowDimensions from '@/app/hooks/useWindowDimensions';

type CalendarFieldProps = DateRangePickerProps & {
  id: string;
  form?: any;
  label?: string;
  formErrors?: any;
  fullWidth?: boolean;
  handleChange?: (id: string, value: any) => void;
  helperText?: string;
  locale?: Locale;
  format?: string;
};

const CalendarField: React.FC<CalendarFieldProps> = ({
  fullWidth,
  id,
  form,
  formErrors,
  helperText,
  label,
  handleChange,
  locale = ptBr,
  format = 'dd-MM-yyyy',
  value,
  ...rest
}) => {
  const { width } = useWindowDimensions();

  const getHelperText = () => {
    if (formErrors[id] !== undefined && formErrors[id] !== '') {
      return formErrors[id];
    }
    if (helperText) {
      return helperText;
    }
    return '';
  };
  const onChange = (e: any) => {
    if (handleChange) {
      handleChange(id, e);
    }
  };

  return (
    <Container fullWidth={fullWidth ?? true} error={!!formErrors[id]}>
      <CustomProvider locale={locale}>
        <FormControl fullWidth={fullWidth ?? true}>
          {label && <Label error={!!formErrors[id]}>{label}</Label>}
          <Calendar
            name={id}
            {...rest}
            // value={form[id]}
            value={value}
            onChange={onChange}
            error={formErrors[id] ? formErrors[id] : undefined}
            format={format}
            size='lg'
            character=' até '
            style={{ marginTop: '-6px', minWidth: width > 630 ? '320px' : '' }}
            placeholder='Selecione um período'
          />
          {getHelperText() && <FormHelperText>{getHelperText()}</FormHelperText>}
        </FormControl>
      </CustomProvider>
    </Container>
  );
};

export default CalendarField;
