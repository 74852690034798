import { useAtom } from 'jotai';
import { alertAtom, setAlertAtom } from '../jotai/AlertAtom';
import { v4 as uuidv4 } from 'uuid';

export interface InputItemAlert {
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
}

export const useAlertService = () => {
  const [, setAlerts] = useAtom(setAlertAtom);
  const [alerts] = useAtom(alertAtom);

  const addAlert = (alert: InputItemAlert, time = 5000) => {
    const id = uuidv4().toString();

    setAlerts([
      ...alerts,
      {
        ...alert,
        id: id,
      },
    ]);

    setTimeout(() => removeAlert(id), time);
  };

  const removeAlert = (id: string) => {
    setAlerts(alerts.filter((i) => (i as any).id !== id));
  };

  return { addAlert };
};
