import makeStyles from '@material-ui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';

const useStyles = makeStyles({
  popOverRoot: {
    pointerEvents: 'none',
  },
});

interface TopMenuItemProps {
  submenu?: boolean;
  item: any;
}

const TopMenuItem = ({ submenu, item }: TopMenuItemProps) => {
  let currentlyHovering = false;
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event: any) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleHover() {
    currentlyHovering = true;
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCloseHover() {
    currentlyHovering = false;
    setTimeout(() => {
      if (!currentlyHovering) {
        handleClose();
      }
    }, 50);
  }

  return (
    <Container>
      {/* TODO: Implementar submenu */}

      {!submenu ? (
        <Button aria-haspopup='true' onClick={handleClick} style={{ color: '#000' }}>
          <Link to={item.url} style={{ color: '#000' }}>
            {item.title}
          </Link>
        </Button>
      ) : (
        <>
          <Button
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
            onMouseOver={handleClick}
            onMouseLeave={handleCloseHover}
            style={{
              color: '#000',
            }}
            endIcon={<ExpandMoreIcon />}
          >
            Oportunidades
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              onMouseEnter: handleHover,
              onMouseLeave: handleCloseHover,
              style: { pointerEvents: 'auto' },
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PopoverClasses={{
              root: styles.popOverRoot,
            }}
          >
            <MenuItem onClick={handleClose}>Encontrar</MenuItem>
            <MenuItem onClick={handleClose}>Meus Jornais</MenuItem>
          </Menu>
        </>
      )}
    </Container>
  );
};

export default TopMenuItem;
