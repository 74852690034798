import styled from '@emotion/styled';
import { ListItemButton, ListItemButtonProps } from '@mui/material';

export interface Props extends ListItemButtonProps {
  level: number;
}

export const Container = styled(ListItemButton)<Props>(({ level, theme }) => ({
  marginBottom: 5,
  borderRadius: theme.shape.borderRadius,
  paddingLeft: level * 24,
  flexDirection: 'row',
  backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
  },
}));
