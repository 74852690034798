import styled from '@emotion/styled';
import { Button, Grid } from '@mui/material';

export const ListPosts = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ContainerPost = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1rem',
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[600],
  padding: '0.5rem 0',
}));

export const HeaderPost = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '>div': {
    fontWeight: 'bold',
  },
}));

export const BodyPost = styled('p')(() => ({
  padding: '0.5px',
}));

export const MorePostsButton = styled(Button)(() => ({
  marginTop: '0.5rem',
}));
