interface NotificationProp {
  id: number;
  description: string;
}
export const getNotifications = () => {
  return new Promise<NotificationProp[]>((resolve) => {
    setTimeout(() => {
      resolve([
        { id: 1, description: 'Teste de notificação 1' },
        { id: 2, description: 'Teste de notificação 2' },
        { id: 3, description: 'Teste de notificação 3' },
        { id: 4, description: 'Teste de notificação 4' },
        { id: 5, description: 'Teste de notificação 5' },
        { id: 6, description: 'Teste de notificação 6' },
      ]);
    }, 2000);
  });
};
