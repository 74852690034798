import { atom } from 'jotai';

export interface ItemAlert {
  id?: string;
  type: 'error' | 'warning' | 'info' | 'success';
  message: string;
  onClose?: () => void;
}

const alertAtom = atom<ItemAlert[]>([]);
const setAlertAtom = atom(null, (get, set, value: ItemAlert[]) => {
  set(alertAtom, value);
});

export { alertAtom, setAlertAtom };
