import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputField from '../../molecules/InputField';
import Modal, { ModalProps } from '../../molecules/Modal';
import SelectField from '../../molecules/SelectField';
import SelectFieldElectronicExemptionsPhase from '../SelectFieldElectronicExemptionsPhase';
import SelectFieldElectronicExemptionsSituation from '../SelectFieldElectronicExemptionsSituation';
import SelectFieldElectronicExemptionsStatusItems from '../SelectFieldElectronicExemptionsStatusItems';
import {
  Container,
  Column,
  Label,
  Line,
  ContainerDataEncerramento,
  ContainerTipoFiltroEncerramento,
  ContainerHoraEncerramento,
} from './style';

export interface ModalElectronicExemptionsProps extends Omit<ModalProps, 'onChange' | 'children'> {
  form: any;
  formError: any;
  onChange: (value: any, event: React.ChangeEvent<any>) => void;
}

const ModalElectronicExemptions: React.FC<ModalElectronicExemptionsProps> = ({ open, onChange, onClose, form }) => {
  const [show, setShow] = useState(open);
  const [data, setData] = useState({
    dataEncerramento: form['dataEncerramento'],
    tipoFiltroEncerramento: form['tipoFiltroEncerramento'],
    horaEncerramento: form['horaEncerramento'],
    faseCotacao: form['faseCotacao'],
    statusItem: form['statusItem'],
    situacao: form['situacao'],
  });
  const [dataError, setDataError] = useState({ dataEncerramento: undefined });

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleSave = (e: any) => {
    setShow(false);
    onChange && onChange(data, e);
    onClose && onClose(e, 'backdropClick');
  };

  const footer = () => {
    return (
      <div>
        <Button variant='contained' onClick={handleSave}>
          Buscar
        </Button>
      </div>
    );
  };

  return (
    <Modal open={show} onClose={onClose} header='Dispensas eletrônicas' maxWidth='sm' footer={footer()}>
      <Container>
        <Column>
          <Label>Encerramento</Label>
          <Line>
            <ContainerDataEncerramento>
              <InputField
                style={{ minWidth: 160 }}
                id='dataEncerramento'
                type={'date'}
                form={data}
                formErrors={dataError}
                handleChange={(id, value) => setData({ ...data, [id]: value })}
              />
            </ContainerDataEncerramento>
            <ContainerTipoFiltroEncerramento>
              <SelectField
                id='tipoFiltroEncerramento'
                form={data}
                formErrors={dataError}
                options={[
                  { label: 'até', value: 'ate' },
                  { label: 'às', value: 'as' },
                ]}
                handleChange={(id, values) => setData({ ...data, [id]: values.value })}
              />
            </ContainerTipoFiltroEncerramento>
            <ContainerHoraEncerramento>
              <InputField
                id='horaEncerramento'
                type={'time'}
                form={data}
                formErrors={dataError}
                handleChange={(id, value) => setData({ ...data, [id]: value })}
              />
            </ContainerHoraEncerramento>
          </Line>
        </Column>
        <Column>
          <Label>Fase</Label>
          <Line minWidth={200}>
            <SelectFieldElectronicExemptionsPhase
              id='faseCotacao'
              form={data}
              formErrors={dataError}
              fullWidth={false}
              handleChange={(id, values) => setData({ ...data, [id]: values.value })}
            />
          </Line>
        </Column>
        <Column>
          <Label>Item</Label>
          <Line minWidth={320}>
            <SelectFieldElectronicExemptionsStatusItems
              id='statusItem'
              form={data}
              formErrors={dataError}
              handleChange={(id, values) => setData({ ...data, [id]: values.value })}
            />
          </Line>
        </Column>
        <Column>
          <Label>Situação</Label>
          <Line minWidth={210}>
            <SelectFieldElectronicExemptionsSituation
              id='situacao'
              form={data}
              formErrors={dataError}
              handleChange={(id, values) => setData({ ...data, [id]: values.value })}
            />
          </Line>
        </Column>
      </Container>
    </Modal>
  );
};

export default ModalElectronicExemptions;
