import { ThemeProvider } from '@emotion/react';
import { pageTitleAtom } from '@jotai/ThemeAtom';
import { CssBaseline, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material';
import Routes from '@routes/index';
import { theme } from '@theme/theme-config';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './contexts/auth';

function App() {
  const [title] = useAtom(pageTitleAtom);

  const queryClient = new QueryClient();

  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
