import styled from '@emotion/styled';

import bgNewspapers from '@assets/bg_newspapers.png';
import SelectFieldNewspapers from '@/app/components/organisms/SelectFieldNewspapers';
import { Box, Grid } from '@mui/material';
import config from '@app/config';

export const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ContainerFilters = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${bgNewspapers})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '5px',
  height: 'auto',
  padding: '1rem',
  backgroundColor: theme.palette.grey['A200'],
  '@media (max-width: 420px)': {
    margin: 0,
    padding: 0,
  },
}));

export const ContainerRowFilters = styled(Grid)(() => ({
  marginTop: config.marginTopLineGrid,
  '@media (max-width: 420px)': {
    justifyContent: 'space-between',
  },
}));

export const TitleHeader = styled(Grid)(() => ({
  paddingLeft: '1rem',
  '@media(max-width: 900px)': {
    padding: '0.5rem',
    textAlign: 'center',
  },
}));

export const ContainerSearch = styled(Grid)(() => ({
  padding: '12px',
  paddingBottom: '0px',
  paddingTop: '1rem',
  '@media (max-width: 420px)': {
    padding: '0.8rem 0.4rem 0 0.4rem',
  },
}));

export const ContainerAddSearch = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));

export const HelpInput = styled('span')(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 11,
  color: theme.palette.grey['900'],
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const LabelAddSearch = styled('span')(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.info.main,
}));

export const ButtonAddSearch = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.palette.info.main,
}));

export const ContainerRowSwitches = styled(Grid)(() => ({
  marginTop: config.marginTopLineGrid,
  '@media (max-width: 420px)': {
    justifyContent: 'space-between',
  },
}));

export const ContentFilters = styled(Box)(() => ({
  margin: '1rem',
  '@media (max-width: 420px)': {
    margin: '0.4rem',
  },
}));

export const ContainerPesquisaAvancada = styled(Grid)(({ theme }) => ({
  marginTop: config.marginTopLineGrid,
  backgroundColor: theme.palette.grey[300],
  borderRadius: 10,
  padding: 31,
  '& .MuiGrid-item': {
    paddingTop: 10,
  },
}));

export const GridComboCustom = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: config.cardBorderRadius,
  padding: 10,
  height: '100%',
}));

export const ButtonDispensasEletronicas = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '1rem',
  color: theme.palette.grey[500],
  border: '1px solid',
  borderColor: '#c5c6c7', // TODO: reornanizar as escalas de cinza em palette
  borderRadius: '5px',
  cursor: 'pointer',
}));

export const ContainerButtonSaveFilters = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}));

export const ContainerFiltersFooter = styled(Grid)(() => ({
  margin: '0 20px',
  display: 'flex',
}));

export const ContentAction = styled('div')(({ theme }) => ({
  padding: '1rem',
  backgroundColor: theme.palette.background.default,
  borderBottomRightRadius: 16,
  borderBottomLeftRadius: 16,
  display: 'flex',
  width: 220,
  boxShadow: theme.shadows[19],
  zIndex: 1,
  height: 50,
  cursor: 'pointer',
  '@media (max-width: 420px)': {
    padding: '0.5rem',
    height: 30,
    justifyContent: 'center',
  },
}));

export const ContentSpacing = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  width: '100%',
  borderBottomRightRadius: 16,
  boxShadow: theme.shadows[19],
  height: 25,
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

interface ArowIconProps {
  opened: boolean;
}

export const ArrowIcon = styled('img')<ArowIconProps>(({ opened }) => ({
  height: 20,
  width: 16,
  margin: '0 1rem',
  transform: opened ? '' : 'rotate(180deg)',
  transition: '0.6s ease',
}));
// ##########################################################################

export const SelectNewspapers = styled(SelectFieldNewspapers)(() => ({
  marginTop: 5,
}));

export const Description = styled('span')(() => ({}));

export interface ColLeftRightProps {
  marginBottom?: number;
}

export const ContainerItems = styled('div')(() => ({
  marginTop: 30,
  maxHeight: '100vh',
  overflow: 'auto',
}));
