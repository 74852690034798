import styled from '@emotion/styled';
import { Card } from '@mui/material';

interface CardProps {
  styles?: {
    border?: boolean;
    borderColor?: string;
    shadow?: boolean;
    boxShadow?: string;
  };
}

export const Container = styled(Card)<CardProps>(({ styles, theme }) => ({
  border: styles?.border ? '1px solid' : 'none',
  borderColor: styles?.borderColor ?? theme.palette.grey.A200,
  '&:hover': {
    boxShadow: styles?.shadow ? styles?.boxShadow || theme.shadows[18] : 'inherit',
  },
}));
