import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';

export const Container = styled(Box)(() => ({
  paddingBottom: 16,
}));

export const ContainerItem = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const ContainerTitleItem = styled.div(() => ({
  marginLeft: 1,
}));

export const TitleItem = styled.p(() => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '0.5rem',
  fontSize: '0.75rem',
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
  '@media (max-width: 850px)': {
    fontSize: '0.65rem',
  },
}));

export const HighlightThrowUpItem = styled.span(() => ({
  color: 'blue',
}));

export const ContainerTitle = styled.div(() => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  margin: '6px 19px',
}));

export const Title = styled.span(() => ({
  marginLeft: 5,
}));

export const SearchBarContainer = styled(Grid)(() => ({
  '@media (max-width: 420px)': {
    paddingBottom: '0.5rem',
  },
}));
