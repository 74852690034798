import styled from '@emotion/styled';
import config from '@/app/config';
import { Grid, Typography } from '@mui/material';

interface PropsType {
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
  first?: string;
}

export const Container = styled(Grid)<PropsType>(({ theme, first }) => ({
  marginTop: first ? 0 : 18,
  backgroundColor: theme.palette.background.paper,
  paddingTop: 22,
  borderBottomLeftRadius: config.cardBorderRadius,
  borderBottomRightRadius: config.cardBorderRadius,
  cursor: 'pointer',
}));

export const Header = styled(Grid)(() => ({
  paddingRight: 16,
  paddingLeft: 16,
}));

export const ChipsContainer = styled(Grid)(() => ({
  width: config.widthFull,
  paddingRight: 16,
  paddingLeft: 16,
}));

export const DateContainer = styled(Grid)(({ theme }) => ({
  width: config.widthFull,
  borderTop: '1px solid',
  borderColor: theme.palette.grey[500],
  padding: '8px 0 8px 24px',
}));

export const GridProgress = styled(Grid)(({ theme }) => ({
  width: config.widthFull,
  padding: '8px 8px 8px 24px',
  borderBottomLeftRadius: config.cardBorderRadius,
  borderBottomRightRadius: config.cardBorderRadius,
  marginBottom: '-12px',
  zIndex: 9,
  backgroundColor: theme.palette.background.paper,
}));

export const TypographyhOpeningDate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

export const Footer = styled(Grid)<PropsType>(({ theme, themecolor }) => ({
  width: config.widthFull,
  height: 20,
  borderBottomLeftRadius: config.cardBorderRadius,
  borderBottomRightRadius: config.cardBorderRadius,
  backgroundColor: themecolor ? (theme.palette as any)[themecolor].dark : theme.palette.grey[900],
}));
