import React, { createContext, useState, useContext, useEffect } from 'react';
import { client } from '../client/client';

interface AuthContextData {
  user: User | undefined;
  signIn(data: UserDto): Promise<void>;
  signOut(): void;
}

export interface User {
  nome: string;
  email: string;
}

export interface UserDto extends User {
  token?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    addToken();
  }, []);

  const addToken = () => {
    const userStr = sessionStorage.getItem('data');
    if (userStr && userStr != 'undefined' && userStr.length != 0) {
      const user = JSON.parse(userStr);
      client.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
      delete user.token;
      setUser(user);
    }
  };

  async function signIn(data: UserDto) {
    sessionStorage.setItem('data', JSON.stringify(data));
    client.defaults.headers.common['Authorization'] = data.token;
    delete data.token;
    setUser(data);
    window.location.href = '/';
  }

  function signOut() {
    sessionStorage.removeItem('data');
    client.defaults.headers.common['Authorization'] = '';
    setUser(undefined);
    window.location.href = '/login';
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
