import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

export const Container = styled('div')(() => ({}));

export const GridListCard = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const GridActions = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const TypographyhOpeningDate = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
}));

export const PaperProps = {
  style: {
    maxHeight: 300,
    width: '20ch',
  },
};
