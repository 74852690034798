import React from 'react';
import { Badge } from '@mui/material';

import { Container, BadgeContainer, LabelContainer } from './styles';

interface Props {
  Icon: any;
  link?: string;
  label: string;
  count?: number;
}

const BadgeCount: React.FC<Props> = ({ Icon, link, label, count = 0 }) => {
  return (
    <Container
      onClick={() => {
        if (link) window.open(link, '_blank');
      }}
    >
      <BadgeContainer>
        <Badge badgeContent={count.toString()} color='primary'>
          <Icon color='action' />
        </Badge>
      </BadgeContainer>
      <LabelContainer>{label}</LabelContainer>
    </Container>
  );
};

export default BadgeCount;
