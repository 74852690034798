import React from 'react';
import { useMediaQuery, useTheme, Popper as MuiPopper, ClickAwayListener, Paper } from '@mui/material';
import Transitions from '@molecules/Transitions';

interface Props {
  anchorRef: React.RefObject<HTMLAnchorElement>;
  open: boolean;
  bottom?: number;
  children: React.ReactElement;
  onClickAway?: () => void;
}
const Popper: React.FC<Props> = ({ anchorRef, open, bottom = 5, children, onClickAway = () => {} }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MuiPopper
      placement={matchesXs ? 'bottom' : 'bottom-end'}
      open={open}
      anchorEl={anchorRef?.current}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? 5 : 0, bottom],
            },
          },
        ],
      }}
    >
      {({ TransitionProps }) => (
        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
          <ClickAwayListener onClickAway={() => onClickAway()}>
            <Paper>{children}</Paper>
          </ClickAwayListener>
        </Transitions>
      )}
    </MuiPopper>
  );
};

export default Popper;
