import useWindowDimensions from '@/app/hooks/useWindowDimensions';
import { AccessAlarm, SvgIconComponent } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import React from 'react';

import { OpeningTypography, ClosureTypography, Container } from './styles';

interface Props {
  startAt: string;
  endAt: string;
  Icon?: SvgIconComponent;
  showIcon?: boolean;
  alingend?: boolean;
}

const DeadlineShow: React.FC<Props> = ({ startAt, endAt, Icon = AccessAlarm, showIcon = true, alingend = false }) => {
  const { width } = useWindowDimensions();
  const mobileDimensions = width <= 630;

  if (mobileDimensions) showIcon = false;

  return (
    <Grid container>
      {showIcon && (
        <Box p={1}>
          <Icon fontSize='large' />
        </Box>
      )}
      <Container alingend={alingend ? 'right' : ''}>
        <OpeningTypography> Abertura em {startAt}</OpeningTypography>
        <ClosureTypography>{endAt && 'Encerramento em ' + endAt}</ClosureTypography>
      </Container>
    </Grid>
  );
};

export default DeadlineShow;
