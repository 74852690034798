import React from 'react';
import { alertAtom, setAlertAtom } from '@/app/jotai/AlertAtom';
import { Alert } from '@mui/material';
import { useAtom } from 'jotai';

import { Container } from './styles';

const Alerts: React.FC = () => {
  const [, setAlerts] = useAtom(setAlertAtom);
  const [alerts] = useAtom(alertAtom);

  return alerts.length > 0 ? (
    <Container sx={{ width: '50%', minHeight: 150 }} spacing={1}>
      {alerts.map((item, index) => (
        <Alert key={index} severity={item.type} onClose={() => setAlerts(alerts.filter((i) => i.id !== item.id))}>
          {item.message}
        </Alert>
      ))}
    </Container>
  ) : (
    <></>
  );
};

export default Alerts;
