import SearchHeader from '@/app/components/atoms/SearchHeader';
import AccordionCollapse from '@/app/components/molecules/Accordion';
import CalendarField from '@/app/components/molecules/CalendarField';
import InputField from '@/app/components/molecules/InputField';
import OpportunityItem, { OpportunityItemType } from '@/app/components/molecules/OpportunityItem';
import Tip from '@/app/components/molecules/Tip';
import CheckBoxMultPortal from '@/app/components/organisms/CheckBoxMultPortal';
import ModalDetailOpportunity, {
  ModalDetailOpportunityhandle,
} from '@/app/components/organisms/ModalDetailOpportunity';
import ModalElectronicExemptions from '@/app/components/organisms/ModalElectronicExemptions';
import MultiSwitchModality from '@/app/components/organisms/MultiSwitchModality';
import MultiSwitchPeriodType from '@/app/components/organisms/MultiSwitchPeriodType';
import SelectFieldOrgao from '@/app/components/organisms/SelectFieldOrgao';
import SelectFieldUF from '@/app/components/organisms/SelectFieldUF';
import useDebounce from '@/app/hooks/debounce';
import { getOpportunities, InputOpportunities } from '@/app/services/opportunitiesService';
import { theme } from '@/app/theme/theme-config';
import ArrowUpIcon from '@assets/ArrowUpIcon.svg';
import VejaAquiVideo from '@assets/veja-aqui-video.png';
import { useForm } from '@hooks/form';
import { Search } from '@mui/icons-material';
import { Card, Grid, Typography } from '@mui/material';
import { addDays, format, subDays } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { formFields } from './formFields';
import {
  AdvancedSearchButton,
  ArrowIcon,
  ButtonDispensasEletronicas,
  Container,
  ContainerBody,
  ContainerFilters,
  ContainerFiltersFooter,
  ContainerPesquisaAvancada,
  ContainerRowFilters,
  ContainerSearch,
  ContainerSelectDispensaEletronica,
  ContainerTip,
  ContainerVejaAqui,
  ContentAction,
  ContentFilters,
  ContentSpacing,
  GridComboCustom,
  MessageEmpty,
  TextItem,
  TitleHeader,
  TitlePage,
} from './styles';

const Opportunity: React.FC = () => {
  const [advancedSearchIdVisible, setAdvancedSearchIdVisible] = useState(false);
  const [openModalElectronic, setOpenModalElectronic] = useState<boolean>(false);
  const [dataList, setDataList] = useState<OpportunityItemType[]>([]);
  const [page, setPage] = useState<number>(0);
  const [defaultInitialDate, setDefaultInitialDate] = useState<Date>(new Date(formFields.dataInicial.value));
  const [defaultFinalDate, setDefaultFinalDate] = useState<Date>(new Date(formFields.dataFinal.value));

  const modalDetail = useRef<ModalDetailOpportunityhandle>(null);

  // inicio implementação do formik
  const onHandleSubmit = async (values: any) => {
    console.log('teste');
  };

  const { values, touched, errors, handleOnChange, handleSubmit, setFieldValue } = useForm(formFields, onHandleSubmit);

  const searchOpportunity = async (clear = false) => {
    let data = clear ? [] : dataList || [];
    const newPage = clear ? 0 : page;
    const opportunities = await getOpportunities({
      ...(values as InputOpportunities),
      page: newPage,
    });
    setPage(newPage + 1);
    data = [...data, ...(opportunities as unknown as OpportunityItemType[])];
    setDataList(data);
  };

  useDebounce(JSON.stringify(values), async () => searchOpportunity(true), 100);
  // fim implementação do formik

  const handlePeriodType = () => {
    const currentDate = new Date();
    const add15DaysInCurrentDate = addDays(new Date(), 14);
    const sub15DaysInCurrentDate = subDays(new Date(), 15);

    if (values.tipoPeriodo === 'abertura') {
      setDefaultInitialDate(currentDate);
      setDefaultFinalDate(add15DaysInCurrentDate);

      setFieldValue('dataInicial', format(currentDate, 'yyyy-MM-dd'));
      setFieldValue('dataFinal', format(add15DaysInCurrentDate, 'yyyy-MM-dd'));
    } else {
      setDefaultInitialDate(sub15DaysInCurrentDate);
      setDefaultFinalDate(currentDate);

      setFieldValue('dataInicial', format(sub15DaysInCurrentDate, 'yyyy-MM-dd'));
      setFieldValue('dataFinal', format(currentDate, 'yyyy-MM-dd'));
    }
  };

  useEffect(() => {
    handlePeriodType();
  }, [values.tipoPeriodo]);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const listBox = event.currentTarget;
    const x = listBox.scrollTop + listBox.clientHeight;
    if (listBox.scrollHeight - (x + 1) <= 1) {
      const top = listBox.scrollTop;
      await searchOpportunity();
      setTimeout(() => listBox.scrollTo({ top }), 40);
    }
  };

  return (
    <Container>
      <TitlePage item md={2} xs={12}>
        <Typography variant='h1'>Oportunidades</Typography>
      </TitlePage>
      <Card>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <ContainerFilters>
              <ContainerRowFilters container>
                <TitleHeader item xs={12} md={12} xl={4}>
                  <SearchHeader
                    title='Faça sua pesquisa'
                    caption='Busque oportunidades de dispensas, pregões...'
                    color={theme.palette.background.paper}
                  />
                </TitleHeader>
                <ContainerSearch item xs={12} md={12} xl={8}>
                  <InputField
                    id='pesquisa'
                    form={values}
                    handleChange={handleOnChange}
                    formErrors={touched.search ? errors : {}}
                    placeholder='O que você deseja pesquisar'
                    positionIcon='start'
                    Icon={Search}
                    helperText='Insira palavras-chave, separadas por ponto e vírgula (;)'
                  />
                </ContainerSearch>
              </ContainerRowFilters>
              <ContainerRowFilters container>
                <ContentFilters>
                  <MultiSwitchModality onChange={(e) => setFieldValue('modalidades', e.toString())} />
                </ContentFilters>
                <ContentFilters>
                  <MultiSwitchPeriodType onChange={(e) => setFieldValue('tipoPeriodo', e.toString())} />
                </ContentFilters>
                <ContentFilters>
                  <CalendarField
                    id='calendar'
                    form={values}
                    formErrors={touched.calendar ? errors : {}}
                    handleChange={(id: string, value: any) => {
                      if (value) {
                        setFieldValue('dataInicial', format(value[0], 'yyyy-MM-dd'));
                        setFieldValue('dataFinal', format(value[1], 'yyyy-MM-dd'));
                      }
                    }}
                    value={[defaultInitialDate, defaultFinalDate]}
                  />
                </ContentFilters>
              </ContainerRowFilters>
              <ContainerPesquisaAvancada display={advancedSearchIdVisible ? 'block' : 'none'}>
                <Typography variant='h4'>Pesquisa Avançada</Typography>
                <ContainerRowFilters container item spacing={2}>
                  <Grid item lg={6} md={12}>
                    <label>Deseja excluir alguma palavra da busca?</label>
                    <InputField
                      id='excluir'
                      form={values}
                      formErrors={touched.excluir ? errors : {}}
                      placeholder='Digite a palavra para excluir da busca'
                      handleChange={handleOnChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <label>Deseja limitar alguma palavra da busca?</label>
                    <InputField
                      id='limitar'
                      form={values}
                      formErrors={touched.limitSearch ? errors : {}}
                      placeholder='Digite a palavra para limitar da busca'
                      handleChange={handleOnChange}
                    />
                  </Grid>
                </ContainerRowFilters>
                <ContainerRowFilters container item spacing={2}>
                  <Grid item lg={6} md={12}>
                    <GridComboCustom item>
                      <CheckBoxMultPortal
                        onChange={(itens) => setFieldValue('portais', itens.map((a) => a.id).join(','))}
                        itensChecked={values['portais']?.split(',')}
                      />
                    </GridComboCustom>
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <GridComboCustom item>
                      <Typography variant='h6'>Escolha os estados que deseja encontrar oportunidades</Typography>
                      <SelectFieldUF
                        id='ufs'
                        form={values}
                        handleChange={(id, value) => setFieldValue(id, value)}
                        formErrors={touched.newspapers ? errors : {}}
                      />
                    </GridComboCustom>
                  </Grid>
                </ContainerRowFilters>
                <ContainerRowFilters container item spacing={2}>
                  <Grid item lg={6} md={12}>
                    <SelectFieldOrgao
                      id='orgao'
                      form={values}
                      handleChange={(id, item) => setFieldValue(id, item ? item.value : '')}
                      formErrors={touched.orgao ? errors : {}}
                      portal={values['portais'] || ''}
                      infoText='Para encontrar um órgão, primeiro selecione um portal'
                    />
                  </Grid>
                  <ContainerSelectDispensaEletronica item lg={6} md={12}>
                    <ButtonDispensasEletronicas onClick={() => setOpenModalElectronic(true)}>
                      Dispensas eletrônicas
                    </ButtonDispensasEletronicas>
                  </ContainerSelectDispensaEletronica>
                </ContainerRowFilters>
              </ContainerPesquisaAvancada>
            </ContainerFilters>

            <ContainerFiltersFooter>
              <ContentAction onClick={() => setAdvancedSearchIdVisible(!advancedSearchIdVisible)}>
                <p>{advancedSearchIdVisible ? 'Fechar' : 'Abrir'} Pesquisa </p>
                <ArrowIcon src={ArrowUpIcon} opened={advancedSearchIdVisible} />
              </ContentAction>
              <ContentSpacing></ContentSpacing>
            </ContainerFiltersFooter>

            <ModalDetailOpportunity ref={modalDetail} />

            <ModalElectronicExemptions
              open={openModalElectronic}
              onClose={() => setOpenModalElectronic(false)}
              form={values}
              formError={touched}
              onChange={(value, e) => {
                setOpenModalElectronic(false);
                Object.keys(value).map((a) => setFieldValue(a, value[a]));
              }}
            />

            <ContainerBody container spacing={2}>
              <Grid container item xs={12} md={9}>
                <ContainerTip>
                  <Tip type='info' description='Adicione as melhores oportunidades aos seus negócios!' />
                </ContainerTip>
                <Grid item xs={12} paddingTop={4}>
                  {dataList.length <= 0 && (
                    <MessageEmpty>
                      O siga pregão não encontrou oportunidades para essa configuração.
                      <br />
                      <strong>Experimente expandir sua busca para obter melhores resultados.</strong>
                      <AdvancedSearchButton variant='contained' onClick={() => setAdvancedSearchIdVisible(true)}>
                        Pesquisa Avançada
                      </AdvancedSearchButton>
                    </MessageEmpty>
                  )}

                  {dataList?.map((data, index) => (
                    <OpportunityItem
                      key={index}
                      data={data}
                      onClick={(d) => modalDetail?.current?.showModal(data.id)}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid container item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <ContainerVejaAqui>
                      {/* TODO: Colocar um dos vídeos do siga pregão no lugar desta imagem */}
                      <img src={VejaAquiVideo} alt='' />
                    </ContainerVejaAqui>

                    <AccordionCollapse
                      itens={[
                        {
                          id: '1',
                          header: <TextItem>Dispensas com itens desertos</TextItem>,
                          body: <h5>Dispensas com itens desertos</h5>,
                        },
                        {
                          id: '2',
                          header: <TextItem>Dispensas com lance acima de referência</TextItem>,
                          body: <h5>Dispensas com lance acima de referência</h5>,
                        },
                        {
                          id: '3',
                          header: <TextItem>Super oportunidades</TextItem>,
                          body: <h5>Super oportunidades</h5>,
                        },
                        {
                          id: '4',
                          header: <TextItem>Super oportunidades do PCP</TextItem>,
                          body: <h5>Super oportunidades do PCP</h5>,
                        },
                      ]}
                      collapseOne
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ContainerBody>
          </Grid>
        </form>
      </Card>
    </Container>
  );
};

export default Opportunity;
