import { client } from '../client/client';
import { ItemMonitorado } from './types';

interface ActivitiesParams {
  id: number;
  filtro: 'men' | 'avi' | 'esc' | 'imp';
  limit?: number;
  offset?: number;
}

export const getActivities = async ({
  id,
  filtro,
  limit = 50,
  offset = 0,
}: ActivitiesParams): Promise<ItemMonitorado> => {
  return client
    .get('/pregao/atividades', {
      params: { id, filtro, limit, offset },
    })
    .then((response: any) => {
      return response;
    });
};
