import useWindowDimensions from '@/app/hooks/useWindowDimensions';
import { TiposAtualizacoes } from '@/app/services/types';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Posts from '../Posts';
import { Container, HeaderContainer, TabStyled, TabCustom } from './styles';

interface TapPostsParams {
  processId: number;
}

const TabPosts: React.FC<TapPostsParams> = ({ processId }) => {
  const [value, setValue] = useState(0);
  const { width } = useWindowDimensions();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const TabPanel = ({ children, value, index }: TabPanelProps) => {
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Grid>{children}</Grid>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Container>
      <HeaderContainer>
        <TabStyled
          value={value}
          onChange={handleChange}
          aria-label='abas de mensagens'
          TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
          orientation={width < 630 ? 'vertical' : 'horizontal'}
        >
          <TabCustom label='Avisos' />
          <TabCustom label='Esclarecimentos' />
          <TabCustom label='Impugnações' />
          <TabCustom label='Mensagens' />
        </TabStyled>
      </HeaderContainer>

      <TabPanel value={value} index={0}>
        <Posts processId={processId} filtro={TiposAtualizacoes.AVISO} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Posts processId={processId} filtro={TiposAtualizacoes.ESCLARECIMENTO} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Posts processId={processId} filtro={TiposAtualizacoes.IMPUGNACAO} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Posts processId={processId} filtro={TiposAtualizacoes.MENSAGEM} />
      </TabPanel>
    </Container>
  );
};

export default TabPosts;
