import { styled } from '@mui/system';
// import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import { Tab, Tabs } from '@mui/material';
import config from '@/app/config';

interface BusinessProps {
  business?: number;
}

export const Container = styled('div')(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  backgroundColor: theme.palette.background.paper,
  padding: 0,
  borderRadius: config.buttonsBorderRadius,
  width: 'max-content',

  '@media (max-width: 1420px)': {
    width: '100%',
  },
}));

export const SelectTab = styled(Tabs)(() => ({}));

export const OptionTab = styled(Tab)<BusinessProps>(({ theme, business }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: '1rem',
  '@media (max-width: 1670px)': {
    fontSize: '0.875rem',
  },

  borderRadius: '5px',
  margin: 'auto',
  width: 'auto',
  zIndex: 1,

  '&:hover': {
    backgroundColor: business ? theme.palette.info.light : theme.palette.warning.light,
  },

  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: business ? '#fff' : theme.palette.text.primary,
  },

  '&.Mui-disabled': {
    opacity: theme.palette.action.disabled,
    cursor: 'not-allowed',
  },
}));
