import React from 'react';
import menuItems from '@jotai/MenuAtom/menuList';
import { Typography } from '@mui/material';
import NavGroup from '@organisms/NavGroup';

const MenuList: React.FC = () => {
  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant='h6' color='error' align='center'>
            Group Menu Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
