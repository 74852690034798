import React, { useEffect } from 'react';

import IconButton from '@atoms/IconButton';
import LogoHeader from '@molecules/LogoHeader';
import NotificationHeader from '@organisms/NotificationHeader';
import { Bar, Container, LogoContainer, LogoSection, NotificationContainer } from './styles';
import { Menu } from '@mui/icons-material';
import { openSidebarAtom } from '@jotai/ThemeAtom';
import UserHeader from '@molecules/UserHeader';
import { useTheme } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import TopMenu from '../../molecules/TopMenu';

const Header: React.FC = () => {
  const setOpenSidebar = useUpdateAtom(openSidebarAtom);
  const openSidebar = useAtomValue(openSidebarAtom);
  const theme = useTheme();

  return (
    <Container>
      <Bar>
        <LogoSection>
          <LogoContainer>
            <LogoHeader />
          </LogoContainer>
          <IconButton
            onClick={() => setOpenSidebar(!openSidebar)}
            icon={<Menu />}
            styles={{
              backgroundColor: theme.palette.primary.main,
              backgroundColorHover: theme.palette.primary.dark,
            }}
          />
        </LogoSection>

        {/* <Spacer /> */}
        <TopMenu />

        <NotificationContainer>
          <NotificationHeader />
          <UserHeader />
        </NotificationContainer>
      </Bar>
    </Container>
  );
};

export default Header;
