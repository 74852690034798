import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogProps } from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { CloseButton, ContainerTitle, sxDialog, Title } from './styles';

export interface ModalProps extends DialogProps {
  header?: string | ReactNode;
  children: string | ReactNode;
  footer?: string | ReactNode;
  closeClickOut?: boolean;
}

const Modal: React.FC<ModalProps> = ({ header, children, footer, onClose, open, closeClickOut, ...rest }) => {
  const [show, setShow] = React.useState(open);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleCloseClickOut = (event: any, reason: any) => {
    setShow(false);
    if (onClose) {
      onClose(event, reason);
    }
  };

  const props = {
    onClose: closeClickOut ? handleCloseClickOut : undefined,
  };

  return (
    <Dialog sx={sxDialog} maxWidth='xs' open={show} {...props} {...rest}>
      {header && (
        <DialogTitle>
          <ContainerTitle>
            {typeof header == 'string' ? <Title>{header}</Title> : header}
            {show ? (
              <CloseButton aria-label='close' onClick={(e) => handleCloseClickOut(e, null)} sx={{}}>
                <Close />
              </CloseButton>
            ) : null}
          </ContainerTitle>
        </DialogTitle>
      )}
      <DialogContent dividers>{children}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
