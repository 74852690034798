import { lazy } from 'react';

import Loadable from '../components/molecules/Loadable';
import MainLayout from '../components/templates/MainLayout';
import Business from '../pages/Business';
import BusinessCards from '../pages/BusinessCards';
import Newspapers from '../pages/Newspapers';
import Opportunity from '../pages/Opportunity';
import OpportunityCardPage from '../pages/OpportunityCardPage';

const Home = Loadable(lazy(() => import('../pages/Home')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Opportunity />,
    },
    {
      path: '/opportunity',
      element: <Opportunity />,
    },
    {
      path: '/business',
      element: <Business />,
    },
    {
      path: '/newspapers',
      element: <Newspapers />,
    },
    {
      path: '/opportunity-card-page',
      element: <OpportunityCardPage />,
    },
    {
      path: '/business-cards',
      element: <BusinessCards />,
    },
  ],
};

export default MainRoutes;
