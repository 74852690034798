import config from '@/app/config';
import styled from '@emotion/styled';
import { Grid, Tab, Tabs } from '@mui/material';

export const Container = styled(Grid)(() => ({
  width: '100%',
  minHeight: '400px',
  '.MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
}));

export const HeaderContainer = styled(Grid)(({ theme }) => ({
  margin: '1rem auto',
  border: '1px solid',
  borderColor: theme.palette.grey[600],
  borderRadius: config.buttonsBorderRadius,
  width: 'max-content',
}));

export const TabStyled = styled(Tabs)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

export const TabCustom = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: 'pointer',
  fontSize: '1rem',
  '@media (max-width: 1670px)': {
    fontSize: '0.875rem',
  },
  borderRadius: '5px',
  margin: 'auto',
  width: 'auto',
  zIndex: 1,

  '&:hover': {
    backgroundColor: theme.palette.info.light,
  },

  '&.Mui-selected': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.background.paper,
    '@media (max-width: 630px)': {
      width: '100%',
    },
  },

  '&.Mui-disabled': {
    opacity: theme.palette.action.disabled,
    cursor: 'not-allowed',
  },
}));
