import styled from '@emotion/styled';
import { Box, FormControlLabel } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  padding: 20,
  backgroundColor: theme.palette.common.white,
  borderRadius: 5,
}));

export const Header = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const HeaderButton = styled.div(() => ({}));

export const ButtonSelectAll = styled.span(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 11,
  color: theme.palette.info[900],
  margin: '0px 10px',
}));

export const ButtonUnSelectAll = styled.span(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 11,
  color: theme.palette.warning[900],
  padding: '2px 5px 2px 5px',
  backgroundColor: theme.palette.warning.A700,
  borderRadius: 15,
}));

export const Body = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginBottom: 10,
}));

export const Footer = styled.div(() => ({}));

export const LabelSelectCount = styled.span(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 300,
  fontSize: 11,
  color: theme.palette.grey[900],
}));

export const CheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
  '.MuiFormControlLabel-label': {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.grey[800],
  },
  marginTop: 10,
  borderRadius: 20,
  paddingRight: 10,
  backgroundColor: theme.palette.grey[100],
  marginLeft: 0,
  marginRight: 8,
}));
