import React, { useState } from 'react';

import { AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { Container, Collapse, Summary } from './styles';

export interface AccordionCollapseItemProps {
  header: string | React.ReactElement;
  body: string | React.ReactElement;
  disabled?: boolean;
  id: string;
}
type AccordionCollapseProps = {
  collapseOne?: boolean;
  itens: AccordionCollapseItemProps[];
  itemCollapsed?: string;
  iconStart?: boolean;
};
const AccordionCollapse: React.FC<AccordionCollapseProps> = ({
  collapseOne,
  itens,
  itemCollapsed,
  iconStart = false,
}) => {
  const [expanded, setExpanded] = useState<string>(itemCollapsed ? itemCollapsed : '');

  const handleChange = (panel: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : '');
  };

  const getProps = (item: AccordionCollapseItemProps) => {
    if (collapseOne) {
      return { expanded: expanded === `${item.id}`, onChange: handleChange(`${item.id}`) };
    }
    if (itemCollapsed) {
      return { expanded: expanded === `${item.id}` };
    }
    return;
  };

  return (
    <Container>
      {itens.map((item, index) => (
        <Collapse id={`its${index}`} key={`its${index}`} disabled={item.disabled} {...getProps(item)}>
          <Summary expandIcon={<ExpandMore />} id={item.id} iconstart={iconStart ? 'start' : 'end'}>
            {item.header}
          </Summary>
          <AccordionDetails>{item.body}</AccordionDetails>
        </Collapse>
      ))}
    </Container>
  );
};

export default AccordionCollapse;
