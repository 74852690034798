import React from 'react';
import { useAtom } from 'jotai';
import { logoAtom } from '@jotai/ThemeAtom';

import Logo from '@atoms/Logo';

import { ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import config from '@app/config';

const HeaderLogo: React.FC = () => {
  const [logoImage] = useAtom(logoAtom);

  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <Logo src={logoImage} />
    </ButtonBase>
  );
};

export default HeaderLogo;
