import React from 'react';

import { Container } from './styles';

interface Props {
  children: React.ReactElement | React.ReactNode;
}
const CardHeaderContainer: React.FC<Props> = ({ children }) => {
  return (
    <Container container alignItems='center' justifyContent='space-between'>
      {children}
    </Container>
  );
};

export default CardHeaderContainer;
