import React from 'react';

import { Container } from './styles';
import { CardContent, CardHeader, Divider, Typography } from '@mui/material';

interface Props {
  styles?: {
    border?: boolean;
    borderColor?: string;
    shadow?: boolean;
    boxShadow?: string;
  };
  elevation?: number;
  darkTitle?: boolean;
  title?: string;
  content?: boolean;
  children?: React.ReactNode | React.ReactElement;
  action?: React.ReactNode | React.ReactElement;
  contentClass?: string;
}
const MainCard: React.FC<Props> = ({
  styles,
  contentClass,
  elevation = 14,
  darkTitle = false,
  title,
  content,
  action,
  children,
}) => {
  return (
    <Container styles={styles} elevation={elevation}>
      {!darkTitle && title && <CardHeader title={title} action={action} />}

      {darkTitle && title && <CardHeader title={<Typography variant='h3'>{title}</Typography>} action={action} />}

      {title && <Divider />}

      {content && <CardContent className={contentClass}>{children}</CardContent>}

      {!content && children}
    </Container>
  );
};

export default MainCard;
