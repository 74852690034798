import styled from '@emotion/styled';
import { Box, Chip, Typography } from '@mui/material';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const UserIcon = styled(Chip)(({ theme }) => ({
  height: 48,
  alignItems: 'center',
  borderRadius: '27px',
  transition: 'all .2s ease-in-out',
  borderColor: theme.palette.background.paper,
  backgroundColor: theme.palette.background.paper,
  '&[aria-controls="menu-list-grow"], &:hover': {
    background: `${theme.palette.background.paper} !important`,
    color: theme.palette.primary.contrastText,
    '& .MuiAvatar-root': {
      background: `${theme.palette.primary.dark} !important`,
    },
  },
  '& .MuiChip-label': {
    lineHeight: 0,
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  paddingRight: '1rem',
  color: theme.typography.h1.color,
  fontWeight: theme.typography.h1.fontWeight,
}));

export const MenuCard = styled(Box)(({ theme }) => ({
  padding: 16,
  '& .MuiListItemButton-root': {
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: theme.shape.borderRadius,
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      '& .MuiTypography-root': {
        color: `${theme.palette.primary.contrastText} !important`,
      },
      '& svg': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
