import styled from '@emotion/styled';
import { createTheme, Grid, DialogTitle, Card } from '@mui/material';

export const HeaderContainer = styled(DialogTitle)(() => ({
  backgroundColor: '#F5F5F5',
}));

export const BodyContainer = styled(DialogTitle)(() => ({
  backgroundColor: '#F5F5F5',
}));

export const CardContainer = styled(Card)(() => ({
  padding: '1rem',
  height: '100%',
  '@media (max-width: 420px)': {
    padding: '0.4rem',
  },
}));

export const GridFlexColumn = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const GridFlexRow = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

interface Props {
  themecolor?: 'primary' | 'warning' | 'success' | undefined;
}

export const GridFlexRowBody = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  '@media (max-width: 420px)': {
    flexDirection: 'column',
    padding: '0',
    '>div': {
      padding: '0',
      margin: '0',
    },
  },
}));

export const CountContainer = styled(Grid)<Props>(({ theme, themecolor }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: themecolor ? (theme.palette as any)[themecolor].dark : theme.palette.grey[900],
  color: '#fff',
  borderRadius: '4px',
  padding: '0 1.8rem',
  maxWidth: '5.625rem',
  maxHeight: '3.875rem',
  '>h3': {
    fontSize: '2rem',
  },
  '>i': {
    fontWeight: 'bold',
  },
}));

export const ContainerTitle = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const ContainerTitleBody = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  '@media (max-width: 420px)': {
    flexDirection: 'column',
    '>div': {
      width: '100%',
      padding: '0',
    },
  },
}));

export const ContainerTitleRow = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  '@media (max-width: 870px)': {
    justifyContent: 'space-between',
  },
  '@media (max-width: 420px)': {
    flexDirection: 'column',
    marginBottom: '1rem',
  },
  '>button': {
    margin: '0 1rem',
  },
  '>div': {
    margin: '0 1rem',
  },
}));

export const ContainerTitleColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 1rem',
  '>p': {
    color: '#656565',
    fontWeight: 'bold',
  },
}));

export const TitleBusiness = styled('p')(() => ({
  fontSize: '0.8rem',
  fontWeight: 'bold',
  color: '#656565',
  paddingTop: '2rem',
}));

export const ContainerDescription = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '1rem 0',
  '>div': {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  '@media (max-width: 420px)': {
    flexDirection: 'column',
    '>div': {
      padding: '0 0 1rem 2rem',
    },
  },
}));

export const HeaderDocuments = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: '8px',
  '>img': {
    marginRight: '8px',
  },
}));

export const sxDialog = {
  '& .MuiDialog-paper': {
    width: '100%',
  },
};

export const themeButtons = createTheme({
  palette: {
    primary: {
      main: '#3F434A',
    },
    secondary: {
      main: '#209117',
    },
  },
});

export const ContainerActionsEditals = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  '>a': {
    margin: '0.5rem 0.5rem 0 0',
  },
  '>button': {
    margin: '0.5rem 0.5rem 0 0',
  },
  '@media (max-width: 420px)': {
    flexDirection: 'column',
  },
}));
