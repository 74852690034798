import styled from '@emotion/styled';
import { Button, Grid } from '@mui/material';

export const Container = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

export const Footer = styled(Grid)(() => ({
  width: '100%',

  '@media (max-width: 420px)': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));

export const InstructionsContainer = styled(Grid)(() => ({
  fontWeight: '400',
  fontSize: 12,
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  padding: '0.5rem',

  '@media (max-width: 420px)': {
    fontSize: '0.7rem',
    textAlign: 'center',
  },
}));

export const ActionsFooter = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '1rem',
}));

export const ButtonCancel = styled(Button)(() => ({
  marginRight: 20,
  border: '1px solid #757575',
  background: '#FFFFFF',
  color: '#434343',
}));

export const ContainerTitle = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '2rem',

  '> h4': {
    display: 'flex',
    justifyContent: 'center',
    color: '#1883f1',
    fontSize: '1rem',
  },

  '> p': {
    display: 'flex',
    justifyContent: 'center',
  },

  '@media (max-width: 420px)': {
    '> h4': {
      fontSize: '0.8rem',
      lineHeight: '1.2rem',
      marginBottom: '1rem',
    },

    '> p': {
      fontSize: '0.8rem',
      lineHeight: '1.2rem',
    },
  },
}));

export const ContainerModal = styled.div(() => ({}));

export const LineEmployer = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',

  '@media (max-width: 420px)': {
    marginTop: '0.8rem',
    flexDirection: 'column',
    '>button': {
      border: '1px #5494F5  solid',
      margin: '0.2rem 0 0 0 !important',
      width: '100%',
    },
  },
}));

export const LineDateOptions = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'end',
  marginTop: 15,
}));

export const ShareIconsContainer = styled(Grid)(() => ({
  margin: 'auto 0',
  minWidth: '21.4rem',
  display: 'flex',
  justifyContent: 'flex-end',
  '@media (max-width: 420px)': {
    minWidth: 'auto',
    flexDirection: 'column',
    '>button': {
      border: '1px #5494F5 solid',
      width: '100%',
      marginBottom: '0.2rem',
    },
  },
}));

export const DeadLineShowContainer = styled(Grid)(() => ({
  maxWidth: '21.4rem',
  minWidth: '18.7rem',
  '@media (max-width: 420px)': {
    minWidth: 'auto',
    width: '100%',
  },
}));

export const ContainerListItems = styled.div(() => ({
  // paddingTop: '2rem',
  backgroundColor: '#f0f0f0',
  padding: '1rem',
  borderRadius: '5px',
}));
