import React from 'react';
import { Divider, List, Typography } from '@mui/material';
import ListHeader from '@molecules/ListHeader';
import NavItem, { NavItemProps } from '@molecules/NavItem';
import NavCollapse from '@molecules/NavCollapse';

interface Props {
  item: {
    id: string;
    title?: string;
    caption?: string;
    type?: string;
    children: NavItemProps[];
  };
}
const NavGroup: React.FC<Props> = ({ item }) => {
  const items = item.children.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        return <NavCollapse key={menu.title} menu={menu} level={1} />;
      case 'item':
        return <NavItem key={menu.title} item={menu} level={1} />;
      default:
        return (
          <Typography key={menu.title} variant='h6' color='error' align='center'>
            Item Menu Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List subheader={item.title && <ListHeader title={item.title} caption={item.caption} />}>{items}</List>
      <Divider />
    </>
  );
};

export default NavGroup;
