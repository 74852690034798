import styled from '@emotion/styled';
import { Card, Grid } from '@mui/material';

export const Container = styled('div')(() => ({}));

export const CardContainer = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  backgroundColor: theme.palette.background.default,
  marginTop: '2rem',
}));

export const FilterContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1rem',
}));

export const FilterItem = styled(Grid)(() => ({
  paddingTop: '0 !important',

  '@media(max-width: 900px)': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.5rem',
  },
}));

export const SearchField = styled('input')(() => ({
  width: '100%',
  height: '36px',
  borderRadius: '4px',
  border: '1px solid',
  borderColor: '#bdbdbd',
  textIndent: '10px',
}));
