import styled from '@emotion/styled';
import InputField from '@/app/components/molecules/InputField';
import { Button, CircularProgress } from '@mui/material';

interface ContainerProps {
  bgSrc: string;
}

export const Container = styled('div')<ContainerProps>(({ bgSrc }) => ({
  backgroundImage: `url(${bgSrc})`,
  height: '100vh',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
}));

export const Logo = styled('img')(() => ({
  marginLeft: '10rem',
  width: '30%',
  '@media (max-width: 1282px)': {
    width: '30%',
  },
  '@media (max-width: 1024px)': {
    display: 'none',
  },
}));

export const ContainerLogin = styled('div')(() => ({
  width: '55%',
  '@media (max-width: 1024px)': {
    width: '100%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const CardLogin = styled('form')(({ theme }) => ({
  width: 350,
  display: 'flex',
  boxShadow: theme.shadows[6],
  transition: '0.3s',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: 5,
  padding: '16px 13px 68px 13px',
}));

export const Title = styled('span')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  color: theme.palette.grey[900],
}));

export const SigaPregao = styled.img(() => ({}));

export const InputEmail = styled(InputField)(() => ({
  width: '20rem',
  marginTop: 22,
}));

export const InputPassword = styled(InputField)(() => ({
  width: '20rem',
  marginTop: 10,
}));

export const ButtonLogin = styled(Button)(() => ({
  width: '20rem',
  marginTop: 49,
}));

export const CircularLoading = styled(CircularProgress)(() => ({
  marginRight: 20,
}));

export const LabelRegister = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 40,
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.grey[900],
}));
