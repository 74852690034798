export class Interceptors {
  public static responseInterceptorSuccess(response: any) {
    return response.data;
  }

  static responseInterceptorError(error: any) {
    if (error && error.response && error.response.status == 401) {
      sessionStorage.removeItem('data');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
}
