import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import SuccessAlert from '@assets/SuccessAlert.svg';
import InfoAlert from '@assets/InfoAlert.svg';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  DialogActionsContainer,
  DialogTextContainer,
  DialogTitleContainer,
  IconAlert,
  MessageContainer,
} from './styles';

interface AlertMessageProps {
  title: string;
  success: boolean;
  description: string;
}

interface Props {
  message?: AlertMessageProps;
  callBack?: () => void;
}

export type AlertHandle = {
  showModal: () => void;
};

const AlertMessage = ({ message, callBack }: Props, ref: React.Ref<AlertHandle>) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal: () => handleClickOpen(),
  }));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    callBack && callBack();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitleContainer>
          <IconButton aria-label='close' color='inherit' size='small' onClick={() => handleClose()}>
            <CloseIcon fontSize='inherit' color='disabled' />
          </IconButton>
        </DialogTitleContainer>
        <DialogContent>
          <DialogTextContainer id='alert-dialog-description'>
            {message?.success ? (
              <IconAlert src={SuccessAlert} alt='Logo Alert' />
            ) : (
              <IconAlert src={InfoAlert} alt='Logo Alert' />
            )}
            <MessageContainer>{message?.description}</MessageContainer>
          </DialogTextContainer>
        </DialogContent>
        <DialogActionsContainer>
          {message?.success ? (
            <Button color='success' variant='contained' style={{ color: '#fff' }} onClick={handleClose}>
              Ok
            </Button>
          ) : (
            <Button
              href='https://api.whatsapp.com/send?phone=556198417005&text=Olá, Gostaria de realizar o upgrade do meu plano para liberar mais Empresas.'
              startIcon={<WhatsAppIcon />}
              target='_blank'
              color='success'
              variant='contained'
              style={{ color: '#fff' }}
            >
              Falar com um atendente no Whatsapp
            </Button>
          )}
        </DialogActionsContainer>
      </Dialog>
    </div>
  );
};

export default forwardRef(AlertMessage);
