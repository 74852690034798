import { FormFields } from '@hooks/form';

export const formFieldsOpportunity: FormFields = {
  empresaId: {
    value: '',
    fieldType: 'string',
  },
  itens: {
    value: [],
    fieldType: 'any',
  },
  nome: {
    value: '',
    fieldType: 'string',
  },
  processoId: {
    value: '',
    fieldType: 'number',
  },
  tipoNegocio: {
    value: '',
    fieldType: 'string',
  },
};
