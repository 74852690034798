import React, { useState } from 'react';
import { Container, SelectTab, OptionTab } from './styles';
import { theme } from '@/app/theme/theme-config';
import useWindowDimensions from '@/app/hooks/useWindowDimensions';
import { FormControl, MenuItem, Select } from '@mui/material';

interface OptionInterface {
  label: string;
  value: any;
}

export interface MultiSwitchProps {
  options: OptionInterface[];
  defaultValue: any;
  onChange: (id: string, value: any) => void;
  business?: boolean;
}

const MultiSwitch: React.FC<MultiSwitchProps> = ({ options, onChange, defaultValue, business }) => {
  const { width } = useWindowDimensions();

  const [value, setValue] = useState(defaultValue);
  const handleChange = (event: any, newValue: any) => {
    if (newValue.props && newValue.props.value) {
      setValue(newValue.props.value);
      onChange(newValue.props.value, options[newValue.props.value]);
    } else {
      setValue(newValue);
      onChange(newValue, options[newValue]);
    }
  };

  const indicatorStyles = {
    style: {
      height: '100%',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: business ? theme.palette.info.main : theme.palette.warning.dark,
    },
  };

  return (
    <Container>
      {width > 630 ? (
        <SelectTab centered={false} onChange={handleChange} value={value} TabIndicatorProps={indicatorStyles}>
          {options.map((option: OptionInterface) => (
            <OptionTab key={option.label} value={option.value} label={option.label} business={business ? 1 : 0} />
          ))}
        </SelectTab>
      ) : (
        <Select value={value} label={options[0].label} onChange={handleChange} style={{ width: '100%' }}>
          {options.map((option: OptionInterface) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
    </Container>
  );
};

export default MultiSwitch;
