import React from 'react';

import { Caption, Container } from './styles';

interface Props {
  title: string;
  caption?: string;
}
const ListHeader: React.FC<Props> = ({ title, caption }) => {
  return (
    <Container variant='caption' display='block' gutterBottom>
      {title}
      {caption && (
        <Caption variant='caption' display='block' gutterBottom>
          {caption}
        </Caption>
      )}
    </Container>
  );
};

export default ListHeader;
