import React, { useState, useEffect } from 'react';

import { Container, ContainerIcon } from './styles';
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  AutocompleteProps,
  ChipTypeMap,
  TextField,
  InputAdornment,
} from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import useDebounce from '@/app/hooks/debounce';

export interface AuxAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'renderInput'> {}

export interface SelectFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> extends Omit<AuxAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>, 'options'> {
  id: string;
  form?: any;
  formErrors?: any;
  helperText?: string;
  label?: string;
  nameFieldValue?: string;
  Icon?: SvgIconComponent;
  bgIcon?: string;
  fetch?: (params: any) => Promise<any[]>;
  fieldLabel?: string;
  fieldParamsPage?: string;
  paramsDefault?: any;
  fieldParamsSearch?: string;
  handleChange?: (id: string, value: any, e: React.ChangeEvent<any>) => void;
  options?: T[];
  infoText?: string;
}

export interface Option {
  label: string;
  value: string | number;
}

function SelectField<
  Option,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  fullWidth,
  id,
  form,
  formErrors,
  helperText,
  label,
  placeholder,
  nameFieldValue,
  handleChange,
  options,
  Icon,
  bgIcon,
  fetch,
  fieldLabel = 'label',
  fieldParamsPage = 'page',
  fieldParamsSearch = 'search',
  paramsDefault,
  infoText,
  ...rest
}: SelectFieldProps<Option, Multiple, DisableClearable, FreeSolo>) {
  const [itens, setItens] = useState<Option[]>([]);
  const [text, setText] = useState('');
  const [page, setPage] = useState(0);
  const [isOpen, setOpen] = useState(false);

  useDebounce(text, async () => fetch && load(true), 600);

  useEffect(() => {
    if (options) {
      setItens(options);
    }
  }, [options]);

  const getHelperText = () => {
    if (formErrors[id] !== undefined && formErrors[id] !== '') {
      return formErrors[id];
    }
    if (helperText) {
      return helperText;
    }
    return '';
  };
  const onChange = (e: any, newValue: any) => {
    if (handleChange) {
      handleChange(id, newValue, e);
    }
  };
  const getInputProps = (params: any) => {
    return (
      Icon && {
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <InputAdornment position='start'>
              <ContainerIcon bgIcon={bgIcon}>
                <Icon fontSize='large' />
              </ContainerIcon>
            </InputAdornment>
          ),
        },
      }
    );
  };

  const load = async (clearPage = false) => {
    const newPage = clearPage ? 0 : (page ? page : 0) + 1;
    setPage(newPage);
    if (fetch) {
      let newItens = itens;
      if (newPage == 0 && isOpen) {
        newItens = [];
      }
      const data = await fetch({
        ...paramsDefault,
        [fieldParamsSearch]: text,
        [fieldParamsPage]: newPage,
      });
      newItens = [
        ...newItens,
        ...data.map(
          (a) =>
            ({
              ...a,
              label: a[fieldLabel] || '',
              value: a[nameFieldValue || 'value'],
            } as unknown as Option),
        ),
      ];
      const removeRepeat: Option[] = newItens.reduce((prev: Option[], curr: Option) => {
        const ant: Option[] = prev || [];
        if (ant.findIndex((i: any) => i['value'] == (curr as any)['value']) == -1) {
          ant.push(curr);
        }
        return ant;
      }, []);
      setItens(removeRepeat);
    }
  };

  useEffect(() => {
    isOpen && load(true);
  }, [isOpen]);

  const handleScroll = async (event: React.UIEvent<HTMLUListElement>) => {
    const listBox = event.currentTarget;
    const x = listBox.scrollTop + listBox.clientHeight;
    if (listBox.scrollHeight - (x + 1) <= 1) {
      const top = listBox.scrollTop;
      await load();
      setTimeout(() => listBox.scrollTo({ top }), 40);
    }
  };

  return (
    <Container fullWidth>
      <FormControl fullWidth>
        {infoText && <small>{infoText}</small>}

        <Autocomplete
          noOptionsText={
            infoText
              ? 'É necessário selecionar 1 portal de compras'
              : 'Digite 1 ou mais caracteres para listar os órgãos'
          }
          id={id}
          fullWidth={fullWidth ?? true}
          onInputChange={(event, newInputValue) => setText(newInputValue)}
          {...rest}
          value={form[id] ?? ''}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          filterSelectedOptions
          onChange={onChange}
          getOptionLabel={(option) => {
            if (!option) {
              return '';
            }
            if ((option as any).label) {
              return option ? (option as any).label : '';
            }
            const itensFind = itens.find((a: any) => a.value == option) as any;
            return option && !!itensFind ? itensFind.label || '' : '';
          }}
          options={itens}
          renderInput={(params) => (
            <TextField {...params} {...getInputProps(params)} label={label || ''} placeholder={placeholder} />
          )}
          isOptionEqualToValue={(option, value) => {
            return (
              (option as any)[`${nameFieldValue ? (fetch ? 'value' : nameFieldValue) : 'value'}`] ===
              (value as any)[`${nameFieldValue ? (fetch ? 'value' : nameFieldValue) : 'value'}`]
            );
          }}
          ListboxProps={{
            onScroll: handleScroll,
          }}
        />
        {getHelperText() && <FormHelperText>{getHelperText()}</FormHelperText>}
      </FormControl>
    </Container>
  );
}

export default SelectField;
