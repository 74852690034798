import React, { useEffect } from 'react';
import MultiSwitch, { MultiSwitchProps } from '../../molecules/MultiSwitch';

export interface MultiSwitchPeriodTypeProps extends Omit<MultiSwitchProps, 'options' | 'defaultValue'> {}

const MultiSwitchPeriodType: React.FC<MultiSwitchPeriodTypeProps> = ({ onChange }) => {
  useEffect(() => {}, []);

  return (
    <MultiSwitch
      defaultValue={'abertura'}
      options={[
        { label: 'Data de abertura', value: 'abertura' },
        { label: 'Data de publicação', value: 'publicacao' },
      ]}
      onChange={onChange}
    />
  );
};

export default MultiSwitchPeriodType;
